import React from 'react'
import { Button, Input, Modal, message, Form } from 'antd';
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { IExperienceState } from "../../store/reducers/experience";
import { IStoreState } from "../../store/IStoreState";
import { hideRenameExperience, renameExperience } from "../../store/actions/experience";
import { FormInstance } from 'antd/lib/form';

interface IProps {
    hideRenameExperience : () => void,
    renameExperience : (id:string, title:string, success: ()=>void, fail: ()=>void) => void,
    id: string,
    experience: IExperienceState,
}

class RenameExperience extends React.Component<IProps> {
    formRef = React.createRef<FormInstance>();

    handleCancel = () => {
        this.props.hideRenameExperience();
    };

    handleSubmit = values => {
        console.log('submitting right?');
        message.info("Renaming...");
        this.props.renameExperience(this.props.id, values.experienceName, this.success, this.fail);
        this.props.hideRenameExperience();
    }

    success = () => {
        message.success("Renamed successfully!");
    }

    fail = () => {
        message.error("Failed to rename...");
    }

    render(): React.ReactNode {
        const { showRenameExperience } = this.props.experience;

        return (
            <Modal visible={showRenameExperience}
                footer={<Button type="primary" htmlType="submit" onClick={this.handleSubmit} className="login-form-button" style={{ backgroundColor: "#2ED47A", border: '0px' }}>
                    Rename
                </Button>} onCancel={this.handleCancel}>
                New Experience Name:
                <Form onFinish={this.handleSubmit} className="login-form" style={{ marginTop: '15px', alignItems: 'middle' }}>
                    <Form.Item
                        name="experienceName"
                        rules={[{ required: true, message: 'Please enter a new experience name' }]}
                    >
                        <Input placeholder="Enter new experience name"/>
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

const mapStateToProps = (state:IStoreState) => {
    return {
        ...state
    }

}

const mapDispatchToProps = dispatch => {
    return ({
        hideRenameExperience: () => dispatch(hideRenameExperience()),
        renameExperience: (id:string, title:string, success: ()=>void, fail: ()=>void) => dispatch(renameExperience(id, title, success, fail)),
    })
}
/* eslint-disable  @typescript-eslint/no-explicit-any */
export default (withRouter(connect(mapStateToProps, mapDispatchToProps)(RenameExperience)as any) as any);