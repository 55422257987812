import { CaretDownFilled } from '@ant-design/icons';
import { Button, Dropdown, Menu } from "antd";
import React from 'react';
import { connect } from 'react-redux';
import { matchPath, RouteComponentProps, withRouter } from "react-router";
import { showResetForm } from "../../store/actions/user";
import { disableUser, enableUser, getHeroStatus, getUserActivation } from "../../store/actions/users";
import { IStoreState } from '../../store/IStoreState';
import { IUserState } from "../../store/reducers/user";
import { IUsersState } from "../../store/reducers/users";
import PasswordResetForm from "../PasswordReset";

interface IState {
    heroButton: boolean,
    travellerButton: boolean,
    idPush: string,
    enable: boolean,
    id: string,
}

interface IProps extends RouteComponentProps {
    getHeroStatus: (id: string) => void,
    getUserActivation: (id: string) => void,
    users: IUsersState,
    showResetForm: () => void,
    user: IUserState,
    disableUser: (id: string) => void,
    enableUser: (id: string) => void,
}


class ModeButton extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            heroButton: false,
            travellerButton: false,
            idPush: '',
            enable: false,
            id: ' ',
        }
    }

    componentDidMount(): void {
        this.props.getUserActivation(this.state.id);
        const { pathname } = this.props.location;
        const { heroButton } = this.props.users;
        const heroBtn = matchPath(pathname, {
            path: "/users/:id",
            exact: true,
            strict: false,
        });
        const travellerBtn = matchPath(pathname, {
            path: "/hero/:id",
            exact: true,
            strict: false,
        })

        if (heroBtn && heroBtn.isExact) {
            const i = pathname.indexOf('users') + 6;
            const userId = pathname.substring(i);
            this.setState({ id: userId })
            this.props.getUserActivation(userId);
            this.props.getHeroStatus(userId);
            this.setState({ heroButton: true, travellerButton: false, idPush: userId, enable: heroButton })

        }
        else if (travellerBtn && travellerBtn.isExact) {
            const j = pathname.indexOf('hero') + 5;
            const heroId = pathname.substring(j);
            this.setState({ id: heroId });
            this.props.getUserActivation(heroId);
            this.setState({ heroButton: false, travellerButton: true, idPush: heroId })
        }
        else {
            this.setState({ heroButton: false, travellerButton: false })
        }

    }

    componentDidUpdate(prevProps: Readonly<IProps>): void {
        const { pathname } = this.props.location;
        const { heroButton } = this.props.users;
        if (prevProps.location.pathname !== pathname || prevProps.users.heroButton !== heroButton) {
            console.log('Mode Button:' + pathname)
            const heroBtn = matchPath(pathname, {
                path: "/users/:id",
                exact: true,
                strict: false,
            });
            const travellerBtn = matchPath(pathname, {
                path: "/hero/:id",
                exact: true,
                strict: false,
            })

            if (heroBtn && heroBtn.isExact) {
                const i = pathname.indexOf('users') + 6;
                const userId = pathname.substring(i);
                this.setState({ id: userId })
                this.props.getUserActivation(userId);
                this.props.getHeroStatus(userId);
                this.setState({ heroButton: true, travellerButton: false, idPush: userId, enable: heroButton })

            }
            else if (travellerBtn && travellerBtn.isExact) {
                const j = pathname.indexOf('hero') + 5;
                const heroId = pathname.substring(j);
                this.setState({ id: heroId });
                this.props.getUserActivation(heroId);
                this.setState({ heroButton: false, travellerButton: true, idPush: heroId })
            }
            else {
                this.setState({ heroButton: false, travellerButton: false })
            }
        }
    }
    render() {
        const { heroButton, travellerButton, enable } = this.state;
        const { userActivation } = this.props.users;
        const menu = (
            <Menu>
                <Menu.Item
                    key="1"
                    onClick={this.props.showResetForm} >
                    Reset Password
                </Menu.Item>
                <Menu.Item
                    key="2"
                    onClick={() => (userActivation ? this.props.disableUser(this.state.id) : this.props.enableUser(this.state.id))}>
                    {(userActivation && "Disable Account") || (!userActivation && "Enable Account")}
                </Menu.Item>
            </Menu>
        );
        return (
            <div className='mode-buttons' style={{ margin: 'auto 0' }}>
                {(heroButton || travellerButton) &&
                    <PasswordResetForm visible={showResetForm} />
                }
                {(heroButton || travellerButton) &&
                    <Dropdown overlay={menu}>
                        <Button style={{ marginRight: '16px' }} >
                            Actions <CaretDownFilled />
                        </Button>
                    </Dropdown>
                }
                {heroButton &&
                    <Button
                        type="primary"
                        style={{ marginRight: '16px' }}
                        disabled={!enable}
                        className="user-button"
                        onClick={() => this.props.history.push('/hero/' + this.state.id)}>
                        Hero mode
                    </Button>
                }
                {travellerButton &&
                    <Button
                        type="primary"
                        style={{ marginRight: '16px' }}
                        className="user-button"
                        onClick={() => this.props.history.push('/users/' + this.state.id)}>
                        Traveller mode
                    </Button>
                }
            </div>
        );
    }
}

const mapStateToProps = (state: IStoreState) => {
    return {
        ...state
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getHeroStatus: (id: string) => dispatch(getHeroStatus(id)),
        showResetForm: () => dispatch(showResetForm()),
        disableUser: (id: string) => dispatch(disableUser(id)),
        getUserActivation: (id: string) => dispatch(getUserActivation(id)),
        enableUser: (id: string) => dispatch(enableUser(id)),

    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModeButton))