import { FirebaseDB } from "../lib/firebase";
import { FirestoreKey } from "../constants/setting";
import { Activity } from "../models/activity";

export function convertToDate(timestamp: number): string {

    if (!isNaN(timestamp)) {
        const date = new Date(timestamp);
        const result = date.getDate() + " " + date.toLocaleString('default', { month: 'long' }) + " " + date.getFullYear();
        return result;
    }
    else {
        return '';
    }

}

export function toOrdinal(n: number): string {
    const i = n % 10;
    const j = n % 100;
    if (i === 1 && j !== 11)
        return n + 'st'
    else if (i === 2 && j !== 12)
        return n + 'nd'
    else if (i === 3 && j !== 13)
        return n + 'rd'
    else
        return n + 'th'
}

export async function getMasterId(activityId: string) : Promise<string> {
    let masterId: string;
    const ActivityRef = FirebaseDB.collection(FirestoreKey.Activities).doc(activityId);
    try {
        const snap = await ActivityRef.get();
        const data = snap.data() as Activity;
        masterId = data.masterId;
        return masterId;
    } catch (e)
    {
        return null;
    }
}
