import React from 'react'
import { Card, Col, Divider, Modal, Row, Statistic, Tag } from "antd";
import { IUsersState } from "../../../store/reducers/users";
import { getUserProfile, getHeroProfile } from "../../../store/actions/users";
import { connect } from "react-redux";
import { IStoreState } from '../../../store/IStoreState';

interface IProps {
    id: string,
    getUserProfile: (id: string) => void,
    getHeroProfile: (id: string) => void,
    users: IUsersState,
}

interface IState {
    showCover: boolean
}

class HeroProfile extends React.Component <IProps, IState> {
    constructor(props) {
        super(props)
        this.state = {
            showCover: false,
        }
    }

    componentDidMount(): void {
        this.props.getUserProfile(this.props.id);
        this.props.getHeroProfile(this.props.id);
    }

    handleCancel = e => {
        console.log(e);
        this.setState({
            showCover: false,
        });
    };

    showCover =  () => {
        this.setState({ showCover: true })
    }


    render() {
        const { heroProfile } = this.props.users;
        const title = () => {
            return (
                <span>{heroProfile.Name}<Tag color={heroProfile.color} style={{ marginLeft: '10px' }}>{heroProfile.categoryName}</Tag><Tag color=' #898989'>Lv. {heroProfile.level}</Tag></span>
            )
        };
        return (
            <Card title={title()} style={{ margin: '0px 16px' }} headStyle={{ backgroundColor: '#F8F8F8' }}
                extra={<div onClick={this.showCover} style={{ cursor: 'pointer' }}><b><u>View Cover</u></b></div>}
            >
                <Modal visible={this.state.showCover} footer={null} centered closable={false} style={{ backgroundColor: 'transparent', width: '650px', padding: '5px' }} className="cover-img">
                    <p onClick={this.handleCancel} style={{ color: 'white', textDecoration: 'underline', float: 'right', cursor: 'pointer' }}>Close</p>
                    <img src={heroProfile.Cover} style={{ marginLeft: '-10px', width: '100%' }} alt=""/>
                </Modal>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <img src={heroProfile.Avatar} style={{ height: '85px', width: '85px' }} alt=""/>
                    <div style={{ display: 'flex', flexDirection: 'column', margin: '10px', width: '400px' }}>
                        <span style={{ color: '#37393A', fontSize: '13px', fontWeight: 'bold' }}> Description </span>
                        <p style={{ color: '#898989' }}> {heroProfile.Description}</p>
                    </div>
                    <Divider type='vertical' style={{ height: 'auto', marginLeft: '10px' }}/>
                    <Row justify="space-between" gutter={32} style={{ marginTop: 'auto', marginLeft: 'auto', marginRight: 'auto', marginBottom: 'auto' }}>
                        <Col span={3}>
                            <div className='profile-col'>
                                <Statistic value={heroProfile.Experiences}/>
                                <p style={{ color: '#898989' }}>Experiences</p>
                            </div>
                        </Col>
                        <Col span={3}>
                            <div className='profile-col'>
                                <Statistic value={heroProfile.Concepts}/>
                                <p style={{ color: '#898989' }}>Concepts</p>
                            </div>
                        </Col>
                        <Col span={3}>
                            <div className='profile-col'>
                                <Statistic value={heroProfile.Followers}/>
                                <p style={{ color: '#898989' }}>Followers</p>
                            </div>
                        </Col>
                    </Row>
                </div>

            </Card>

        );
    }
}

const mapStateToProps = (state: IStoreState) => {
    return {
        ...state
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUserProfile: (id: string) => dispatch(getUserProfile(id)),
        getHeroProfile: (id: string) => dispatch(getHeroProfile(id)),

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(HeroProfile);