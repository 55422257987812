import React from 'react';
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Card, Table } from "antd";
import { getExperiences } from "../../store/actions/experience";
import { IExperienceState } from "../../store/reducers/experience";
import { IStoreState } from '../../store/IStoreState';

interface IProps extends RouteComponentProps{
    display: boolean,
    ids: string[],
    hits: Array<{title, objectID}>,
    getExperiences?: (hits : Array<{title, objectID}>, activeOnly: boolean) => void,
    experience?: IExperienceState,
}

interface IState {
    display: boolean,
}

export interface IExperience {
    id: string,
    experience: string,
    heroName: string,
    userId: string,
}

const columns = [
    {
        title: "Experience",
        dataIndex: 'experience',
        key: 'experience',
    },
    {
        title: 'Hero Name',
        dataIndex: 'heroName',
        key: 'heroName',
    },
];

class ExperienceList extends React.Component <IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            display: false,
        }
    }

    UNSAFE_componentWillUpdate(nextProps: Readonly<IProps>): void {
        if (nextProps.ids !== this.props.ids)
        {
            this.props.getExperiences(nextProps.hits, true);
        }
    }

    componentDidMount(): void {
        this.props.getExperiences(this.props.hits, true)
    }

    render(): React.ReactNode {
        console.log(this.props);
        const { experiences } = this.props.experience;
        return (
            <Card title='Search Result' headStyle={{ backgroundColor: '#F8F8F8' }} bodyStyle={{ padding: "0" }}>
                <Table
                    columns={columns}
                    dataSource={experiences}
                    pagination={{ pageSize: 10, style: { float: "left" } }}
                    style={{ backgroundColor: 'white' }}
                    rowKey='id'
                    onRow={(record) => {
                        return {
                            onClick: () => {
                                this.props.history.push('/experiences/' + record.id);
                            }
                        }
                    }}
                />
            </Card>
        );
    }
}


const mapStateToProps = (state: IStoreState) => {
    return {
        ...state
    }};

const mapDispatchToProps = (dispatch) => {
    return {
        getExperiences: (hits:Array<{title, objectID}>, activeOnly: boolean) => dispatch(getExperiences(hits, activeOnly)),
    }
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps )(ExperienceList));