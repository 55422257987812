import React from 'react'
import { PlusOutlined } from '@ant-design/icons';
import { Card, Input, Table, Badge, Tag, Tooltip, message } from "antd";
import { Status } from '../experiences/ExperienceDetails';
import { RouteComponentProps, withRouter } from "react-router";
import { connect } from "react-redux";
import { IRewardState } from "../../store/reducers/rewards";
import { getAllRewards, showRewardForm } from "../../store/actions/rewards";
import RewardForm from "./RewardForm";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { IStoreState } from '../../store/IStoreState';

const { Search } = Input;

export interface IReward {
    id: string,
    type:string,
    title:string,
    createDate:string,
    expiryDate: string,
    used: string,
    status: Status,
}

interface IProps extends RouteComponentProps{
    getAllRewards : () => void,
    showRewardForm : (id? : string) => void,
    rewards: IRewardState,
}

interface IState {
    searchResults: IReward[],
}

const columns = [
    {
        title: 'ID',
        key: 'id',
        render: function render(record) {
            const success = () => {
                message.success('Reward ID Copied!');
            };
            return (
                <CopyToClipboard text={record.id}><Tooltip placement="top" title={record.id}><Tag onClick={(e) => {e.stopPropagation(); success()}}>ID</Tag></Tooltip></CopyToClipboard>
            );
        }
    },
    {
        title: 'Type',
        dataIndex: 'type',
    },
    {
        title: 'Title',
        dataIndex: 'title',
    },
    {
        title: 'Create Date',
        dataIndex: 'createDate',
    },
    {
        title: 'Expiry Date',
        dataIndex: 'expiryDate',
    },
    {
        title: 'Used',
        dataIndex: 'used',
    },
    {
        title: 'Active',
        key: 'active',
        render: function render(record) {
            return (
                record.status && <Badge status={record.status.type} text={record.status.title} />
            )
        }
    },
]


class AllRewards extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            searchResults: [],
        }
    }
    
    search(value: string) {
        const { rewards } = this.props.rewards;
        const results : IReward[] = [];
        rewards.forEach(reward => {
            if (reward.title.includes(value)) {
                results.push(reward);
            }
        })
        this.setState({ searchResults: results })
    }

    componentDidMount(): void {
        this.props.getAllRewards();
        this.setState({ searchResults: this.props.rewards.rewards })
    }

    componentDidUpdate(prevProps: Readonly<IProps>): void {
        if (prevProps.rewards !== this.props.rewards)
        {
            this.setState({ searchResults: this.props.rewards.rewards });
        }
    }

    render() {
        console.log(this.props);
        const { searchResults } = this.state;
        return (
            <div style={{ margin: '16px' }}>
                <Search
                    placeholder="Search Code/ID/Title"
                    onSearch={value => this.search(value)}
                    className="search"
                    style={{
                        marginBottom: "16px"
                    }}
                    size="large"
                />
                <Card style={{ marginBottom: 16 }} headStyle={{ backgroundColor: '#F8F8F8' }} title="Reward"
                    extra={<PlusOutlined onClick={() => this.props.showRewardForm()} />}
                >
                    <Table columns={columns}
                        dataSource={searchResults}
                        rowKey='id'
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    this.props.showRewardForm(record.id)
                                }
                            }
                        }
                        }

                    />
                </Card>
                <RewardForm />
            </div>
        );
    }
}

const mapStateToProps = (state: IStoreState) => {
    return {
        ...state,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAllRewards: () => dispatch(getAllRewards()),
        showRewardForm: (id? : string) => dispatch(showRewardForm(id)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AllRewards));