import React from 'react';
import { RouteComponentProps } from "react-router";
import UserInfo from "./traveller/UserInfo";
import HeroProfile from "./hero/HeroProfile";
import ExperienceRecordComponent from "./hero/ExperienceRecord";
import CashOutMethodComponent from "./hero/CashOutMethod";
import CashOutRecordComponent from "./hero/CashOutRecord";

interface MatchParams {
    id: string,
}

interface MatchProps extends RouteComponentProps<MatchParams>{
    params: { id },
}

interface IProps {
    match: MatchProps,
}


class UserHero extends React.Component <IProps> {
    render(): React.ReactNode {
        const { match } = this.props;
        const { id } = match.params;
        return (
            <div key={id}>
                <UserInfo id={id} />
                <HeroProfile id={id} />
                <ExperienceRecordComponent id={id} />
                <CashOutMethodComponent id={id} />
                <CashOutRecordComponent id={id} />
            </div>
        )
    }

}
export default UserHero;