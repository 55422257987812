import React from 'react'
import { Modal } from 'antd';

interface IProps {
    confirmHandler: () => void,
    cancelHandler: () => void,
    title: string,
    message: string,
    visible: boolean,
}

interface IState {
    visible: boolean,
}

class ConfirmationBox extends React.Component<IProps, IState> {
    constructor(props: Readonly<IProps>) {
        super(props);
        this.state = {
            visible: this.props.visible,
        }
    }

    handleCancel = ():void => {
        this.props.cancelHandler();
    };

    handleConfirm = ():void => {
        this.props.confirmHandler();
    }

    componentDidUpdate(): void {
        if (this.props.visible !== this.state.visible) this.setState({ visible: this.props.visible });
    }

    render(): React.ReactNode {
        return (
            <Modal
                visible={this.state.visible}
                title={this.props.title}
                onOk={this.handleConfirm}
                onCancel={this.handleCancel}
            >
                <p>{this.props.message}</p>
            </Modal>
        );
    }
}

export default ConfirmationBox;