import React from 'react'
import { Card, Col, message, Row, Tag, Tooltip } from 'antd';
import { getUserInfo } from "../../../store/actions/users";
import { connect } from "react-redux";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { detachUserInfo } from "../../../utility/unsubscribe";
import { IStoreState } from '../../../store/IStoreState';

export interface DisplayUser {
    Email : string,
    PhoneNumber: string,
    FirstName : string,
    LastName: string,
    Gender: string,
    DOB: string,
    Address: string,
    Activated: string,
    BetaUser: string,
    Ac: string,
    Balance: string,
    Credit : string,
    StripeID: string,
    RegistrationDate: string,
    LastSignIn: string,
    LastPayoutDate:string,
    Language: string,
    FCMToken: string,
}

interface IState {
    user : DisplayUser
}

interface IProps {
    id : string,
    getUserInfo: (id: string ) => void,
    users: {
        user: DisplayUser
    }
}

class UserInfo extends React.Component <IProps, IState> {
    constructor(props) {
        super(props);
        this.state =
            { user: {
                Email: '-',
                PhoneNumber: '-',
                FirstName: '-',
                LastName: '-',
                Gender: '-',
                DOB: '-',
                Address: '-',
                Activated: '-',
                BetaUser: '-',
                Ac: '-',
                Balance: '-',
                Credit: '-',
                StripeID: '-',
                RegistrationDate: '-',
                LastSignIn: '-',
                LastPayoutDate: '-',
                Language: '-',
                FCMToken: '-',
            }
            }

    }

    componentDidMount(): void {
        this.props.getUserInfo(this.props.id);
        this.setState({ user: this.props.users.user })
    }

    UNSAFE_componentWillUpdate(nextProps: Readonly<IProps>): void {
        if (nextProps.users.user !== this.props.users.user)
        {
            this.setState({ user: nextProps.users.user })

        }
    }

    componentWillUnmount(): void {
        detachUserInfo();
    }

    render() {
        // const { user } = this.state ;
        const { user } = this.props.users;
        const success = () => {
            message.success('Token Copied!');
        };
        const successCustomer = () => {
            message.success('Stripe Customer ID Copied!');
        };
        return (
            <Card title={"User Information (" + this.props.id + ")"} style={{ margin: '16px' }} headStyle={{ backgroundColor: '#F8F8F8' }} bodyStyle={{ paddingBottom: '12px', height: 'auto' }}>
                <div style={{ display: 'flex', flexDirection: 'column', lineHeight: '30px' }}>
                    <Row gutter={16} style={{ textAlign: 'left' }}>
                        <Col span={3} className='desc-label'> Email Address: </Col>
                        <Col span={5} className='desc-item'> {user.Email} </Col>
                        <Col span={3} className='desc-label border-left'>Activated: </Col>
                        <Col span={4} style={(user.Activated === 'No') ? { color: 'red' } : { color: 'green' }} > {user.Activated} </Col>
                        <Col span={4} className='desc-label border-left'> Registration Date: </Col>
                        <Col span={5} className='desc-item'> {user.RegistrationDate}  </Col>
                    </Row>
                    <Row gutter={16} style={{ textAlign: 'left' }}>
                        <Col span={3} style={{ color: '#37393A' }}> Phone Number: </Col>
                        <Col span={5} style={{ color: ' #898989' }}> {user.PhoneNumber} </Col>
                        <Col span={3}  style={{ color: '#37393A', borderLeft: '1px solid #EEEEEE', paddingLeft: '40px' }}> Beta User: </Col>
                        <Col span={4} style={{ color: ' #898989' }}> {user.BetaUser} </Col>
                        <Col span={4} style={{ color: '#37393A', borderLeft: '1px solid #EEEEEE', paddingLeft: '40px' }} > Last Sign In Date: </Col>
                        <Col span={5} style={{ color: ' #898989' }}> {user.LastSignIn}  </Col>
                    </Row>
                    <Row gutter={16} style={{ textAlign: 'left' }}>
                        <Col span={3} style={{ color: '#37393A' }}> First Name  </Col>
                        <Col span={5} style={{ color: ' #898989' }}> {user.FirstName} </Col>
                        <Col span={3}  style={{ color: '#37393A', borderLeft: '1px solid #EEEEEE', paddingLeft: '40px' }}> AC: </Col>
                        <Col span={4} style={{ color: ' #898989' }}> {user.Ac} </Col>
                        <Col span={4} style={{ color: '#37393A', borderLeft: '1px solid #EEEEEE', paddingLeft: '40px' }} > Last payout Date: </Col>
                        <Col span={5} style={{ color: ' #898989' }}> {user.LastPayoutDate}  </Col>
                    </Row>
                    <Row gutter={16} style={{ textAlign: 'left' }}>
                        <Col span={3} style={{ color: '#37393A' }}> Last Name  </Col>
                        <Col span={5} style={{ color: ' #898989' }}> {user.LastName} </Col>
                        <Col span={3}  style={{ color: '#37393A', borderLeft: '1px solid #EEEEEE', paddingLeft: '40px' }}> Balance: </Col>
                        <Col span={4} style={{ color: ' #898989' }}> ${user.Balance} </Col>
                        <Col span={4} style={{ color: '#37393A', borderLeft: '1px solid #EEEEEE', paddingLeft: '40px' }} > Preferred language: </Col>
                        <Col span={5} style={{ color: ' #898989' }}> {user.Language}  </Col>
                    </Row>
                    <Row gutter={16} style={{ textAlign: 'left' }}>
                        <Col span={3} style={{ color: '#37393A' }}> Gender  </Col>
                        <Col span={5} style={{ color: ' #898989' }}> {user.Gender} </Col>
                        <Col span={3}  style={{ color: '#37393A', borderLeft: '1px solid #EEEEEE', paddingLeft: '40px' }}> Credit: </Col>
                        <Col span={4} style={{ color: ' #898989' }}> ${user.Credit} </Col>
                        <Col span={4} style={{ color: '#37393A', borderLeft: '1px solid #EEEEEE', paddingLeft: '40px' }} > FCM Token: </Col>
                        <Col span={5} style={{ color: ' #898989', wordWrap: 'break-word' }} >
                            <CopyToClipboard text={user.FCMToken}>
                                <Tooltip placement="top" title={user.FCMToken}><Tag onClick={success} style={{ cursor: 'pointer' }}>Token</Tag></Tooltip>
                            </CopyToClipboard>
                        </Col>
                    </Row>
                    <Row gutter={16} style={{ textAlign: 'left' }}>
                        <Col span={3} style={{ color: '#37393A' }}> Birthday:  </Col>
                        <Col span={5} style={{ color: ' #898989' }}> {user.DOB} </Col>
                        <Col span={3}  style={{ color: '#37393A', borderLeft: '1px solid #EEEEEE', paddingLeft: '40px' }}> Stripe ID: </Col>
                        <Col span={4} style={{ color: ' #898989' }}>
                            <CopyToClipboard text={user.StripeID}>
                                <Tooltip placement="top" title={user.StripeID}><Tag onClick={successCustomer} style={{ cursor: 'pointer' }}>Customer</Tag></Tooltip>
                            </CopyToClipboard>
                        </Col>
                        <Col span={4} style={{ color: '#37393A', borderLeft: '1px solid #EEEEEE', paddingLeft: '40px' }} >  </Col>
                        <Col span={5} style={{ color: ' #898989', wordWrap: 'break-word' }}>  </Col>
                    </Row>
                    <Row gutter={16} style={{ textAlign: 'left' }}>
                        <Col span={3} style={{ color: '#37393A' }}> Address:  </Col>
                        <Col span={5} style={{ color: ' #898989' }}> {user.Address} </Col>
                        <Col span={3}  style={{ color: '#37393A', borderLeft: '1px solid #EEEEEE', paddingLeft: '40px' }}>  </Col>
                        <Col span={4} style={{ color: ' #898989' }}> </Col>
                        <Col span={4} style={{ color: '#37393A', borderLeft: '1px solid #EEEEEE', paddingLeft: '40px' }} >  </Col>
                        <Col span={5} style={{ color: ' #898989', wordWrap: 'break-word' }}>  </Col>
                    </Row>


                    {/*<Descriptions column={1} style={{width:"fit-content"}}>*/}
                    {/*    <Descriptions.Item label="Email Address" >{user.Email}</Descriptions.Item>*/}
                    {/*    <Descriptions.Item label="Phone Number" >{user.PhoneNumber}</Descriptions.Item>*/}
                    {/*    <Descriptions.Item label="First Name" >{user.FirstName}</Descriptions.Item>*/}
                    {/*    <Descriptions.Item label="Last Name">{user.LastName}</Descriptions.Item>*/}
                    {/*    <Descriptions.Item label="Gender"> {user.Gender} </Descriptions.Item>*/}
                    {/*    <Descriptions.Item label="Birthday">{user.DOB}</Descriptions.Item>*/}
                    {/*    <Descriptions.Item label="Address">{user.Address}</Descriptions.Item>*/}
                    {/*</Descriptions>*/}
                    {/*<Descriptions column={1}>*/}
                    {/*    <Descriptions.Item label="Activated">*/}
                    {/*        <span style={(user.Activated === 'No') ? {color:'red'} : {color: 'green'}}>{user.Activated}</span>*/}
                    {/*    </Descriptions.Item>*/}
                    {/*    <Descriptions.Item label="Beta User">{user.BetaUser}</Descriptions.Item>*/}
                    {/*    <Descriptions.Item label="AC"> {user.Ac}</Descriptions.Item>*/}
                    {/*    <Descriptions.Item label="Balance">HKD{user.Balance}</Descriptions.Item>*/}
                    {/*    <Descriptions.Item label="Credit">{user.Credit}</Descriptions.Item>*/}
                    {/*    <Descriptions.Item label="Stripe Customer ID">{user.StripeID}*/}
                    {/*    <CopyToClipboard text={user.StripeID}>*/}
                    {/*    <Icon type="copy" style={{paddingLeft:'2px'}}/>*/}
                    {/*    </CopyToClipboard>*/}
                    {/*    </Descriptions.Item>*/}
                    {/*</Descriptions>*/}
                    {/*<Divider type= 'vertical' style={{height:'auto'}} />*/}
                    {/*<Descriptions column={1} >*/}
                    {/*    <Descriptions.Item label="Registration date">{user.RegistrationDate}</Descriptions.Item>*/}
                    {/*    <Descriptions.Item label="Last sign in date">{user.LastSignIn}</Descriptions.Item>*/}
                    {/*    <Descriptions.Item label="Last payout  date">{user.LastPayoutDate}</Descriptions.Item>*/}
                    {/*    <Descriptions.Item label="Preferred Language">{user.Language}</Descriptions.Item>*/}
                    {/*    <Descriptions.Item label="FCM token" ><div style={{wordWrap:'break-word'}}>{user.FCMToken}</div></Descriptions.Item>*/}
                    {/*</Descriptions>*/}
                </div>
            </Card>
        );
    }
}

const mapStateToProps = (state: IStoreState) => {
    return {
        ...state
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUserInfo: (id: string) => {
            return dispatch(getUserInfo(id))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo);