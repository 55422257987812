import React from 'react';
import { connect } from "react-redux";
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Card, Input, Table, Tag, Tooltip, Button } from "antd";
import { getStrings, showStringForm } from "../../store/actions/contentManagement";
import { ICMState } from "../../store/reducers/contentManagement";
import StringForm from "./StringForm";
import Highlighter from 'react-highlight-words';
import { IStoreState } from '../../store/IStoreState';

interface IProps {
    showStringForm : (id?:string) => void,
    getStrings : () => void,
    contentManagement : ICMState,
}

interface IState {
    searchText: string
    strings: IString[]
}

export interface IString {
    key: string,
    languages: {
        en: string,
        zh: string,
    },
    updatedBy?: string,
    lastUpdated?: string,
}

class Strings extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            strings: [],
        }
    }

    componentDidMount(): void {
        this.props.getStrings();
        this.setState({strings: this.props.contentManagement.strings})
    }

    componentDidUpdate(prevProps: Readonly<IProps>): void {
        if (this.state.strings.length !== this.props.contentManagement.strings.length)
        {
            var results = [...this.props.contentManagement.strings]
            this.setState({strings: results});
        }
    }

    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    // ref={node => {
                    //     this.searchInput = node;
                    // }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                // setTimeout(() => this.searchInput.select());
            }
        },
        render: text => (
            <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[this.state.searchText]}
                autoEscape
                textToHighlight={text.toString()}
            />
        ),
    });

    render(): React.ReactNode {
        const { strings } = this.state;
        const columns = [
            {
                title: 'Key',
                key: 'key',
                dataIndex: 'key',
                ...this.getColumnSearchProps('key')
            },
            {
                title: 'Languages',
                key: 'languages',
                render: record => {
                    return (
                        <span>
                            {record.languages.en && <Tooltip placement="top" title={record.languages.en.split(" ").splice(0, 30).join(" ")}>
                                <Tag>en</Tag>
                            </Tooltip>}
                            {record.languages.zh && <Tooltip placement="top" title={record.languages.zh.split(" ").splice(0, 30).join(" ")}>
                                <Tag>zh</Tag>
                            </Tooltip>}
                        </span>
                    )
                }
            },
            {
                title: "Updated By",
                key: "updatedBy",
                dataIndex: 'updatedBy'
            },
            {
                title: 'Last Updated',
                key: 'lastUpdated',
                dataIndex: 'lastUpdated'
            }
        ]
        return (
            <div>
                <Card style={{ margin: '16px' }} headStyle={{ backgroundColor: '#F8F8F8' }} title="Reward"
                    extra={<PlusOutlined onClick={() => this.props.showStringForm()} />}
                >
                    <Table columns={columns}
                        dataSource={strings}
                        rowKey='id'
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    this.props.showStringForm(record.key)
                                }
                            }
                        }
                        }

                    />
                </Card>
                <StringForm />
            </div>
        );
    }
}

const mapStateToProps = (state: IStoreState) => {
    return {
        ...state,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        showStringForm: (id?:string) => dispatch(showStringForm(id)),
        getStrings: () => dispatch(getStrings()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Strings);