import React from 'react'
import { matchPath, RouteComponentProps, withRouter } from "react-router";
import { Button, message } from "antd";
import MasterExperience from "./MasterExperience";
import RenameExperienceForm from './RenameExperience';
import ConfirmationBox from "../ConfirmationBox"
import { IExperienceState } from "../../store/reducers/experience";
import { disableExperience, showMasterExperience, showRenameExperience } from "../../store/actions/experience";
import { connect } from "react-redux";
import { IStoreState } from '../../store/IStoreState';

interface IProps extends RouteComponentProps {
    showMasterExperience: () => void,
    disableExperience: (id: string, success: () => void, fail: () => void) => void,
    showRenameExperience: () => void,
    experience: IExperienceState,
}

interface IState {
    experienceBtn: boolean,
    id: string,
    showDisableConfirmation: boolean,
}

class ExperienceActions extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            experienceBtn: false,
            id: ' ',
            showDisableConfirmation: false
        };
    }

    componentDidUpdate(): void {
        const { pathname } = this.props.location;

        const experienceBtn = matchPath(pathname, {
            path: "/experiences/:id",
            exact: true,
            strict: false,
        });

        if (experienceBtn && experienceBtn.isExact) {
            const i = pathname.indexOf('experiences') + 12;
            const experienceId = pathname.substring(i);
            if (!this.state.experienceBtn) this.setState({ id: experienceId, experienceBtn: true });
        }
        else {
            if (this.state.experienceBtn) this.setState({ experienceBtn: false, });
        }
    }

    confirmDisableExp = () => {
        this.props.disableExperience(this.state.id, this.success, this.fail);
        message.info("Disabling experience...");
        this.setState({ showDisableConfirmation: false });
    }

    cancelDisableExp = () => {
        this.setState({ showDisableConfirmation: false });
    }

    success = () => {
        message.success("Experience disabled successfully!");
    }

    fail = () => {
        message.error("Failed to disable experience...");
    }

    render(): React.ReactNode {

        return (
            <div style={{ margin: "auto 0" }}>
                {this.state.experienceBtn &&
                    <div>
                        <span
                            style={{ marginRight: '16px', color: '#898989', textDecoration: 'underline', cursor: 'pointer' }}
                            onClick={this.props.showMasterExperience} >
                            Go to Master Experience
                        </span>
                        <Button
                            type="primary"
                            style={{ marginRight: '16px', backgroundColor: '#F7C16A', border: '0px' }}
                            onClick={this.props.showRenameExperience}
                            className="user-button">
                            Rename Experience
                        </Button>
                        <Button
                            type="primary"
                            style={{ marginRight: '16px', backgroundColor: '#F7685B', border: '0px' }}
                            onClick={() => this.setState({ showDisableConfirmation: true })}
                            className="user-button">
                            Disable Experience
                        </Button>
                        <MasterExperience id={this.state.id} />
                        <RenameExperienceForm id={this.state.id} />
                        <ConfirmationBox
                            visible={this.state.showDisableConfirmation}
                            title={"Confirmation"}
                            message={"Are you sure to disable experience?"}
                            confirmHandler={this.confirmDisableExp}
                            cancelHandler={this.cancelDisableExp}
                        />
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state: IStoreState) => {
    return {
        ...state
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        showMasterExperience: () => dispatch(showMasterExperience()),
        showRenameExperience: () => dispatch(showRenameExperience()),
        disableExperience: (id: string, success: () => void, fail: () => void) => dispatch(disableExperience(id, success, fail)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ExperienceActions));
