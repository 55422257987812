import React from 'react';
import { Card, message, Table, Tag, Tooltip, Select } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { IStoreState } from "../../store/IStoreState";
import { IAdminState } from "../../store/reducers/admin";
import { getAdminUsers, updateAdminUser } from "../../store/actions/admin";
import { connect } from 'react-redux';

const { Option, OptGroup } = Select;

interface IProps {
    getAdminUsers : () =>void,
    updateAdminUser: (id: string, role: string) => void,
    admin:IAdminState,
}

interface IState {
    filteredInfo: {text, value, children, roles},
    sortedInfo: {columnKey, order},
}

export interface IAdminUser {
    id?: string,
    user: string,
    name?:string,
    lastLogin: string,
    role:string,
    roleNumber: number,
}

class AdminUsers extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            filteredInfo: null,
            sortedInfo: null
        }
    }
    componentDidMount(): void {
        this.props.getAdminUsers();
        console.log("okokok");
    }

    handleChange = (pagination, filters, sorter) => {
        console.log('Various parameters', pagination, filters, sorter);
        this.setState({
            filteredInfo: filters,
            sortedInfo: sorter,
        });
    };

    clearFilters = () => {
        this.setState({ filteredInfo: null });
    };

    render(): React.ReactNode {
        const { adminUsers, updating } = this.props.admin;
        let { sortedInfo, filteredInfo } = this.state;
        sortedInfo = sortedInfo || { columnKey: null, order: null };
        filteredInfo = filteredInfo || { text: null, value: null, children: null, roles: null };
        const columns = [
            {
                title: 'ID',
                key: 'id',
                render: record => {
                    const success = () => {
                        message.success('User ID Copied!');
                    };
                    return (
                        <CopyToClipboard text={record.id}><Tooltip placement="top" title={record.id}><Tag onClick={success}>ID</Tag></Tooltip></CopyToClipboard>
                    )
                }
            },
            {
                title: "User's Email",
                key: 'user',
                dataIndex: 'user',
                sorter: (a, b) => a.user.length - b.user.length,
                sortOrder: sortedInfo.columnKey === 'user' && sortedInfo.order,
                ellipsis: true,
            },
            {
                title: 'Name',
                key: 'name',
                dataIndex: 'name',
                sorter: (a, b) => (a.name && b.name) && a.name.length - b.name.length,
                sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
                ellipsis: true,
            },
            {
                title: 'Last Login Date Time',
                key: 'lastLogin',
                dataIndex: 'lastLogin',
                sorter: (a, b) => (a.lastLogin && b.lastLogin) && a.lastLogin.moment.unix() - b.lastlogin.moment.unix(),
                sortOrder: sortedInfo.columnKey === 'lastLogin' && sortedInfo.order,
                ellipsis: true,
            },
            {
                title: 'Roles',
                key: 'roles',
                sorter: (a, b) => a.role.length - b.role.length,
                sortOrder: sortedInfo.columnKey === 'roles' && sortedInfo.order,
                ellipsis: true,
                filters: [
                    { text: 'IT', value: 'IT', children: [{ text: 'Head', value: 'IT_Head' }, { text: 'Developer', value: 'IT_Developer' }]},
                    { text: 'Operation', value: 'Operation', children: [{ text: 'Head', value: 'Operation_Head' }, { text: 'Developer', value: 'Operation_Developer' }]},
                    { text: 'Marketing', value: 'Marketing', children: [{ text: 'Head', value: 'Marketing_Head' }, { text: 'Developer', value: 'Marketing_Developer' }]},
                ],
                filteredValue: filteredInfo.roles || null,
                onFilter: (value, record) => record.role === value,
                render: record => {
                    const handleChange = (value) => {
                        this.props.updateAdminUser(record.id, value);
                        console.log('handling change');
                    }
                    return (
                        <Select defaultValue={record.role.replace(/_/g, ' ')} onChange={value => handleChange(value)}
                            style={{ width: '200px', textAlign: 'left' }}  loading={(record.id === updating.id) && updating.updating} disabled={(record.id === updating.id) && updating.updating}>
                            <OptGroup label="IT">
                                <Option value="IT Head">IT Head</Option>
                                <Option value="IT Developer">IT Developer</Option>
                            </OptGroup>
                            <OptGroup label="Operation">
                                <Option value="Operation Head">Operation Head</Option>
                                <Option value="Operation Staff">Operation Staff</Option>
                            </OptGroup>
                            <OptGroup label="Marketing">
                                <Option value="Marketing Head">Marketing Head</Option>
                                <Option value="Marketing Staff">Marketing Staff</Option>
                            </OptGroup>
                        </Select>
                    )
                }
            }
        ];
        return (
            <Card style={{ margin: '16px' }} headStyle={{ backgroundColor: '#F8F8F8' }} title="Admin Users"
            >
                <Table columns={columns}
                    dataSource={adminUsers}
                    onChange={this.handleChange}
                    rowKey='id'
                />
            </Card>

        )
    }
}

const mapStateToProps = (state: IStoreState) => {
    return {
        ...state,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAdminUsers: () => dispatch(getAdminUsers()),
        updateAdminUser: (id: string, role:string) => dispatch(updateAdminUser(id, role)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminUsers);