import React from 'react'
import { Card, Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';

interface RefundRecord {
    id: string,
    btId: string,
    paymentId: string,
    btRefundId: string,
    refundDate: Date,
    refundedPercentage: number,
    refunded: number,
    status: string,
}
const columns: ColumnProps<RefundRecord>[] = [
    {
        title: 'ID',
        dataIndex: 'id',
    },
    {
        title: 'Payment ID',
        dataIndex: 'paymentId'
    },
    {
        title: 'Stripe ID',
        dataIndex: 'stripeId',
    },
    {
        title: 'Stripe Refund ID',
        dataIndex: 'stripeRefundId',
    },
    {
        title: 'Refund Date',
        dataIndex: 'refundDate',
    },
    {
        title: 'Refunded %',
        dataIndex: 'refundedPercentage',
    },
    {
        title: 'Refunded',
        dataIndex: 'refunded',
    },
    {
        title: 'Status',
        dataIndex: 'status',
    },

];
const data: RefundRecord[] = [];

interface IProps {
    id: string,
}

class RefundRecord extends React.Component <IProps> {
    render(): React.ReactNode {
        return (
            <Card style={{ margin: '0px 16px', marginBottom: 16, }} title='Refund Record' headStyle={{ backgroundColor: '#F8F8F8' }} bodyStyle={{ padding: '0' }}>
                <Table columns={columns}
                    className="table"
                    dataSource={data}
                    bordered={false}
                    pagination={{ pageSize: 4, hideOnSinglePage: true, style: { float: "left" } }}/>
            </Card>
        );
    }
}


export default RefundRecord
