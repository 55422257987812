import React from 'react'
import { Modal, Table } from 'antd';
import { IExperienceState } from "../../store/reducers/experience";
import { getMasterExperience, hideMasterExperience } from "../../store/actions/experience";
import { RouteComponentProps, withRouter } from "react-router";
import { connect } from "react-redux";
import { IStoreState } from '../../store/IStoreState';

export interface MasterExperienceItem {
    id: string,
    create: string,
    name: string,
    createDate: string,
}

interface IProps extends RouteComponentProps {
    id: string,
    getMasterExperience: (id:string) => void,
    hideMasterExperience : () => void,
    experience: IExperienceState,
}

const columns = [
    {
        title: 'Create',
        dataIndex: 'create',
    },
    {
        title: 'Name',
        dataIndex: 'name',
    },
    {
        title: 'Create Date',
        dataIndex: 'createDate',
    },
];

class MasterExperience extends React.Component<IProps> {
    componentDidMount(): void {
        this.props.getMasterExperience(this.props.id);
    }

    handleCancel = e => {
        console.log(e);
        this.props.hideMasterExperience();
    };

    render(): React.ReactNode {
        const { masterExperiences, showMasterList } = this.props.experience;
        return (
            <Modal footer={null} title="Master Experience List" onCancel={this.handleCancel} visible={showMasterList}>
                <Table showHeader={false} columns={columns} dataSource={masterExperiences}
                    onRow={(record, index) => {
                        return {
                            onClick: (e) => {
                                this.props.history.push('/experiences/' + masterExperiences[index].id);
                                this.handleCancel(e)
                            }
                        }
                    }}
                >
                </Table>
            </Modal>
        )
    }
}
const mapStateToProps = (state: IStoreState) => {
    return {
        ...state
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getMasterExperience: (id:string) => dispatch(getMasterExperience(id)),
        hideMasterExperience: () => dispatch(hideMasterExperience()),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MasterExperience));
