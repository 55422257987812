import React from "react"
import { UserOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Form } from "antd";
import { hideResetForm, resetPassword } from "../store/actions/user";
import { connect } from "react-redux";
import { IUserState } from "../store/reducers/user";
import { RouteComponentProps } from "react-router";
import { IStoreState } from "../store/IStoreState";

interface IProps extends RouteComponentProps{
    visible: boolean,
    user: IUserState,
    resetPassword: (email: string) => void;
    hideResetForm : () => void;
}

interface IState {
    visible: boolean
}

class PasswordReset extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            ...props
        }
    }

    componentDidMount(): void {
        this.setState({ visible: this.props.visible })
    }

    handleCancel = e => {
        console.log(e);
        this.props.hideResetForm();
    };

    handleSubmit = (values) => {
        this.props.resetPassword(values.email);
    }

    render(): React.ReactNode {
        const { resetEmail, showResetForm } = this.props.user;
        return (
            <div>
                <Modal visible={showResetForm} footer={null}  onCancel={this.handleCancel} centered>
                    { !resetEmail && <Form onFinish={this.handleSubmit} className="login-form">
                        Forgot Password
                        <Form.Item
                            name="email"
                            rules={[{ required: true, message: 'Please input your email address!' }]}
                        >
                            <Input
                                prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                placeholder="Email"
                            />
                        </Form.Item>

                        <Button type="primary" htmlType="submit" className="login-form-button">
                            Reset Password
                        </Button>
                    </Form>}
                    { resetEmail && <span style={{ color: "#F68A5B" }}> Password reset email sent. Follow directions in the email to reset password. </span> }
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state:IStoreState) => ({
    user: state.user,
});

const mapDispatchToProps = dispatch => {
    return ({
        resetPassword: (email: string) => dispatch(resetPassword(email)),
        hideResetForm: () => dispatch(hideResetForm()),
    })
}
/* eslint-disable  @typescript-eslint/no-explicit-any */
export default (connect(mapStateToProps, mapDispatchToProps)(PasswordReset) as any);