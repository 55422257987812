import { firestore } from "firebase";

export enum ActivityType {
    Journey,
    Adventure,
    Event,
}
export enum SessionMessageType {
    User,
    System,
}

export interface ActivityStage {
    isAnySessionStarted: boolean;
    isAllSessionEnded: boolean;
    isAllSessionSealed: boolean;
}

export interface ActivityMaster {
    id: string;
    currentOrder: number;
    currentActivityId: string;
    nextActivityId?: string;
    userId: string;
    categoryId: string;
    rating: number;
    pledgedCount: number;
    pledgedAmount: number;
    shockCount: number;
    isCanLaunchNotifcationSent: boolean;
}
export interface Activity {
    id?: string;
    masterId: string;
    order: number;
    userId: string;
    type?: ActivityType;
    categoryId: string;
    title: string;
    description: string;
    customTypeId: string;
    createdDateTime?: number;
    launchedDateTime?: number;
    fee?: number;
    currencyCode?: string;
    languageCode?: string;
    whatToExpect?: string;
    whoShouldJoin?: string;
    getReady?: Array<string>;
    keywords?: Array<string>;
    coverPhotoUrl?: string;
    coverPhotoRatio?: number;
    coverPhotoLastModified?: number;
    sessionCount?: number;
    dailyUpdateCount?: number;
    packagePrices?: Array<number>;
    rating: number;
    isActive: boolean;
    isLaunched: boolean;
    isAnySessionStarted: boolean;
    isAllSessionFulled: boolean;
    isAllSessionEnded: boolean;
    isAllSessionSealed: boolean;
    isRecreatable: boolean;
    lastModifiedDateTime?: number;
}
export interface ActivityStats {
    id?: string;
    totalIncomes: number;
}
export interface ActivityInfo {
    id?: string;
    shockCount: number;
    isCanLaunchNotifcationSent: boolean;
}
export interface ActivitySession {
    id?: string;
    masterId: string;
    activityId: string;
    userId: string;
    categoryId: string;
    type?: ActivityType;
    customTypeId: string;
    launchedDateTime: number;
    startDateTime: number;
    endDateTime: number;
    index: number;
    pax: number;
    minPax: number;
    currentParticipantCount: number;
    location: firestore.GeoPoint;
    address: string;
    zone?: string;
    timezoneOffset?: number;
    rating: number;
    isLaunched: boolean;
    isFulled: boolean;
    isSealed: boolean;
    isDismissed: boolean;
    isFundReleased: boolean;
}
export interface ActivitySessionStats {
    id?: string;
    totalIncomes: number;
}
export interface SessionParticipant {
    id?: string;
    joinedDateTime: number;
    attendDateTime?: number;
    bundleId?: string;
    getReady: Array<string>;
    lastSeenDateTime: number;
}
export interface CustomisationAnswer {
    id?: string;
    answer: string;
}
export interface SessionMessage {
    id?: string;
    type: SessionMessageType;
    sentDateTime: number;
}
export interface SessionUserMessage extends SessionMessage {
    senderId: string;
    message: string;
}
/**
export interface SessionSystemMessage extends SessionMessage {
    // titleStringId?: StringKey;
    titleArgs?: Array<any>;
    // bodyStringId?: StringKey;
    bodyArgs?: Array<any>;
}
*/
export interface ActivityBundle {
    id?: string;
    title: string;
    description?: string;
    index: number;
    price: number;
    stock: number;
    remaining: number;
    customisations?: BundleCustomisation[]; // for callable function parameter only
    photoUrl?: string;
    photoRatio?: number;
}
export interface BundleCustomisation {
    id?: string;
    customisation: string;
}
export interface ActivityReview {
    id?: string;
    activityId: string;
    sessionId?: string;
    userId: string;
    rating: number;
    comments?: string;
    createdDateTime: number;
}
export interface MasterActivityRecord {
    id?: string;
    order: number;
    createdDateTime: number;
}
export interface Shock {
    id?: string;
    shocked: boolean;
    datetime: number;
}