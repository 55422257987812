import React from 'react';
import { SaveOutlined } from '@ant-design/icons';
import { Button, Card, Typography } from "antd";
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ICMState } from "../../store/reducers/contentManagement";
import { getHtml, saveHtml } from "../../store/actions/contentManagement";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter, Prompt } from "react-router";
import { IStoreState } from '../../store/IStoreState';

const { Text } = Typography;

interface IProps extends RouteComponentProps{
    type:string,
    saveHtml : (type: string, data:string) => void,
    getHtml : (type:string) => void,
    contentManagement?: ICMState,
}

interface IState {
    data:string,
    unsaved: boolean,
    initialLoad: boolean,
}

class TextEditor extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            data: '',
            unsaved: true,
            initialLoad: false,
        }
    }
    componentDidMount(): void {
        this.props.getHtml(this.props.type);
        this.setState({ initialLoad: true });
    }

    componentDidUpdate = () => {
        if (this.state.unsaved) {
            console.log('here');
            window.onbeforeunload = () => true
        } else {
            window.onbeforeunload = undefined
        }
    }

    render() {
        console.log(this.props);
        const { type } = this.props;
        const { html, htmlLoading } = this.props.contentManagement;
        const data = html[type];
        return (
            <Card style={{ margin: '16px' }} headStyle={{ backgroundColor: '#F8F8F8' }} title={type}
                extra={<>
                    <Text style={{ paddingRight: '6px' }}>{this.state.unsaved && "unsaved" } </Text>
                    <Button icon={<SaveOutlined />} loading={htmlLoading} disabled={htmlLoading}
                        onClick={() => {
                            this.props.saveHtml(type, this.state.data)
                            this.setState({ unsaved: false })
                        }}></Button>
                </>}
            >
                <React.Fragment>
                    <Prompt
                        when={this.state.unsaved}
                        message='You have unsaved changes, are you sure you want to leave?'
                    />
                    {/* Component JSX */}
                </React.Fragment>
                <CKEditor
                    editor={ClassicEditor}
                    data={data}
                    onInit={editor => {
                        // You can store the "editor" and use when it is needed.
                        const data = editor.getData();
                        console.log('initiate ' + type);
                        this.setState({ data: data, unsaved: false });
                        console.log(data);
                        console.log( 'Editor is ready to use!', editor );
                    }}
                    onChange={( event, editor ) => {
                        const data = editor.getData();
                        if (data !== this.state.data && !this.state.initialLoad)
                        {
                            this.setState({ data: data, unsaved: true })
                            console.log('On changes for type: ' + type);
                        } else {
                            this.setState({ data: data, unsaved: false, initialLoad: false });
                        }


                    }}
                    onBlur={( event, editor ) => {
                        console.log( 'Blur.', editor );
                    }}
                    onFocus={( event, editor ) => {
                        console.log( 'Focus.', editor );
                    }}
                />
            </Card>
        );
    }
}

const mapStateToProps = (state :IStoreState) => {
    return {
        ...state,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        saveHtml: (type:string, data:string) => dispatch(saveHtml(type, data)),
        getHtml: (type:string) => dispatch(getHtml(type)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TextEditor));