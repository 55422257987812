import { Firebase, FirebaseDB, FirebaseFunctions } from "../../lib/firebase";
import moment from 'moment';
import { Journey } from "../../components/users/traveller/Jouneys";
import { ExperienceHistory } from "../../components/users/traveller/ExperienceHistories";
import { PaymentRecord } from "../../components/users/traveller/PaymentRecord";
import { Activity, ActivitySession, ActivityStage, SessionParticipant } from "../../models/activity";
import { FirestoreKey } from "../../constants/setting";
import { User, UserCitizenProfile, UserHeroProfile, UserInfo, UserVariable } from "../../models/user";
import { IUser } from "../../components/users/UsersList";
import { ExperienceRecord } from "../../components/users/hero/ExperienceRecord";
import { CashOutMethod } from "../../components/users/hero/CashOutMethod";
import { HSBC, Payout, PayoutMethod, PayoutMethodType, PayoutStatus, Paypal, FPS } from "../../models/payout";
import { CashOutRecord } from "../../components/users/hero/CashOutRecord";
import { IPaymentMethod } from "../../components/users/traveller/PaymentMethod";
import { dateStringFormat } from "../../constants/setting";
import { AppThunk } from "../IStoreState"
import { JoinFeePayment } from "../../models/payment";

export const GET_USERS = 'GET_USERS';
export const USERS_ERROR = 'USERS_ERROR';
export const GET_USER_INFO = 'GET_USER_INFO';
export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const GET_JOURNEYS = 'GET_JOURNEYS';
export const GET_EXPERIENCE_HISTORIES = "GET_HISTORIES"
export const GET_PAYMENT_RECORD = 'GET_PAYMENT_RECORD';
export const SHOW_HERO_BUTTON = 'SHOW_HERO_BUTTON';
export const GET_HERO_STATUS = 'GET_HERO_STATUS';
export const GET_HERO_PROFILE = 'GET_HERO_PROFILE';
export const GET_PAYMENT_METHOD = 'GET_PAYMENT_METHOD';
export const GET_EXPERIENCE_RECORD = 'GET_EXPERIENCE_RECORD';
export const GET_CASHOUT_METHODS = 'GET_CASHOUT_METHODS';
export const GET_CASHOUT_RECORD = 'GET_CASHOUT_RECORD';
export const GET_USER_ACTIVATION = 'GET_USER_ACTIVATION';
export const ENABLE_USER = 'ENABLE_USER';
export const PRE = 'Concept';
export const LAUNCHED = 'Open';
export const ON_GOING = 'On Going';
export const COMPLETED = 'Completed';
export const SEALED = 'Sealed'

export function getUsers(ids: string[]): AppThunk {
    return async dispatch => {
        if (Firebase === null) {
            dispatch({
                type: USERS_ERROR,
                data: "Server error"
            });
            return;
        }
        try {
            const users = [] as Array<IUser>;
            const dbRef = await FirebaseDB.collection('usersInfo');
            const dbRef2 = await FirebaseDB.collection('usersVariable');
            await Promise.all(
                ids.map(async id => {
                    const user = {} as IUser;
                    dbRef.doc(id).onSnapshot(doc => {
                        const data = doc.data() as UserInfo;
                        if (data) {
                            user.id = doc.id;
                            user.phoneNumber = data.phoneNumber;
                        }
                    })
                    dbRef2.doc(id).onSnapshot(doc => {
                        const data = doc.data() as UserVariable;
                        if (doc.id === user.id) {
                            user.email = data.email
                        }
                        users.push(user);
                        if (users.length === ids.length && ids.length !== 0) {
                            dispatch({
                                type: GET_USERS,
                                data: users,
                            })
                        }
                    })
                })
            )

            dispatch({
                type: GET_USERS,
                data: users,
            })



        } catch (error) {
            dispatch({
                type: USERS_ERROR,
                data: error.message,
            });
        }
    }
}

export function getUserInfo(id: string): AppThunk {
    return async dispatch => {
        if (Firebase === null) {
            dispatch({
                type: USERS_ERROR,
                data: "Server error"
            });
            return;
        }
        try {
            const userInfo = {}
            const docRef = await FirebaseDB.collection('usersVariable').doc(id);
            docRef.onSnapshot(doc => {
                const data = doc.data() as UserVariable;
                if (data) {
                    userInfo['Email'] = data.email;
                    userInfo['Activated'] = data.isActivated ? 'Yes' : 'No';
                    userInfo['BetaUser'] = data.isBetaUser ? 'Yes' : 'No';
                    userInfo['Ac'] = data.ac;
                    userInfo['Balance'] = data.balance;
                    userInfo['Credit'] = data.credit;
                    userInfo['StripeID'] = data.stripeCustomerId;
                    userInfo['RegistrationDate'] = moment(data.registrationDate).format(dateStringFormat.dateTime);
                    userInfo['LastPayoutDate'] = moment(data.lastPayoutDate).format(dateStringFormat.dateTime);
                }

            });
            const docRef2 = await FirebaseDB.collection('usersInfo').doc(id);
            docRef2.onSnapshot(doc => {
                const data = doc.data() as UserInfo;
                if (data) {
                    userInfo['PhoneNumber'] = data.phoneNumber ? data.phoneNumber : '-';
                    userInfo['FirstName'] = data.firstName ? data.firstName : '-';
                    userInfo['LastName'] = data.lastName ? data.lastName : '-';
                    userInfo['Gender'] = data.gender ? data.gender : '-';
                    userInfo['DOB'] = moment(data.dob).format(dateStringFormat.dateTime);
                    userInfo['Address'] = data.addressLine ? data.addressLine + " ," + data.addressCity : '-';
                    userInfo['LastSignIn'] = moment(data.lastSignInTime).format(dateStringFormat.dateTime);
                    userInfo['Language'] = data.language ? data.language : '-';
                    userInfo['FCMToken'] = data.fcmToken ? data.fcmToken : '-';
                    dispatch({
                        type: GET_USER_INFO,
                        data: userInfo,
                    })
                }
            })

        }
        catch (error) {
            dispatch({
                type: USERS_ERROR,
                data: error.message,
            });
        }
    }
}

export function getUserProfile(id: string): AppThunk {
    return async dispatch => {
        if (Firebase === null) {
            dispatch({
                type: USERS_ERROR,
                data: "Server error"
            });
            return;
        }
        try {
            const userProfile = {};
            const docRef = FirebaseDB.collection('usersCitizenProfile').doc(id);
            await docRef.onSnapshot(snap => {
                const data = snap.data() as UserCitizenProfile;
                if (data) {
                    userProfile['Name'] = data.citizenName;
                    userProfile['Description'] = data.citizenBio;
                    userProfile['Avatar'] = data.citizenAvatarUrl;
                    userProfile['Cover'] = data.citizenCoverUrl;
                }

            });
            const docRef2 = FirebaseDB.collection('users').doc(id);
            await docRef2.onSnapshot(snap => {
                const data = snap.data() as User;
                if (data) {
                    userProfile['Joined'] = data.journeyJoinedCount;
                    userProfile['Pledged'] = data.adventurePledgedCount;
                    userProfile['Bookmarked'] = data.experienceBookmarkedCount;
                    userProfile['Followings'] = data.followingCount;
                }
                dispatch({
                    type: GET_USER_PROFILE,
                    data: userProfile,
                })
            })
        }
        catch (error) {
            dispatch({
                type: USERS_ERROR,
                data: error.message,
            });
        }
    }
}

export function getJourneys(id: string): AppThunk {
    return async dispatch => {
        if (Firebase === null) {
            dispatch({
                type: USERS_ERROR,
                data: "Server error"
            });
            return;
        }
        try {
            let userJourneys: Array<Journey> = [];
            const ids = [];
            const docRef = await FirebaseDB.collection('users').doc(id).collection('joined').where('isHero', '==', false);
            const activityRef = await FirebaseDB.collection(FirestoreKey.Activities);
            docRef.onSnapshot(async snap => {
                snap.forEach(doc => {
                    ids.push(doc.id);
                })
                const length = snap.docs.length ? snap.docs.length : dispatch({ type: GET_JOURNEYS, data: [], });
                snap.docChanges().map(async change => {
                    if (change.type === "added") {
                        const index = userJourneys.findIndex(e => {
                            return e.sessionId === change.doc.id;
                        })
                        if (index === -1) {
                            ids.push(change.doc.id);
                            const sessionId = change.doc.id;
                            let activityId;
                            const journey: Journey = { sessionId: '', starts: '', ends: '', session: 0, joinOn: "", journeyName: "" };
                            const sessionRef = await FirebaseDB.collection(FirestoreKey.ActivitySessions).doc(sessionId);
                            sessionRef.get().then(snap => {
                                const data = snap.data() as ActivitySession;
                                journey.sessionId = sessionId;
                                if (data) {
                                    journey.starts = data.startDateTime ? moment(data.startDateTime).format(dateStringFormat.dateTime) : '-';
                                    journey.ends = data.startDateTime ? moment(data.endDateTime).format(dateStringFormat.dateTime) : '-';
                                    journey.session = data.index;
                                    activityId = data.activityId;
                                    activityRef.doc(activityId).onSnapshot(
                                        snap => {
                                            const data = snap.data() as Activity;
                                            journey.journeyName = data.title;
                                            if (length === userJourneys.length) {
                                                dispatch({
                                                    type: GET_JOURNEYS,
                                                    data: userJourneys,
                                                })
                                            }
                                        }
                                    );
                                }
                            });
                            const participantRef = await sessionRef.collection('participants').doc(id);
                            participantRef.get().then(doc => {
                                const data = doc.data() as SessionParticipant;
                                if (data) {
                                    journey['joinOn'] = (data.joinedDateTime) ? moment(data.joinedDateTime).format(dateStringFormat.date) : '-';
                                }
                                userJourneys.push(journey);
                            });
                        }
                    }
                    if (change.type === "modified") {
                        console.log(change.type)
                        const index = userJourneys.findIndex(e => {
                            return e.sessionId === change.doc.id;
                        })
                        if (index !== -1) {
                            const sessionId = ids[index];
                            let activityId;
                            const sessionRef = await FirebaseDB.collection(FirestoreKey.ActivitySessions).doc(sessionId);
                            sessionRef.onSnapshot(async snap => {
                                const data = await snap.data() as ActivitySession;
                                userJourneys[index].sessionId = sessionId;
                                userJourneys[index].starts = data.startDateTime ? moment(data.startDateTime).format(dateStringFormat.dateTime) : '-';
                                userJourneys[index].ends = data.startDateTime ? moment(data.endDateTime).format(dateStringFormat.dateTime) : '-';
                                userJourneys[index].session = data.index;
                                activityId = data.activityId;
                                activityRef.doc(activityId).onSnapshot(
                                    snap => {
                                        const data = snap.data() as Activity;
                                        userJourneys[index].journeyName = data.title;
                                        if (length === userJourneys.length) {
                                            dispatch({
                                                type: GET_JOURNEYS,
                                                data: userJourneys,
                                            })
                                        }
                                    }
                                );
                            });
                            const participantRef = await sessionRef.collection('participants').doc(id);
                            participantRef.onSnapshot(doc => {
                                const data = doc.data() as SessionParticipant;
                                userJourneys[index].joinOn = (data.joinedDateTime) ? moment(data.joinedDateTime).format(dateStringFormat.date) : '-';

                            });
                        }
                    }
                    if (change.type === "removed") {
                        console.log(change.type)
                        const newJourneys = userJourneys.filter(journey => {
                            return journey.sessionId !== change.doc.id;
                        })
                        userJourneys = [...newJourneys];
                        if (length === userJourneys.length) {
                            dispatch({
                                type: GET_JOURNEYS,
                                data: userJourneys,
                            })
                        }
                    }
                })
            })
        }
        catch (error) {
            dispatch({
                type: USERS_ERROR,
                data: error.message,
            });
        }
    }
}

export function getExperienceHistories(id: string): AppThunk {
    return async dispatch => {
        if (Firebase === null) {
            dispatch({
                type: USERS_ERROR,
                data: "Server error"
            });
            return;
        }
        try {
            const userExpHistories: Array<ExperienceHistory> = [];
            const docRef = await FirebaseDB.collection(FirestoreKey.Payments).where('userId', '==', id).where('type', '==', 1);
            docRef.onSnapshot(async snap => {
                const length = snap.docs.length ? snap.docs.length : dispatch({ type: GET_EXPERIENCE_HISTORIES, data: [], });
                snap.docChanges().map(async change => {
                    const paymentData = change.doc.data() as JoinFeePayment;
                    if (change.type === "added" || change.type === "modified") {
                        const index = userExpHistories.findIndex(e => {
                            return e.experienceId === paymentData.activityId
                        })
                        if (index === -1) {
                            const expRef = FirebaseDB.collection(FirestoreKey.Activities).doc(paymentData.activityId)
                            const expSnapshot = await expRef.get();
                            const expData = expSnapshot.data() as Activity;
                            const expHistory: ExperienceHistory = {
                                experienceId: paymentData.activityId,
                                experienceName: expData.title,
                                packageName: "N/A",
                                packageType: "N/A",
                                sessions: [],
                                bundleName: "N/A",
                                enrolledTime: paymentData.paymentDateTime ? moment(paymentData.paymentDateTime).format(dateStringFormat.dateTime) : '-',
                            };
                            if (paymentData.packageId) {
                                const packageSnapshot = await expRef.collection("packages").doc(paymentData.packageId).get();
                                const packageData = packageSnapshot.data()
                                expHistory.packageName = packageData.name ? packageData.name : "N/A";
                                expHistory.packageType = packageData.type !== undefined ? packageData.type === 0 ? "Any" : "Specific" : "N/A";
                            }
                            if (paymentData.bundleId) {
                                const bundleSnapshot = await expRef.collection("bundles").doc(paymentData.bundleId).get();
                                const bundleData = bundleSnapshot.data()
                                expHistory.bundleName = bundleData.title ? bundleData.title : "N/A";
                            }
                            if (paymentData.sessionIds) {
                                const sessionQueryPromises = [];
                                for (let i = 0; i < paymentData.sessionIds.length; i++) {
                                    const sessionQuery = FirebaseDB.collection(FirestoreKey.ActivitySessions).doc(paymentData.sessionIds[i]).get();
                                    sessionQueryPromises.push(sessionQuery)
                                }
                                const sessionSnapshot = await Promise.all(sessionQueryPromises);
                                for (const sessionSnap of sessionSnapshot) {
                                    const sessionData = sessionSnap.data() as ActivitySession;
                                    expHistory.sessions.push(sessionData);
                                }
                            }
                            userExpHistories.push(expHistory);
                            if (length === userExpHistories.length) {
                                userExpHistories.sort((a, b) => {
                                    return (moment(a.enrolledTime) < moment(b.enrolledTime) ? 1 : moment(a.enrolledTime) === moment(b.enrolledTime) ? 0 : -1);
                                });
                                dispatch({
                                    type: GET_EXPERIENCE_HISTORIES,
                                    data: userExpHistories,
                                });
                            }
                        }
                    }
                    if (change.type === "removed") {
                        const index = userExpHistories.findIndex(e => {
                            return e.experienceId === paymentData.activityId
                        })
                        if (index !== -1) {
                            userExpHistories.splice(index, 1); userExpHistories.splice(index, 1);
                        }
                        if (length === userExpHistories.length) {
                            userExpHistories.sort((a, b) => {
                                return (moment(a.enrolledTime) < moment(b.enrolledTime) ? 1 : moment(a.enrolledTime) === moment(b.enrolledTime) ? 0 : -1);
                            });
                            dispatch({
                                type: GET_EXPERIENCE_HISTORIES,
                                data: userExpHistories,
                            });
                        }
                    }
                })

            })
        }
        catch (error) {
            dispatch({
                type: USERS_ERROR,
                data: error.message,
            });
        }
    }
}

export function getPaymentRecord(id: string): AppThunk {
    return async dispatch => {
        if (Firebase === null) {
            dispatch({
                type: USERS_ERROR,
                data: "Server error"
            });
            return;
        }
        try {
            let payments: Array<PaymentRecord> = [];
            const paymentRef = await FirebaseDB.collection(FirestoreKey.Payments).orderBy('paymentDateTime', "desc");
            paymentRef.where('userId', '==', id).onSnapshot(snap => {
                let length = 0;
                if (snap.docs.length) {
                    length = snap.docs.length
                }
                else {
                    dispatch({ type: GET_PAYMENT_RECORD, data: [], });
                }
                snap.docChanges().map(async change => {
                    if (change.type === "added" || change.type === "modified") {
                        const index = payments.findIndex(e => {
                            return e.id === change.doc.id;
                        })
                        if (index === -1) {
                            const data = change.doc.data();
                            const payment: PaymentRecord = {
                                id: change.doc.id,
                                stripeId: data.stripeChargeId,
                                experience: 'Not applicable',
                                type: '',
                                paymentDate: (data.paymentDateTime) ? moment(data.paymentDateTime).format(dateStringFormat.dateTime) : '-',
                                amount: {
                                    "amount": data.amount,
                                    "netAmount": data.netAmount,
                                    "platformFee": data.platformFee,
                                    "transactionFee": data.transactionFee,
                                    "stripeFee": data.stripeFee,
                                    "paymeFee": data.paymeFee,
                                    "packagePrice": data.packagePrice,
                                    "bundlePrice": data.bundlePrice,
                                    "venueEventPrice": data.venueEventPrice,
                                    "donation": data.donation,
                                },
                                status: data.isRefunded ? 'Refunded' : 'Success',
                            };
                            switch (data.type) {
                                case 0:
                                    payment.type = "Experience Pledge";
                                    break;
                                case 1:
                                    payment.type = "Experience Join Fee";
                                    break;
                                case 2:
                                    payment.type = "Venue Event Fee";
                                    break;
                                case 3:
                                    payment.type = "Venue Event Pledge";
                                    break;
                                case 4:
                                    payment.type = "Hero Pledge";
                                    break;
                            }
                            // Activity
                            const activityId = data.activityId;
                            if (activityId) {
                                const experienceRef = await FirebaseDB.collection('activities').doc(activityId).get();
                                const experienceData = experienceRef.data();
                                payment.experience = experienceData.title;
                            }
                            // Venue Event
                            const venueEventId = data.venueEventId;
                            if (venueEventId) {
                                const venueEventRef = await FirebaseDB.collection('venueEvents').doc(venueEventId).get();
                                const venueEventData = venueEventRef.data();
                                payment.experience = venueEventData.title;
                            }
                            payments.push(payment);
                            if (length === payments.length) {
                                payments.sort((a, b) => {
                                    return (moment(a.paymentDate) < moment(b.paymentDate) ? 1 : moment(a.paymentDate) === moment(b.paymentDate) ? 0 : -1);
                                });
                                dispatch({
                                    type: GET_PAYMENT_RECORD,
                                    data: payments
                                })
                            }
                        }
                    }

                    if (change.type === "removed") {
                        const newPayments = payments.filter(payment => {
                            return payment.id !== change.doc.id;
                        })
                        payments = [...newPayments];
                        length -= 1;
                        if (length === payments.length) {
                            payments.sort((a, b) => {
                                return (moment(a.paymentDate) < moment(b.paymentDate) ? 1 : moment(a.paymentDate) === moment(b.paymentDate) ? 0 : -1);
                            });
                            dispatch({
                                type: GET_PAYMENT_RECORD,
                                data: payments
                            })
                        }
                    }

                })
            });

        }
        catch (error) {
            dispatch({
                type: USERS_ERROR,
                data: error.message,
            });
        }
    }

}

function getActivityStage(activityStage: ActivityStage, launchTime: number) {
    if (activityStage.isAllSessionSealed)
        return SEALED
    if (activityStage.isAllSessionEnded)
        return COMPLETED
    if (activityStage.isAnySessionStarted)
        return ON_GOING
    if (launchTime !== null && launchTime !== 0)
        return LAUNCHED
    return PRE
}

export function getExperienceRecord(id: string): AppThunk {
    return async dispatch => {
        if (Firebase === null) {
            dispatch({
                type: USERS_ERROR,
                data: "Server error"
            });
            return;
        }
        try {

            let experiences: Array<ExperienceRecord> = [];
            const experienceRef = await FirebaseDB.collection('activities');
            const queryRef = await experienceRef.where('userId', '==', id).orderBy('createdDateTime', 'desc');
            queryRef.onSnapshot(snap => {
                let length = 0;
                if (snap.docs.length) {
                    length = snap.docs.length
                }
                else {
                    dispatch({ type: GET_EXPERIENCE_RECORD, data: [], });
                }
                snap.docChanges().forEach(async change => {
                    if (change.type === 'added' || change.type === 'modified') {
                        const index = experiences.findIndex(e => {
                            return e.id === change.doc.id;
                        })
                        if (index === -1) {
                            const data = change.doc.data() as Activity;
                            const activityStage: ActivityStage = {
                                isAllSessionSealed: data.isAllSessionSealed,
                                isAllSessionEnded: data.isAllSessionEnded,
                                isAnySessionStarted: data.isAnySessionStarted
                            }
                            const experience: ExperienceRecord = {
                                id: change.doc.id,
                                name: data.title,
                                createDate: (data.createdDateTime) ? moment(data.createdDateTime).format(dateStringFormat.dateTime) : '-',
                                lastUpdateDate: (data.lastModifiedDateTime) ? moment(data.lastModifiedDateTime).format(dateStringFormat.dateTime) : '-',
                                numberOfPackages: data.packagePrices ? data.packagePrices.length : 0,
                                status: getActivityStage(activityStage, data.launchedDateTime),
                                purchasedUsers: [],
                                sessions: []
                            };

                            const sessionSnapshot = await FirebaseDB.collection(FirestoreKey.ActivitySessions).where('activityId', '==', change.doc.id).get()
                            for (const sessionSnap of sessionSnapshot.docs) {
                                const sessionData = sessionSnap.data() as ActivitySession;
                                experience.sessions.push(sessionData);
                            }

                            const paymentSnapshot = await FirebaseDB.collection(FirestoreKey.Payments).where('type', '==', 1).where('activityId', '==', change.doc.id).get()
                            const userQueryPromises = [];
                            for (const paymentSnap of paymentSnapshot.docs) {
                                const paymentData = paymentSnap.data();
                                const userQuery = FirebaseDB.collection(FirestoreKey.UsersCitizenProfile).doc(paymentData.userId).get();
                                userQueryPromises.push(userQuery)
                            }

                            const userSnapshot = await Promise.all(userQueryPromises);
                            for (const userSnap of userSnapshot) {
                                const userData = userSnap.data();
                                experience.purchasedUsers.push(userData.citizenName);
                            }

                            experiences.push(experience)
                            if (experiences.length === length) {
                                experiences.sort((a, b) => {
                                    return (moment(a.createDate) < moment(b.createDate) ? 1 : moment(a.createDate) === moment(b.createDate) ? 0 : -1);
                                });
                                dispatch({
                                    type: GET_EXPERIENCE_RECORD,
                                    data: experiences,
                                })
                            }
                        }
                    }

                    if (change.type === 'removed') {
                        const newExp = experiences.filter(experience => {
                            return experience.id !== change.doc.id;
                        })
                        experiences = [...newExp];
                        length -= 1;
                        if (experiences.length === length) {
                            experiences.sort((a, b) => {
                                return (moment(a.createDate) < moment(b.createDate) ? 1 : moment(a.createDate) === moment(b.createDate) ? 0 : -1);
                            });
                            dispatch({
                                type: GET_EXPERIENCE_RECORD,
                                data: experiences,
                            })
                        }
                    }
                })
            });

        }
        catch (error) {
            dispatch({
                type: USERS_ERROR,
                data: error.message,
            });
        }
    }

}

export function getCashOutMethod(id: string): AppThunk {
    return async dispatch => {
        if (Firebase === null) {
            dispatch({
                type: USERS_ERROR,
                data: "Server error"
            })
        }
        try {
            let cashOutMethods: CashOutMethod[] = [];
            const cashRef = await FirebaseDB.collection(FirestoreKey.UsersVariable);
            await cashRef.doc(id).collection(FirestoreKey.UserVariablePayoutMethods).onSnapshot(
                snap => {
                    snap.docChanges().forEach(change => {
                        if (change.type === "added") {
                            const index = cashOutMethods.findIndex(e => {
                                return e.id === change.doc.id;
                            })

                            if (index === -1) {
                                const data = change.doc.data() as PayoutMethod;
                                const cashOutMethod: CashOutMethod = {
                                    id: change.doc.id,
                                    type: PayoutMethodType[data.type],
                                    account: '',
                                    status: data.isActive ? 'Active' : 'Invalid',
                                }
                                switch (cashOutMethod.type) {
                                    case 'Paypal': {
                                        const dataPaypal = change.doc.data() as Paypal;
                                        cashOutMethod.account = dataPaypal.email;
                                        console.log(dataPaypal);
                                        console.log(dataPaypal.email)
                                        break;
                                    }
                                    case 'FPS': {
                                        const dataFPS = change.doc.data() as FPS;
                                        cashOutMethod.account = dataFPS.phoneNumber;
                                        break;
                                    }
                                    case 'HSBC': {
                                        const dataHSBC = change.doc.data() as HSBC;
                                        cashOutMethod.account = dataHSBC.bankAccountNo;
                                        break;
                                    }
                                }
                                cashOutMethods.push(cashOutMethod);
                            }
                        }
                        if (change.type === "modified") {
                            const index = cashOutMethods.findIndex(e => {
                                return e.id === change.doc.id;
                            })

                            if (index !== -1) {
                                const data = change.doc.data() as PayoutMethod;
                                const cashOutMethod: CashOutMethod = {
                                    id: change.doc.id,
                                    type: PayoutMethodType[data.type],
                                    account: '',
                                    status: data.isActive ? 'Active' : 'Invalid',
                                }
                                switch (cashOutMethod.type) {
                                    case 'Paypal': {
                                        const dataPaypal = change.doc.data() as Paypal;
                                        cashOutMethod.account = dataPaypal.email;
                                        console.log(dataPaypal);
                                        console.log(dataPaypal.email)
                                        break;
                                    }
                                    case 'FPS': {
                                        const dataFPS = change.doc.data() as FPS;
                                        cashOutMethod.account = dataFPS.phoneNumber;
                                        break;
                                    }
                                    case 'HSBC': {
                                        const dataHSBC = change.doc.data() as HSBC;
                                        cashOutMethod.account = dataHSBC.bankAccountNo;
                                        break;
                                    }
                                }
                                cashOutMethods[index] = cashOutMethod;
                            }
                        }

                        if (change.type === 'removed') {
                            const newMethods = cashOutMethods.filter(method => {
                                return method.id !== change.doc.id;
                            })
                            cashOutMethods = [...newMethods];
                        }
                    }
                    )
                    dispatch({
                        type: GET_CASHOUT_METHODS,
                        data: cashOutMethods,
                    })
                }
            )

        }
        catch (error) {
            dispatch({
                type: USERS_ERROR,
                data: error.message
            })
        }
    }

}

export function getCashoutRecord(id: string): AppThunk {
    return async dispatch => {
        if (Firebase === null) {
            dispatch({
                type: USERS_ERROR,
                data: "Server error"
            });
            return;
        }
        try {
            let cashOuts: Array<CashOutRecord> = [];
            const cashRef = await FirebaseDB.collection('payouts').orderBy('createdDateTime', 'desc');

            await cashRef.where('userId', '==', id).onSnapshot(snap => {
                snap.docChanges().forEach(change => {
                    if (change.type === "added") {
                        const index = cashOuts.findIndex(e => {
                            return e.id === change.doc.id;
                        })
                        if (index === -1) {
                            const data = change.doc.data() as Payout;

                            const cashOut: CashOutRecord = {
                                id: change.doc.id,
                                type: PayoutMethodType[data.payoutMethodType],
                                account: data.payoutMethodIdentifier,
                                cashOutDate: (data.createdDateTime) ? moment(data.createdDateTime).format(dateStringFormat.date) : '-',
                                amount: '$' + data.amount,
                                statusNo: data.status,
                                status: PayoutStatus[data.status],
                            };

                            cashOuts.push(cashOut);
                        }
                    }
                    if (change.type === "modified") {
                        const index = cashOuts.findIndex(e => {
                            return e.id === change.doc.id;
                        })
                        if (index !== -1) {
                            const data = change.doc.data() as Payout;

                            const cashOut: CashOutRecord = {
                                id: change.doc.id,
                                type: PayoutMethodType[data.payoutMethodType],
                                account: data.payoutMethodIdentifier,
                                cashOutDate: (data.createdDateTime) ? moment(data.createdDateTime).format(dateStringFormat.date) : '-',
                                amount: '$' + data.amount,
                                statusNo: data.status,
                                status: PayoutStatus[data.status],
                            };

                            cashOuts[index] = cashOut;
                        }
                    }
                    if (change.type === "removed") {
                        const newCashouts = cashOuts.filter(cashout => {
                            return cashout.id !== change.doc.id;
                        })
                        cashOuts = [...newCashouts];
                    }
                })
                dispatch({
                    type: GET_CASHOUT_RECORD,
                    data: cashOuts,
                })
            })

        }
        catch (error) {
            dispatch({
                type: USERS_ERROR,
                data: error.message,
            });
        }
    }

}

export function getPaymentMethod(id: string): AppThunk {
    return async dispatch => {
        if (Firebase === null) {
            dispatch({
                type: USERS_ERROR,
                data: "Server error"
            });
            return;
        }
        try {
            const getMethods = await FirebaseFunctions.httpsCallable('getPaymentMethods');
            getMethods({ userId: id }).then(function (result) {
                const paymentMethods: IPaymentMethod[] = [];
                if (result.data !== null || result.data.length !== 0) {
                    result.data.forEach(record => {
                        const paymentMethod: IPaymentMethod = {
                            id: record.id,
                            last4: record.last4,
                            brand: record.brand,
                        }
                        paymentMethods.push(paymentMethod);
                    })
                    dispatch({
                        type: GET_PAYMENT_METHOD,
                        data: paymentMethods,
                    })
                }

            })
        }
        catch (error) {
            dispatch({
                type: USERS_ERROR,
                data: error.message,
            });
        }
    }
}

export function getHeroStatus(id: string): AppThunk {
    return async dispatch => {
        if (Firebase === null) {
            dispatch({
                type: GET_HERO_STATUS,
                data: false,
            })
        }
        try {
            const userRef = FirebaseDB.collection('usersVariable').doc(id);
            await userRef.onSnapshot(snap => {
                if (snap) {
                    const data = snap.data();
                    if (data) {
                        if (data.isHero) {
                            dispatch({
                                type: GET_HERO_STATUS,
                                data: true,
                            })
                        }
                        else {
                            dispatch({
                                type: GET_HERO_STATUS,
                                data: false,
                            })
                        }
                    }

                }
            })

        }
        catch (error) {
            dispatch({
                type: GET_HERO_STATUS,
                data: false,
            })
        }
    }
}

export function getHeroProfile(id: string): AppThunk {
    return async dispatch => {
        if (Firebase === null) {
            dispatch({
                type: USERS_ERROR,
                data: null,
            });
        }
        try {
            const heroProfile = {};
            const docRef = FirebaseDB.collection(FirestoreKey.Users).doc(id);
            const profileRef = FirebaseDB.collection(FirestoreKey.UsersHeroProfile).doc(id);
            const customRef = FirebaseDB.collection('categories');
            await profileRef.onSnapshot(async snap => {
                const data = snap.data() as UserHeroProfile;
                if (data) {
                    heroProfile['Name'] = data.heroName;
                    heroProfile['Description'] = data.heroBio;
                    heroProfile['Avatar'] = data.heroAvatarUrl;
                    heroProfile['Cover'] = data.heroCoverUrl;
                }
            })
            await docRef.onSnapshot(async snap => {
                const data = snap.data() as User;
                if (data) {
                    heroProfile['Adventures'] = data.adventureCount;
                    heroProfile['Journeys'] = data.journeyCount;
                    heroProfile['Concepts'] = data.conceptCount;
                    heroProfile['Experiences'] = data.experienceCount;
                    heroProfile['Followers'] = data.followerCount;
                    heroProfile['categoryId'] = data.categoryId;
                    heroProfile['level'] = data.level;
                    console.log(data.categoryId)
                    await customRef.doc(data.categoryId).onSnapshot(snap => {
                        const data = snap.data();
                        if (data) {
                            heroProfile['categoryName'] = data.name;
                            heroProfile['color'] = data.color;
                        }
                        dispatch({
                            type: GET_HERO_PROFILE,
                            data: heroProfile,
                        })
                    })
                }

            })
        }
        catch (error) {
            dispatch({
                type: USERS_ERROR,
                data: error.message,
            });
        }
    }
}

export function disableUser(userId: string): AppThunk {
    return async dispatch => {
        if (Firebase === null) {
            dispatch({
                type: USERS_ERROR,
                data: null,
            });
        }

        try {
            const disableProm = await FirebaseFunctions.httpsCallable('disableUser');
            disableProm({ userId: userId }).then(
                () => {
                    getUserInfo(userId);
                    dispatch({
                        type: ENABLE_USER,
                        data: false,
                    })
                }
            )
        }
        catch (error) {
            console.log(error);
        }
    }


}

export function enableUser(id: string): AppThunk {
    return async dispatch => {
        if (Firebase === null) {
            dispatch({
                type: USERS_ERROR,
                data: null,
            });
        }

        try {
            const enableProm = await FirebaseFunctions.httpsCallable('enableUser');
            enableProm({ userId: id }).then(
                () => {
                    getUserInfo(id);
                    dispatch({
                        type: ENABLE_USER,
                        data: true,
                    })
                }
            )
        }
        catch (error) {
            console.log(error);
        }
    }
}

export function getUserActivation(id: string): AppThunk {
    return async dispatch => {
        if (Firebase === null) {
            dispatch({
                type: USERS_ERROR,
                data: null,
            });
        }

        try {
            const userRef = await FirebaseDB.collection(FirestoreKey.UsersVariable).doc(id);
            let activation: boolean;
            await userRef.onSnapshot(snap => {
                const data = snap.data() as UserVariable;
                if (data) {
                    activation = data.isActivated;
                }
                dispatch({
                    type: GET_USER_ACTIVATION,
                    data: activation,
                })
            })

        }
        catch (error) {
            console.log(error);
        }
    }

}
