import { RewardRecordUserRole } from "./rewardRecord";

export enum RewardType {
    Venue,
    Code,
    CodeList,
    QrCode,
    QrCodeList,
    JourneyDiscount,
}
export interface Reward {
    id?: string;
    type: RewardType;
    userRole: RewardRecordUserRole;
    isActive: boolean;
    isIssueOnReg: boolean;
    title: string;
    description: string;
    createdDateTime: number;
    expireDateTime?: number;
}
export interface VenueReward extends Reward {
    discount: number;
    recommendation: string;
    capacity: string;
    website: string;
    originalPrice: number;
    location: string;
    tnc: string;
}
export interface JourneyDiscountReward extends Reward {
    discount: number;
}



