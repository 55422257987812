import React from 'react'
import { Button, Modal } from "antd";
import { ITransactionState } from "../../store/reducers/transactions";
import { hideSettlePayout, updateCashOutStatus } from "../../store/actions/transactions";
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from "react-router";
import { ICashOut } from "./CashOutTable";
import { IStoreState } from '../../store/IStoreState';

interface IProps extends RouteComponentProps{
    hideSettlePayout : () => void;
    updateCashOutStatus : (id:string, no:number, reason:string) => void;
    visible: boolean,
    transactions : ITransactionState,
    record:ICashOut,
}

class SettlePayout extends React.Component<IProps> {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
        }
    }

    componentDidMount(): void {
        this.setState({ visible: this.props.visible })
    }

    handleCancel = e => {
        e.preventDefault();
        this.props.hideSettlePayout();
    };

    handleSubmit = e => {
        e.preventDefault();
        this.props.updateCashOutStatus(this.props.record.id, 2, "");
        this.props.hideSettlePayout();
    }

    render() {
        return (
            <Modal visible={this.props.visible}
                footer={[
                    <Button key="back" onClick={this.handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={this.handleSubmit}>
                        Confirm
                    </Button>,
                ]}
                onCancel={this.handleCancel} onOk={this.handleSubmit} >
                Confirm to settle this cash out?
            </Modal>
        )
    }
}

const mapStateToProps = (state: IStoreState) => {
    return {
        ...state
    }

}

const mapDispatchToProps = dispatch => {
    return ({
        hideSettlePayout: () => dispatch(hideSettlePayout()),
        updateCashOutStatus: (id:string, no:number, reason:string) => dispatch(updateCashOutStatus(id, no, reason)),
    })
}
/* eslint-disable  @typescript-eslint/no-explicit-any */
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SettlePayout));