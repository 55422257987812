import { FirebaseDB } from "../lib/firebase";
import { FirestoreKey } from "../constants/setting";

export function detachUserInfo(): void {
    const unsub = FirebaseDB.collection(FirestoreKey.UsersInfo).onSnapshot(() => {return});
    unsub();
}

export function detachUserVariable(): void {
    const unsub = FirebaseDB.collection(FirestoreKey.UsersVariable).onSnapshot(() => {return})
    unsub();
}

export function detachUsers(): void {
    const unsub = FirebaseDB.collection(FirestoreKey.Users).onSnapshot(() => {return})
    unsub();
}

export function detachActivities(): void {
    const unsub = FirebaseDB.collection(FirestoreKey.Activities).onSnapshot(() => {return})
    unsub();
}

export function detachPayouts(): void {
    const unsub = FirebaseDB.collection(FirestoreKey.Payouts).onSnapshot(() => {return})
    unsub();
}