import { Firebase, FirebaseDB, FirebaseFunctions } from "../../lib/firebase";
import { FirestoreKey, dateStringFormat } from "../../constants/setting";
import moment from 'moment'
import { UserReport } from "../../components/reports/ReportTable";
import { PayoutStatus } from "../../models/payout";
import { getUser } from "./experience";
import { AppThunk } from "../IStoreState";

export const GET_COMPLETED_REPORTS = 'GET_COMPLETED_REPORTS';
export const GET_PENDING_REPORTS = 'GET_PENDING_REPORTS';
export const REPORT_ERROR = 'REPORT_ERROR';
export const SHOW_USER_REPORT = 'SHOW_USER_REPORT';

async function userReport (id:string, data:firebase.firestore.DocumentData): Promise <UserReport> {
    const user = await getUser(data.userId);
    const report : UserReport = {
        id: id,
        userId: data.userId,
        user: user,
        title: data.title,
        location: data.location,
        date: moment(data.createdDateTime).format(dateStringFormat.date),
        status: data.status,
        action: PayoutStatus[data.status],
        description: data.description,
    }
    return report;
}

export function getAllReports(): AppThunk {
    return async dispatch => {
        if (Firebase === null) {
            dispatch({
                type: REPORT_ERROR,
                data: null,
            })
        }
        try {
            let reports = [] as UserReport[];
            const reportRef = await FirebaseDB.collection(FirestoreKey.UserReports).where('status', '<=', 3)
                .where('status', '>=', 2);
            reportRef.onSnapshot(async snap => {
                await Promise.all(snap.docChanges().map(async change => {
                    const data = change.doc.data();
                    if (change.type === 'added') {
                        const index = reports.findIndex(report => {
                            return report.id === change.doc.id;
                        }
                        )
                        if (index === -1) {
                            if (data) {
                                const report = await userReport(change.doc.id, data);
                                reports.push(report);
                            }
                        }
                    }
                    else if (change.type === 'modified') {
                        const index = reports.findIndex(report => {
                            return report.id === change.doc.id;
                        })
                        if (index !== -1)
                        {
                            if (data) {
                                const report = await userReport(change.doc.id, data);
                                reports[index] = report;
                            }
                        }
                    }
                    else if (change.type === 'removed') {
                        const newReports = reports.filter(report => {
                            return report.id !== change.doc.id;
                        })
                        reports = [...newReports];
                    }

                }));
                dispatch({
                    type: GET_COMPLETED_REPORTS,
                    data: reports
                })
            })
        } catch (e) {
            console.log(e.message);
            dispatch({
                type: REPORT_ERROR,
                data: e.message
            })
        }

    }
}

export function getPendingReports(): AppThunk {
    return async dispatch => {
        if (Firebase === null) {
            dispatch({
                type: REPORT_ERROR,
                data: null,
            })
        }
        try {
            let reports = [] as UserReport[];
            const reportRef = await FirebaseDB.collection(FirestoreKey.UserReports)
                .where('status', '<=', 1 );
            reportRef.onSnapshot(async snap => {
                await Promise.all(snap.docChanges().map(async change => {
                    const data = change.doc.data();
                    if (change.type === 'added') {
                        const index = reports.findIndex(report => {
                            return report.id === change.doc.id;
                        }
                        )
                        if (index === -1) {
                            if (data) {
                                const report = await userReport(change.doc.id, data);
                                reports.push(report);
                            }
                        }
                    }
                    else if (change.type === 'modified') {
                        const index = reports.findIndex(report => {
                            return report.id === change.doc.id;
                        })
                        if (index !== -1)
                        {
                            if (data) {
                                const report = await userReport(change.doc.id, data);
                                reports[index] = report;
                            }
                        }
                    }
                    else if (change.type === 'removed') {
                        const newReports = reports.filter(report => {
                            return report.id !== change.doc.id;
                        })
                        reports = [...newReports];
                    }

                }));
                dispatch({
                    type: GET_PENDING_REPORTS,
                    data: reports
                })
            })
        } catch (e) {
            dispatch({
                type: REPORT_ERROR,
                data: e.message
            })
        }

    }
}

export function updateUserReportStatus(id:string, status:number): AppThunk {
    return async dispatch => {
        if (Firebase === null) {
            dispatch({
                type: REPORT_ERROR,
                data: null,
            })
        }
        try {
            const updateStatus = await FirebaseFunctions.httpsCallable('updateUserReportStatus');
            updateStatus({ reportId: id, reportStatus: status }).then(
                (result) => {
                    console.log(result);
                }
            )
        } catch (e) {
            dispatch({
                type: REPORT_ERROR,
                data: e.message,
            })
        }
    }
}

export function showUserReport (record:UserReport): AppThunk {
    return async dispatch => {
        dispatch({
            type: SHOW_USER_REPORT,
            data: record,
        })
    }
}

export function  hideUserReport(): AppThunk {
    return async dispatch => {
        dispatch({
            type: SHOW_USER_REPORT,
            data: null,
        })
    }

}