import {
    ENABLE_USER,
    GET_CASHOUT_METHODS, GET_CASHOUT_RECORD,
    GET_EXPERIENCE_RECORD, GET_HERO_PROFILE,
    GET_HERO_STATUS,
    GET_JOURNEYS, GET_EXPERIENCE_HISTORIES, GET_PAYMENT_METHOD, GET_PAYMENT_RECORD, GET_USER_ACTIVATION,
    GET_USER_INFO, GET_USER_PROFILE,
    GET_USERS,
    USERS_ERROR,
} from "../actions/users";
import { PaymentRecord } from "../../components/users/traveller/PaymentRecord";
import { ExperienceRecord } from "../../components/users/hero/ExperienceRecord";
import { CashOutMethod } from "../../components/users/hero/CashOutMethod";
import { CashOutRecord } from "../../components/users/hero/CashOutRecord";
import { IPaymentMethod } from "../../components/users/traveller/PaymentMethod";
import { Journey } from "../../components/users/traveller/Jouneys";
import { ExperienceHistory } from "../../components/users/traveller/ExperienceHistories";
import { IUser } from "../../components/users/UsersList";
import { DisplayUser } from "../../components/users/traveller/UserInfo";

export interface IUsersState {
    error: string,
    users: Array<IUser>,
    user: DisplayUser,
    profile: {
        Name: string,
        Description: string,
        Avatar: string,
        Cover: string,
        Joined: number,
        Pledged: number,
        Bookmarked: number,
        Followings: number
    },
    heroProfile: {
        Name: string,
        Description: string,
        Avatar: string,
        Cover: string,
        Adventures: number,
        Journeys: number,
        Experiences: number,
        Concepts: number,
        Followers: number,
        categoryId: string,
        level: number,
        categoryName: string,
        color: string
    },
    journeys: Array<Journey>,
    experienceHistories: Array<ExperienceHistory>,
    payments: Array<PaymentRecord>,
    heroButton: boolean,
    experiences : Array<ExperienceRecord>,
    cashOut: Array<CashOutMethod>,
    cashOutRecord: Array<CashOutRecord>,
    paymentMethods: Array<IPaymentMethod>,
    userActivation: boolean,
    enableUser
}

export const initialState : IUsersState = {
    error: null,
    users: [],
    user: {
        Email: null,
        Activated: null,
        BetaUser: null,
        Ac: null,
        Balance: null,
        Credit: null,
        StripeID: null,
        RegistrationDate: null,
        LastPayoutDate: null,
        PhoneNumber: null,
        FirstName: null,
        LastName: null,
        Gender: null,
        DOB: null,
        Address: null,
        LastSignIn: null,
        Language: null,
        FCMToken: null
    },
    profile: {
        Name: null,
        Description: null,
        Avatar: null,
        Cover: null,
        Joined: null,
        Pledged: null,
        Bookmarked: null,
        Followings: null
    },
    heroProfile: {
        Name: null,
        Description: null,
        Avatar: null,
        Cover: null,
        Adventures: null,
        Journeys: null,
        Experiences: null,
        Concepts: null,
        Followers: null,
        categoryId: null,
        level: null,
        categoryName: null,
        color: null
    },
    journeys: [],
    experienceHistories: [],
    payments: [],
    heroButton: false,
    experiences: [],
    cashOut: [],
    cashOutRecord: [],
    paymentMethods: [],
    userActivation: null,
    enableUser: null,
};

export default (state = initialState, action: {type: string, data}): IUsersState => {
    switch (action.type) {
        case GET_USERS: {
            return {
                ...state,
                users: action.data,
            };
        }
        case GET_USER_INFO:
            return {
                ...state,
                user: action.data,
            }
        case GET_USER_PROFILE:
            return {
                ...state,
                profile: action.data,
            }
        case GET_JOURNEYS:
            return {
                ...state,
                journeys: action.data,
            }
        case GET_EXPERIENCE_HISTORIES:
            return {
                ...state,
                experienceHistories: action.data
            }
        case GET_PAYMENT_METHOD:
            return {
                ...state,
                paymentMethods: [...action.data],
            }
        case GET_PAYMENT_RECORD:
            return {
                ...state,
                payments: action.data
            }
        case GET_EXPERIENCE_RECORD:
            return {
                ...state,
                experiences: action.data
            }
        case GET_CASHOUT_METHODS:
            return {
                ...state,
                cashOut: action.data
            }
        case GET_CASHOUT_RECORD:
            return {
                ...state,
                cashOutRecord: action.data
            }
        case USERS_ERROR:
            return {
                ...state,
                error: action.data,
            }
        case GET_HERO_STATUS:
            return {
                ...state,
                heroButton: action.data
            }
        case GET_HERO_PROFILE:
            return {
                ...state,
                heroProfile: action.data
            }
        case GET_USER_ACTIVATION:
            return {
                ...state,
                userActivation: action.data
            }
        case ENABLE_USER:
            return {
                ...state,
                enableUser: action.data
            }
        default:
            return {
                ...state
            }
    }
}