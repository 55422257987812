import { Firebase, FirebaseDB, FirebaseFunctions } from "../../lib/firebase";
import moment from 'moment'
import { IReward } from "../../components/rewards/AllRewards";
import { FirestoreKey } from "../../constants/setting";
import { Reward, RewardType, VenueReward } from "../../models/reward";
import { IRedemption } from "../../components/rewards/Redemption";
import { RewardRedemptionCodeType } from "../../models/rewardRedemptionCode";
import { dateStringFormat } from "../../constants/setting";
import { AppThunk } from "../IStoreState";

export const GET_ALL_REWARDS = 'GET_ALL_REWARDS';
export const GET_REDEMPTIONS = 'GET_REDEMPTIONS';
export const REWARD_ERROR = 'REWARD_ERROR';
export const REDEMPTION_ERROR = 'REDEMPTION_ERROR';
export const REWARD_FORM = 'REWARD_FORM';
export const REDEMPTION_FORM = 'REDEMPTION_FORM';
export const SUBMIT_REWARD = 'SUBMIT_REWARD';
export const UPLOAD_ERROR = 'UPLOAD_ERROR';
export const REWARD_DETAILS = 'REWARD_DETAILS';
export const REDEMPTION_DETAILS = 'REDEMPTION_DETAILS';
export const SUBMIT_PROCESSING = 'SUBMIT_PROCESSING';

function getRewardStatus (isActive: boolean) {
    if (isActive) {
        return {
            title: 'Active',
            type: 'success'
        }
    }
    else return {
        title: 'Invalid',
        type: 'error'
    }
}
async function getRewardUsed (rewardId: string):Promise<string> {
    let remaining: number;
    let quantity: number;
    let used: string;
    const RewardMasterRef = FirebaseDB.collection(FirestoreKey.RewardMasters).doc(rewardId);
    try {
        const snap = await RewardMasterRef.get();
        const data = snap.data();
        remaining = data.remaining;
        quantity = data.quantity;
        if (!remaining || !quantity)
        {
            used = '-'
        }
        else {
            used = remaining + '/' + quantity;
        }
        return used
    } catch (e) {
        return null;
    }
}

export function getAllRewards(): AppThunk {
    return async dispatch => {
        if (Firebase === null) {
            dispatch({
                type: REWARD_ERROR,
                data: null,
            })
        }
        try {
            let rewards : IReward[] = [];
            let rewardIds = [];
            const RewardRef = await FirebaseDB.collection(FirestoreKey.Rewards).orderBy('createdDateTime', 'desc');
            RewardRef.onSnapshot(async snap => {
                await Promise.all(snap.docChanges().map(async change => {
                    if (change.type === 'added')
                    {
                        const index = rewards.findIndex(reward => {
                            return reward.id === change.doc.id;
                        })
                        if (index === -1)
                        {
                            const data = change.doc.data() as Reward;
                            const used = await getRewardUsed(change.doc.id);
                            rewardIds.push(change.doc.id);
                            const reward : IReward = {
                                id: change.doc.id,
                                type: RewardType[data.type],
                                title: data.title,
                                createDate: data.createdDateTime ? moment(data.createdDateTime).format(dateStringFormat.date) : '-',
                                expiryDate: data.expireDateTime ? moment(data.expireDateTime).format(dateStringFormat.date) : '-',
                                used: used,
                                status: getRewardStatus(data.isActive),
                            };
                            rewards.push(reward);
                        }
                    }
                    else if (change.type === 'modified')
                    {
                        const index = rewards.findIndex(reward => {
                            return reward.id === change.doc.id;
                        })
                        if (index !== -1)
                        {
                            const data = change.doc.data() as Reward;
                            const reward : IReward = {
                                id: change.doc.id,
                                type: RewardType[data.type],
                                title: data.title,
                                createDate: data.createdDateTime ? moment(data.createdDateTime).format(dateStringFormat.date) : '-',
                                expiryDate: data.expireDateTime ? moment(data.expireDateTime).format(dateStringFormat.date) : '-',
                                used: await getRewardUsed(change.doc.id),
                                status: getRewardStatus(data.isActive),
                            }
                            rewards[index] = reward;
                            rewardIds[index] = change.doc.id;
                        }
                    }
                    else {
                        const newRewards = rewards.filter(reward => {
                            return reward.id !== change.doc.id;
                        })
                        rewards = [...newRewards];
                        const newIds = rewardIds.filter(id => {
                            return id !== change.doc.id;
                        })
                        rewardIds = [...newIds];
                    }
                }))
                dispatch({
                    type: GET_ALL_REWARDS,
                    data: {
                        rewards: rewards,
                        rewardIds: rewardIds,
                    },
                })
            })

        } catch (e) {
            dispatch({
                type: REWARD_ERROR,
                data: null,
            })
        }

    }
}

export function getRedemptions(): AppThunk {
    return async dispatch => {
        if (Firebase === null) {
            dispatch({
                type: REWARD_ERROR,
                data: null
            })
        }
        try {
            let redemptions : IRedemption[] = [];
            const RedemptionRef = FirebaseDB.collection(FirestoreKey.RewardRedemptionCodes);
            RedemptionRef.onSnapshot(snap => {
                snap.docChanges().forEach(change => {
                    if (change.type === 'added')
                    {
                        const index = redemptions.findIndex(reward => {
                            return reward.code === change.doc.id;
                        })
                        if (index === -1)
                        {
                            const data = change.doc.data();
                            const redemption : IRedemption = {
                                code: change.doc.id,
                                type: RewardRedemptionCodeType[data.type],
                                amount: (data.type === 1) ? data.amount : '-',
                                usedCount: (data.type === 0) ? (data.isUsed ? 1 : 0) : (data.usedCount ? data.usedCount : '-'),
                                repeatable: data.isRepeatable ? 'Y' : ((data.type === 0) ? '-' : 'N'),
                                rewardId: data.rewardId,
                                expiryDate: data.expireDateTime ? moment(data.expireDateTime).format(dateStringFormat.dateTime) : '-',
                                active: data.isActive ? { type: 'success', title: 'Active' } : { type: 'error', title: 'Invalid' },
                            }
                            redemptions.push(redemption);
                            redemptions.sort((a, b) => (a.code > b.code) ? 1 : -1);
                        }
                    }
                    else if (change.type === "modified")
                    {
                        const index = redemptions.findIndex(reward => {
                            return reward.code === change.doc.id;
                        })
                        if (index !== -1)
                        {
                            const data = change.doc.data();
                            const redemption : IRedemption = {
                                code: change.doc.id,
                                type: RewardRedemptionCodeType[data.type],
                                amount: (data.type === 1) ? data.amount : '-',
                                usedCount: (data.type === 0) ? (data.isUsed ? 1 : 0) : (data.usedCount ? data.usedCount : '-'),
                                repeatable: data.isRepeatable ? 'Y' : 'N',
                                rewardId: data.rewardId,
                                expiryDate: data.expireDateTime ? moment(data.expireDateTime).format(dateStringFormat.dateTime) : '-',
                                active: data.isActive ? { type: 'success', title: 'Active' } : { type: 'error', title: 'Invalid' },
                            }
                            redemptions[index] = redemption;
                        }
                    }
                    else {
                        const newRedemptions = redemptions.filter(redemption =>
                        {return redemption.code !== change.doc.id}
                        )
                        redemptions = [...newRedemptions];
                    }
                })
                dispatch({
                    type: GET_REDEMPTIONS,
                    data: redemptions
                })
            })

        } catch (e) {
            dispatch({
                type: REWARD_ERROR,
                data: e,
            })
        }
    }
}

export function showRewardForm(id?: string): AppThunk {
    return dispatch => {
        dispatch({
            type: REWARD_FORM,
            data: {
                show: true,
                id: id ? id : '',
            }
        })
    }
}

export function showRedemptionForm(id?:string): AppThunk {
    return dispatch => {
        dispatch({
            type: REDEMPTION_FORM,
            data: {
                show: true,
                id: id ? id : '',
            }
        })
    }
}

export function hideRewardForm(): AppThunk {
    return dispatch => {
        dispatch({
            type: REWARD_FORM,
            data: {
                show: false,
                id: '',
            }
        })
    }
}

export function hideRedemptionForm(): AppThunk {
    return dispatch => {
        dispatch({
            type: REDEMPTION_FORM,
            data: {
                show: false,
                id: '',
            }
        })
    }
}

async function uploadImage (imageUrl: string, id:string, type:string) : Promise<string> {
    const imageRef = Firebase.storage().ref('rewards/' + id ).child(type);
    try {
        const arr = imageUrl.split(',');
        imageUrl = arr[1];
        await imageRef.putString(imageUrl, 'base64').then((img) => {
            img.ref.getDownloadURL().then((url) => {
                return url;
            });
        });
    } catch (e) {
        // return Promise.reject(e);
        console.log(e.message);
        return null;
    }
    return null;
}

export function submitReward(value:VenueReward, master:{
    recordExpireDateTime;
    recordValidityPeriod;
    quantity;
    remaining;
}, coverUrl:string, detailsUrl:string): AppThunk {
    return async dispatch => {
        if (Firebase === null)
        {
            dispatch({
                type: REWARD_ERROR,
                data: null
            })
        }
        try {
            let rewardId:string;
            if (value.id)
            {
                rewardId = value.id;
            }
            else {
                rewardId = FirebaseDB.collection(FirestoreKey.Rewards).doc().id;
                value.id = rewardId;
            }
            dispatch({
                type: SUBMIT_PROCESSING,
                data: true,
            });
            const rewardProm = await FirebaseFunctions.httpsCallable('saveReward');
            rewardProm({ reward: value, rewardMaster: master }).then(
                async result => {
                    console.log(result)
                    //Check if new image upload or old only.
                    if (coverUrl) {
                        const cover = await uploadImage(coverUrl, rewardId, 'cover');
                        console.log(cover);
                    }
                    if (detailsUrl) {
                        const details = await uploadImage(detailsUrl, rewardId, 'detail');
                        console.log(details);
                    }
                    dispatch({
                        type: SUBMIT_PROCESSING,
                        data: false,
                    })
                    dispatch({
                        type: REWARD_FORM,
                        data: {
                            show: false,
                            id: '',
                        }
                    })
                }
            ).catch(e => console.log(e))
        } catch (e) {
            dispatch({
                type: REWARD_ERROR,
                data: e,
            })
        }
    }
}

export function submitRedemption(value: {id, type, rewardId, expireDateTime, isActive}): AppThunk {
    return async dispatch => {
        if (Firebase === null)
        {
            dispatch({
                type: REWARD_ERROR,
                data: null
            })
        }
        try {
            dispatch({
                type: SUBMIT_PROCESSING,
                data: true,
            });
            const redemptionProm = await FirebaseFunctions.httpsCallable('saveRedemptionCode');
            redemptionProm({ code: value }).then(
                result => {
                    console.log(result);
                    console.log(value);
                    dispatch({
                        type: SUBMIT_PROCESSING,
                        data: false,
                    });
                    dispatch({
                        type: REDEMPTION_FORM,
                        data: {
                            show: false,
                            id: '',
                        }
                    })
                }
            ).catch(e => {
                console.log(e);
            })


        } catch (e) {
            console.log(e)
            dispatch({
                type: REWARD_ERROR,
                data: e,
            })
        }
    }
}

async function getRewardMaster(id:string) : Promise<{expiry, validity, remaining, quantity, url, code}> {
    let master : {expiry, validity, remaining, quantity, url, code};
    const masterRewardRef = FirebaseDB.collection(FirestoreKey.RewardMasters).doc(id);
    try {
        const snap = await masterRewardRef.get();
        const data = snap.data();
        master = {
            expiry: data.recordExpireDateTime ? moment(data.recordExpireDateTime) : null,
            validity: data.recordValidityPeriod,
            remaining: data.remaining,
            quantity: data.quantity,
            url: data.url,
            code: data.code,
        }
        return master;
    } catch (e) {
        return null;
    }

}
async function getImage(id:string, type:string) : Promise<string> {
    let image: string;
    const imageRef = Firebase.storage().ref('rewards/' + id + '/' + type);

    try {
        image = await imageRef.getDownloadURL();
        return image;
    } catch (e) {
        console.log(e.message);
        return null;
    }
}

export function getRewardDetails(id:string): AppThunk {
    return async dispatch => {
        if (Firebase === null) {
            dispatch({
                type: REWARD_ERROR,
                data: null
            })
        }
        try {
            dispatch({
                type: SUBMIT_PROCESSING,
                data: true,
            });
            const rewardRef = await FirebaseDB.collection(FirestoreKey.Rewards).doc(id);
            rewardRef.onSnapshot(async snap => {
                const data = snap.data();
                const rewardMaster = await getRewardMaster(id);
                const cover = await getImage(id, 'cover');
                const details = await getImage(id, 'detail');
                const Reward = {
                    id: snap.id,
                    type: data.type,
                    userRole: data.userRole,
                    isActive: data.isActive,
                    isIssueOnReg: data.isIssueOnReg,
                    title: data.title,
                    description: data.description,
                    redeem: !!data.expireDateTime,
                    expireDateTime: data.expireDateTime ? moment(data.expireDateTime) : null,
                    quantity: rewardMaster.quantity,
                    remaining: rewardMaster.remaining,
                    recordValidityPeriod: rewardMaster.validity,
                    expire: !!rewardMaster.expiry,
                    recordExpireDateTime: rewardMaster.expiry,
                    coverUrl: cover,
                    detailsUrl: details,
                }
                if (Reward.type === 0)
                {
                    Reward['discount'] = data.discount;
                    Reward['recommendation'] = data.recommendation ? data.recommendation.split(',') : null;
                    Reward['capacity'] = data.capacity;
                    Reward['website'] = data.website;
                    Reward['originalPrice'] = data.originalPrice;
                    Reward['location'] = data.location;
                    Reward['tnc'] = data.tnc;
                }
                else if (Reward.type === 1)
                {
                    Reward['code'] = rewardMaster.code;
                }
                else if (Reward.type === 3)
                {
                    Reward['url'] = rewardMaster.url;
                }
                else if (Reward.type === 5)
                {
                    Reward['discount'] = data.discount;
                }
                console.log(Reward);
                dispatch({
                    type: SUBMIT_PROCESSING,
                    data: false,
                });
                dispatch({
                    type: REWARD_DETAILS,
                    data: Reward,
                })
            })

        } catch (e) {
            dispatch({
                type: REWARD_ERROR,
                data: e
            })
        }
    }
}

export function getRedemptionDetails(id:string): AppThunk {
    return async dispatch => {
        if (Firebase === null)
        {
            dispatch({
                type: REDEMPTION_DETAILS,
                data: null,
            })
        }
        try {
            dispatch({
                type: SUBMIT_PROCESSING,
                data: true,
            });
            const RedemptionRef = FirebaseDB.collection('rewardRedemptionCodes').doc(id);
            await RedemptionRef.onSnapshot(doc => {
                const data = doc.data() ;
                const Redemption = {
                    id: doc.id,
                    type: data.type,
                    rewardId: data.rewardId,
                    expireDateTime: data.expireDateTime ? moment(data.expireDateTime) : null,
                    isActive: data.isActive,
                    amount: data.amount,
                    isRepeatable: data.isRepeatable,
                }
                dispatch({
                    type: SUBMIT_PROCESSING,
                    data: false,
                });
                dispatch({
                    type: REDEMPTION_DETAILS,
                    data: Redemption,
                })
            })

        } catch (e) {
            console.log(e)
            dispatch({
                type: REDEMPTION_ERROR,
                data: e,
            })
        }
    }
}