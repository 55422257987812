import React from 'react'
import { Card, Dropdown, Menu, Table, Tag } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { IUsersState } from "../../../store/reducers/users";
import { getExperienceRecord, PRE, LAUNCHED, ON_GOING, COMPLETED, SEALED } from "../../../store/actions/users";
import { connect } from "react-redux";
import { IStoreState } from '../../../store/IStoreState';
import { ActivitySession } from '../../../models/activity';
import moment from 'moment';
import { dateStringFormat } from '../../../constants/setting';

export interface ExperienceRecord {
    id: string,
    name: string,
    numberOfPackages: number,
    sessions: Array<ActivitySession>,
    purchasedUsers: Array<string>,
    createDate: string,
    lastUpdateDate: string
    status: string,
}
const columns: ColumnProps<ExperienceRecord>[] = [
    {
        title: 'Exp. Name',
        dataIndex: 'name',
    },
    {
        title: 'No. of Package(s)',
        dataIndex: 'numberOfPackages',
        render: function render(n: number) {
            return (
                <div>{n > 1 ? n.toString() + " Packages" : n.toString() + " Package"}</div>
            );
        }
    },
    {
        title: 'No. of Session(s)',
        dataIndex: 'sessions',
        render: function render(sessions: Array<ActivitySession>) {
            return (
                <Dropdown placement="topLeft" overlay=
                {<Menu>
                    <Menu.ItemGroup title="Enrolled session(s)">
                        {sessions.map((element => {
                            if (element.startDateTime && element.endDateTime)
                                return <Menu.Item>{moment(element.startDateTime).format(dateStringFormat.dateTime) + " - " + moment(element.endDateTime).format(dateStringFormat.dateTime)}</Menu.Item>
                            else
                                return <Menu.Item>No recorded start and end time</Menu.Item>
                        }))}
                    </Menu.ItemGroup>
                </Menu>}>
                    <div>{sessions.length > 1 ? sessions.length.toString() + " Sessions" : sessions.length.toString() + " Session"}</div>
                </Dropdown>
            );
        }
    },
    {
        title: 'No. of Purchase(s)',
        dataIndex: 'purchasedUsers',
        render: function render(purchasedUsers: Array<string>) {
            return (
                <Dropdown placement="topLeft" overlay=
                {<Menu>
                    <Menu.ItemGroup title="Purchased user(s)">
                        {purchasedUsers.map((element => {
                            return <div>{element}</div>
                        }))}
                    </Menu.ItemGroup>
                </Menu>}>
                    <div>{purchasedUsers.length > 1 ? purchasedUsers.length.toString() + " purchases" : purchasedUsers.length.toString() + " purchase"}</div>
                </Dropdown>
            );
        }
    },
    {
        title: 'Last Update Date',
        dataIndex: 'lastUpdateDate',
    },
    {
        title: 'Create date',
        dataIndex: 'createDate',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        // status === "Success" ? <Tag color="#2ED47A">{status}</Tag> : <Tag color="#F7685B">{status}</Tag>
        render: function render(status: String) {
            switch(status){
                case LAUNCHED: 
                    return(<Tag color="#2ED47A">{status}</Tag>)
                case ON_GOING:
                    return(<Tag color="#2DB7F5">{status}</Tag>)
                case COMPLETED:
                    return(<Tag color="#F7685B">{status}</Tag>)
                case SEALED:
                    return(<Tag color="#C4C4C4">{status}</Tag>)
                case PRE:
                    return(<Tag color="#108EE9">{status}</Tag>)
                default:
                    return(<Tag color="#C4C4C4">{status}</Tag>)
            }
        }
    },
];

interface IProps {
    id: string,
    getExperienceRecord : (id : string) => void,
    users: IUsersState,
}

class ExperienceRecordComponent extends React.Component <IProps> {
    componentDidMount(): void {
        this.props.getExperienceRecord(this.props.id);
    }

    render() {
        const { experiences } = this.props.users;
        console.log(experiences);
        return (
            <Card style={{ margin: '16px' }} title='Hosted Experience History' headStyle={{ backgroundColor: '#F8F8F8' }}  bodyStyle={{ padding: '0' }}>
                <Table columns={columns}
                    className="table"
                    dataSource={experiences}
                    bordered={false}
                    pagination={{ pageSize: 4, hideOnSinglePage: true, style: { float: "left" } }}/>
            </Card>
        );
    }
}

const mapStateToProps = (state:IStoreState) => {
    return {
        ...state
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getExperienceRecord: (id: string) => {
            return dispatch(getExperienceRecord(id))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ExperienceRecordComponent)
