import React from 'react';
import { Input } from 'antd';
import algoliasearch from 'algoliasearch';
import ExperienceList from "./ExperienceList";
import { AlgoliaSettings } from '../../constants/setting';

const { Search } = Input;


const client = algoliasearch(AlgoliaSettings.appId, AlgoliaSettings.apiKey);
const index = client.initIndex(AlgoliaSettings.indexActivities);

interface IState {
    showExperience: boolean,
    hits: Array<{title, objectID}>,
    ids : string[],
}

type IProps = unknown

class Experiences extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            showExperience: false,
            hits: [],
            ids: [],
        }
    }

    search(value: string): void {
        index.search( value, {
            attributesToRetrieve: ['title'],
            hitsPerPage: 10,
        }).then (({ hits }) => {
            if (value !== null && value !== "") {
                const ids :  string[] = []
                this.setState({ showExperience: true });
                hits.forEach(hit => {
                    ids.push(hit.objectID);
                })
                const mappedHits = hits.map((hit:{title, objectID}) => {return ({ title: hit.title, objectID: hit.objectID })});
                this.setState({ ids: ids, hits: mappedHits })
            }
        }
        );
    }

    render(): React.ReactNode {
        return (
            <div style={{
                margin: "16px"
            }}>
                <Search
                    placeholder="Search Experience or Hero"
                    onSearch={value => this.search(value)}
                    className='search'
                    style={{
                        marginBottom: "16px"
                    }}
                    size="large"
                />
                {this.state.showExperience && <ExperienceList display={this.state.showExperience} ids={this.state.ids} hits={this.state.hits}/>}
            </div>
        );
    }
}

export default Experiences;
