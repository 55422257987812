import React from 'react'
import { Badge, Button, Card, message, Table, Tag, Tooltip } from "antd";
import { ITransactionState } from "../../store/reducers/transactions";
import { getRefundDetails, retryRefund } from "../../store/actions/transactions";
import { connect } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Status } from '../experiences/ExperienceDetails';

export interface OutstandingRefundRecord {
    id: string,
    paymentId: string,
    stripeId: string,
    stripeRefundId: string,
    refundDate: string,
    refundPercent : string,
    refunded: string,
    status: Status,
}

interface IProps {
    getRefundDetails : () => void,
    retryRefund : (id: string) => void,
    transactions: ITransactionState,
}


class OutstandingRefund extends React.Component <IProps> {
    componentDidMount(): void {
        this.props.getRefundDetails();
    }

    render() {
        const { refunds } = this.props.transactions;
        const columns = [
            {
                title: 'ID',
                key: 'id',
                render: record => {
                    const success = () => {
                        message.success('Refund ID Copied!');
                    };
                    return (
                        <CopyToClipboard text={record.id}><Tooltip placement="top" title={record.id}><Tag onClick={success}>ID</Tag></Tooltip></CopyToClipboard>
                    )
                }
            },
            {
                title: 'Payment ID',
                render: record => {
                    const success = () => {
                        message.success('Payment ID Copied!');
                    };
                    return (
                        <CopyToClipboard text={record.paymentId}><Tooltip placement="top" title={record.paymentId}><Tag onClick={success}>Payment</Tag></Tooltip></CopyToClipboard>
                    )
                }
            },
            {
                title: 'Stripe Payment ID',
                render: record => {
                    const success = () => {
                        message.success('Stripe Payment ID Copied!');
                    };
                    return (
                        <CopyToClipboard text={record.stripeId}><Tooltip placement="top" title={record.stripeId}><Tag onClick={success}>Stripe Payment</Tag></Tooltip></CopyToClipboard>
                    )
                }
            },
            {
                title: 'Stripe Refund ID',
                render: record => {
                    const success = () => {
                        message.success('Stripe Refund ID Copied!');
                    };
                    return (
                        <div>
                            {
                                record.stripeRefundId !== '-' ?
                                    <CopyToClipboard text={record.stripeRefundId}>
                                        <Tooltip overlayStyle={{ backgroundColor: 'white', color: '#000' }} placement="top" title={record.stripeRefundId}>
                                            <Tag onClick={success}>Stripe Refund</Tag>
                                        </Tooltip>
                                    </CopyToClipboard>
                                    : '-'
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Refund Date',
                dataIndex: 'refundDate',
            },
            {
                title: 'Refunded %',
                dataIndex: 'refundPercent',
            },
            {
                title: 'Refunded',
                dataIndex: 'refunded',
            },
            {
                title: 'Status',
                render: (record) => {
                    return (
                        record.status && <Badge status={record.status.type} text={record.status.title} />
                    )
                }
            },
            {
                title: 'Action',
                render: (record) => {
                    let action: boolean;
                    let color: string;
                    if (record.status.title === 'Failed') {
                        action = true;
                        color = '#F7C16A'
                    } else if (record.status.title === 'Processing') {
                        action = false;
                        color = '#DBD7D5'
                    }
                    return (
                        record.status && <Button disabled={!action} onClick={() => this.props.retryRefund(record.id)}
                            style={{
                                backgroundColor: color,
                                color: '#ffffff',
                                width: '130px',
                                border: 0,
                            }}> Retry </Button>
                    )
                }
            },
        ]
        return (
            <Card title="Refund Record" style={{ margin: '16px' }} headStyle={{ backgroundColor: '#F8F8F8' }} bodyStyle={{ padding: "0" }}>
                <Table
                    rowKey='id'
                    columns={columns} dataSource={refunds} />
            </Card>
        )
    }
}

const mapStateToProps  = (state:ITransactionState) => {
    return ({
        ...state,
        refunds: state.refunds,
    })
}

const mapDispatchToProps = (dispatch) => {
    return ({
        getRefundDetails: () => dispatch(getRefundDetails()),
        retryRefund: (id: string) => dispatch(retryRefund(id)),
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(OutstandingRefund)