import React from 'react';
import {
    Drawer,
    Button,
    Row,
    Input,
    Select,
    DatePicker,
    Switch,
    InputNumber,
    Checkbox,
    Form
} from 'antd';
import { IRewardState } from "../../store/reducers/rewards";
import { getAllRewards, getRedemptionDetails, hideRedemptionForm, submitRedemption } from "../../store/actions/rewards";
import { connect } from "react-redux";
import moment from 'moment'
import { IStoreState } from '../../store/IStoreState';
import { FormInstance } from 'antd/lib/form';

const { Option } = Select;
interface IProps {
    hideRedemptionForm: () => void,
    getRedemptionDetails : (id:string) => void,
    saveRedemption: (value:{
            id,
            type,
            rewardId,
            expireDateTime,
            isActive
        }) => void,
    rewards:IRewardState,
}
interface IState {
    visible: boolean,
    type:number,
    disable: boolean,
    redeem: boolean,
    loading: boolean,
    expireDate: boolean,
    expireDateTime: number,
    amount:number,
    repeatable:boolean,
    status:boolean,
}

class RedemptionForm extends React.Component <IProps, IState> {
    formRef = React.createRef<FormInstance>();

    constructor(props) {
        super(props)
        this.state = {
            visible: false,
            disable: false,
            type: null,
            redeem: false,
            loading: false,
            expireDate: false,
            expireDateTime: null,
            amount: null,
            repeatable: false,
            status: true,
        };
    }

    componentDidUpdate(prevProps: Readonly<IProps>): void {
        const { redemptionForm, redemption } = this.props.rewards;
        if (prevProps.rewards.redemptionForm.show !== redemptionForm.show)
        {
            if (this.formRef.current) {
                this.setState({
                    type: null,
                    amount: null,
                    repeatable: null,
                    redeem: false,
                    expireDate: false,
                    status: true,
                    disable: false,
                });
                this.formRef.current.resetFields();
            }
        }
        if (prevProps.rewards.redemptionForm.id !== redemptionForm.id)
        {
            this.props.getRedemptionDetails(redemptionForm.id);
        }
        if (prevProps.rewards.redemption !== redemption)
        {
            console.log(redemption);
            this.setState({
                type: redemption.type,
                amount: redemption.amount,
                disable: true,
                repeatable: redemption.isRepeatable,
                status: redemption.isActive,
                expireDate: !!redemption.expireDateTime,
                expireDateTime: redemption.expireDateTime ? redemption.expireDateTime : null
            });
            this.formRef.current.setFieldsValue(redemption);
        }
    }

    changeType = (value) => {
        this.setState({
            type: value
        })
        if (!this.props.rewards.redemptionForm.id) {
            if (this.formRef.current) {
                this.formRef.current.resetFields();
            }
        }
    }

    handleSubmit = values => {
        console.log('trying to submit');
        //this.props.hideRedemptionForm();
        const Redemption = {
            id: values.code,
            type: values.type,
            rewardId: values.rewardId,
            expireDateTime: this.state.expireDate ? moment(values.expireDate).unix() * 1000 : null, //Format issue, convert date from Datepicker to timestamp
            isActive: this.state.status,
        };
        if (this.state.type === 1) {
            Redemption['amount'] = parseInt(values.amount, 10);
            Redemption['isRepeatable'] = values.repeatable;
        }
        else if (this.state.type === 2) {
            Redemption['isRepeatable'] = values.repeatable;
        }
        this.props.saveRedemption(Redemption);
    }

    checkRewardId = (rule, value, callback) => {
        const { rewards } = this.props;
        const { rewardIds } = rewards;
        const index = rewardIds.findIndex(id =>
        {return id === value}
        )
        if (value && index === -1)
        {
            callback('Invalid Reward ID');
        }
        else {
            callback();
        }
    }
    render() {
        const { redemptionForm, loading } = this.props.rewards;
        return (
            <div>
                <Drawer
                    title="Create Redemption Item"
                    width={500}
                    onClose={() => {this.props.hideRedemptionForm(); this.formRef.current.resetFields();}}
                    visible={redemptionForm.show}
                >
                    <Form layout="vertical" hideRequiredMark id="rewardForm" ref={this.formRef} onFinish={this.handleSubmit}>
                        <Form.Item
                            label="Type"
                            name="type"
                            rules={[{ required: false }]}
                        >
                            <Select
                                placeholder="Select Type"
                                style={{ width: '200px' }}
                                disabled={this.state.disable || loading}
                                onChange={(value) => this.changeType(value)}
                                defaultValue={this.state.type}
                            >
                                <Option value={2}>Infinity</Option>
                                <Option value={1}>Limited</Option>
                                <Option value={0}>One Time</Option>
                            </Select>
                        </Form.Item>
                        {(this.state.type || this.state.type === 0 || redemptionForm.id) &&
                        <div>
                            <div className="form-subheading">Reward Information</div>
                            <Form.Item
                                label="Status"
                                name="active"
                                initialValue={this.state.status}
                                rules={[{ required: false }]}
                            >
                                <Switch
                                    checkedChildren='✔'
                                    unCheckedChildren='✖'
                                    disabled={loading}
                                    checked={this.state.status}
                                    onChange={e => this.setState({ status: e })}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Code"
                                name="code"
                                rules={[{ required: true, message: 'Please enter a code' }]}
                            >
                                <Input placeholder="Code" disabled={this.state.disable || loading} />
                            </Form.Item>
                            <Form.Item
                                label="Reward ID"
                                name="rewardId"
                                rules={[
                                    { required: true, message: 'Please enter a reward Id' },
                                    { validator: this.checkRewardId }
                                ]}
                                hasFeedback
                            >
                                <Input placeholder="Reward ID" disabled={loading}/>
                            </Form.Item>
                            <Form.Item label="Expiry Date"  style={{ marginBottom: 0 }}>
                                <Row>
                                    <Form.Item
                                        style={{ display: 'inline-block' }}
                                        className="ant-form-checkbox"
                                        name="expire"
                                        rules={[{ required: false }]}
                                        initialValue={this.state.expireDate}
                                    >
                                        <Checkbox
                                            onChange={
                                                (e) => {
                                                    this.setState({ expireDate: e.target.checked });
                                                    if (!e.target.checked) {
                                                        console.log('clear date')
                                                        this.formRef.current.setFieldsValue({ expireDate: null })
                                                    }
                                                }
                                            }
                                            checked={this.state.expireDate} disabled={loading}>Expires on
                                        </Checkbox>
                                    </Form.Item>
                                    <Form.Item
                                        style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}
                                        name="expireDate"
                                        rules={[{ required: false }]}
                                    >
                                        <DatePicker style={{ width: '150px' }} disabled={!this.state.expireDate || loading}/>
                                    </Form.Item>
                                </Row>
                            </Form.Item>
                            {
                                (this.state.type === 1) &&
                                <div>
                                    <Row>
                                        <Form.Item
                                            label="Amount"
                                            name="amount"
                                            rules={[{ required: true, message: 'Please enter an amount!' }]}
                                            initialValue={this.state.amount}
                                            style={{ width: 'calc(30% - 12px)' }}
                                        >
                                            <InputNumber min={0} disabled={loading}/>
                                        </Form.Item>
                                    </Row>
                                    <Form.Item
                                        label="Repeatable"
                                        name="repeatable"
                                        rules={[{ required: false }]}
                                        initialValue={false}
                                    >
                                        <Checkbox  onChange={(e) => this.setState({ repeatable: e.target.checked })}  disabled={loading} checked={this.state.repeatable}>Yes</Checkbox>
                                    </Form.Item>
                                </div>
                            }
                            {
                                ( this.state.type === 2 ) &&
                                <div>
                                    <Form.Item
                                        label="Repeatable"
                                        name="repeatable"
                                        rules={[{ required: false }]}
                                        initialValue={false}
                                    >
                                        <Checkbox disabled={loading}
                                            onChange={(e) => this.setState({ repeatable: e.target.checked })} checked={this.state.repeatable}>Yes</Checkbox>
                                    </Form.Item>
                                </div>
                            }
                        </div>
                        }
                        <div className="reward-form-footer">
                            <Button onClick={() => {this.props.hideRedemptionForm(); this.formRef.current.resetFields();}} style={{ marginRight: 8 }}>
                                Cancel
                            </Button>
                            <Button form="rewardForm" type="primary" htmlType="submit" loading={loading}>
                                Submit
                            </Button>
                        </div>
                    </Form>

                </Drawer>
            </div>
        )
    }
}

const mapStateToProps = (state: IStoreState) => {
    return {
        ...state,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        hideRedemptionForm: () => dispatch(hideRedemptionForm()),
        getRedemptionDetails: (id:string) => dispatch(getRedemptionDetails(id)),
        getAllRewards: () => dispatch(getAllRewards()),
        saveRedemption: (value:{
            id,
            type,
            rewardId,
            expireDateTime,
            isActive
        }) => dispatch(submitRedemption(value))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RedemptionForm);
