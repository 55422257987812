import { Card, Dropdown, Menu, message, Table, Tag, Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import React from 'react';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { connect } from "react-redux";
import { getPaymentRecord } from "../../../store/actions/users";
import { IStoreState } from '../../../store/IStoreState';
import { IUsersState } from "../../../store/reducers/users";

export interface PaymentRecord {
    id: string,
    stripeId: string,
    experience: string,
    type: string,
    paymentDate: string,
    amount: Record<string, number>,
    status: string,
}
const columns: ColumnProps<PaymentRecord>[] = [
    {
        title: 'ID',
        dataIndex: 'id',
        render: function render(record) {
            const success = () => {
                message.success('Payment ID Copied!');
            };
            return (
                <CopyToClipboard text={record}><Tooltip placement="top" title={record}><Tag onClick={(e) => { e.stopPropagation(); success() }}>ID</Tag></Tooltip></CopyToClipboard>
            );
        }
    },
    {
        title: 'Stripe ID',
        dataIndex: 'stripeId',
        render: function render(record) {
            const success = () => {
                message.success('Stripe ID Copied!');
            };
            return (
                <CopyToClipboard text={record ? record : "Not applicable"}><Tooltip placement="top" title={record ? record : "Not applicable"}><Tag onClick={(e) => { e.stopPropagation(); success() }}>ID</Tag></Tooltip></CopyToClipboard>
            );
        }
    },
    {
        title: 'Experience',
        dataIndex: 'experience',
    },
    {
        title: 'Type',
        dataIndex: 'type',
    },
    {
        title: 'Payment date',
        dataIndex: 'paymentDate',
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        render: function render(amount: Record<string, number>) {
            return (
                <Dropdown
                    placement="topLeft"
                    overlay={<Menu style={{ width: 300 }}>
                        <Menu.ItemGroup title="Total Amount">
                            <div style={{ display: 'flex', flexDirection: "row" }}>
                                <span style={{
                                    display: 'flex',
                                    flex: 1,
                                    justifyContent: 'flex-start'
                                }}>
                                    Total
                                </span>
                                <span style={{
                                    display: 'flex',
                                    flex: 1,
                                    justifyContent: 'flex-end'
                                }}>
                                    HKD {amount["amount"].toFixed(2)}
                                </span>
                            </div>
                            <Menu.Divider />
                            <div style={{ display: 'flex', flexDirection: "row" }}>
                                <span style={{
                                    display: 'flex',
                                    flex: 1,
                                    justifyContent: 'flex-start'
                                }}>
                                    Bundle Price
                                </span>
                                <span style={{
                                    display: 'flex',
                                    flex: 1,
                                    justifyContent: 'flex-end'
                                }}>
                                    HKD {amount["bundlePrice"] ? amount["bundlePrice"].toFixed(2) : '0.00'}
                                </span>
                            </div>
                            <div style={{ display: 'flex', flexDirection: "row" }}>
                                <span style={{
                                    display: 'flex',
                                    flex: 1,
                                    justifyContent: 'flex-start'
                                }}>
                                    Package Price
                                </span>
                                <span style={{
                                    display: 'flex',
                                    flex: 1,
                                    justifyContent: 'flex-end'
                                }}>
                                    HKD {amount["packagePrice"] ? amount["packagePrice"].toFixed(2) : '0.00'}
                                </span>
                            </div>
                            <div style={{ display: 'flex', flexDirection: "row" }}>
                                <span style={{
                                    display: 'flex',
                                    flex: 1,
                                    justifyContent: 'flex-start'
                                }}>
                                    Venue Event Price
                                </span>
                                <span style={{
                                    display: 'flex',
                                    flex: 1,
                                    justifyContent: 'flex-end'
                                }}>
                                    HKD {amount["venueEventPrice"] ? amount["venueEventPrice"].toFixed(2) : '0.00'}
                                </span>
                            </div>
                            <div style={{ display: 'flex', flexDirection: "row" }}>
                                <span style={{
                                    display: 'flex',
                                    flex: 1,
                                    justifyContent: 'flex-start'
                                }}>
                                    Platform Fee
                                </span>
                                <span style={{
                                    display: 'flex',
                                    flex: 1,
                                    justifyContent: 'flex-end'
                                }}>
                                    HKD {amount["platformFee"] ? amount["platformFee"].toFixed(2) : '0.00'}
                                </span>
                            </div>
                        </Menu.ItemGroup>
                        <Menu.ItemGroup title="Additional Fee">
                            <div style={{ display: 'flex', flexDirection: "row" }}>
                                <span style={{
                                    display: 'flex',
                                    flex: 1,
                                    justifyContent: 'flex-start'
                                }}>
                                    Transaction Fee
                                </span>
                                <span style={{
                                    display: 'flex',
                                    flex: 1,
                                    justifyContent: 'flex-end'
                                }}>
                                    HKD {amount["transactionFee"] ? amount["transactionFee"].toFixed(2) : '0.00'}
                                </span>
                            </div>
                            <div style={{ display: 'flex', flexDirection: "row" }}>
                                <span style={{
                                    display: 'flex',
                                    flex: 1,
                                    justifyContent: 'flex-start'
                                }}>
                                    Stripe Fee
                                </span>
                                <span style={{
                                    display: 'flex',
                                    flex: 1,
                                    justifyContent: 'flex-end'
                                }}>
                                    HKD {amount["stripeFee"] ? amount["stripeFee"].toFixed(2) : '0.00'}
                                </span>
                            </div>
                            <div style={{ display: 'flex', flexDirection: "row" }}>
                                <span style={{
                                    display: 'flex',
                                    flex: 1,
                                    justifyContent: 'flex-start'
                                }}>
                                    Payme Fee
                                </span>
                                <span style={{
                                    display: 'flex',
                                    flex: 1,
                                    justifyContent: 'flex-end'
                                }}>
                                    HKD {amount["paymeFee"] ? amount["paymeFee"].toFixed(2) : '0.00'}
                                </span>
                            </div>
                            <div style={{ display: 'flex', flexDirection: "row" }}>
                                <span style={{
                                    display: 'flex',
                                    flex: 1,
                                    justifyContent: 'flex-start'
                                }}>
                                    Donation
                                </span>
                                <span style={{
                                    display: 'flex',
                                    flex: 1,
                                    justifyContent: 'flex-end'
                                }}>
                                    HKD {amount["donation"] ? amount["donation"].toFixed(2) : '0.00'}
                                </span>
                            </div>
                        </Menu.ItemGroup>
                    </Menu>}>
                    <div style={{ display: 'flex', flexDirection: "row", width: 90 }}>
                        <span style={{
                            display: 'flex',
                            flex: 1,
                            justifyContent: 'flex-start'
                        }}>
                            HKD
                        </span>
                        <span style={{
                            display: 'flex',
                            flex: 1,
                            justifyContent: 'flex-end'
                        }}>
                            {amount["amount"].toFixed(2)}
                        </span>
                    </div>
                </Dropdown>
            );
        }
    },
    {
        title: 'Status',
        dataIndex: 'status',
        render: function render(status: string) {
            return (
                status === "Success" ? <Tag color="#2ED47A">{status}</Tag> : <Tag color="#F7685B">{status}</Tag>
            )
        }
    },
];

interface IProps {
    id: string,
    getPaymentRecord: (id: string) => void,
    users: IUsersState,
}

class PaymentRecordComponent extends React.Component<IProps> {
    componentDidMount(): void {
        this.props.getPaymentRecord(this.props.id);
    }

    render() {
        const { payments } = this.props.users;
        console.log(payments);
        return (
            <Card style={{ margin: '16px' }} title='Payment Record' headStyle={{ backgroundColor: '#F8F8F8' }} bodyStyle={{ padding: '0' }}>
                <Table columns={columns}
                    className="table"
                    dataSource={payments}
                    rowKey='id'
                    bordered={false}
                    pagination={{ pageSize: 4, hideOnSinglePage: true, style: { float: "left" } }} />
            </Card>
        );
    }
}

const mapStateToProps = (state: IStoreState) => {
    return {
        ...state,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getPaymentRecord: (id: string) => {
            return dispatch(getPaymentRecord(id))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(PaymentRecordComponent)
