import React from 'react';

class Dashboard extends React.Component {
    render(): React.ReactNode {
        return (
            <iframe width="100%" height="100%" src="https://datastudio.google.com/embed/reporting/1HO0If2r6NNJBXOQtL0uw86nc9k6zxfhK/page/zM8IB" frameBorder="0" style={{ border: 0, padding: "16px" }} allowFullScreen={true} title="Google Data Studio">
            </iframe>
        );
    }
}

export default Dashboard