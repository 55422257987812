import { GET_HTML, GET_STRINGS, SAVE_STRING_FORM, SHOW_STRING_FORM, SUBMIT_HTML } from "../actions/contentManagement";
import { IString } from "../../components/contentManagement/Strings";

export interface ICMState {
    stringForm : {
        id:string,
        show:boolean,
    },
    strings: Array<IString>,
    loading: boolean,
    html: {file, text},
    htmlLoading: boolean,
}

export const initialState:ICMState = {
    stringForm: {
        id: null,
        show: false,
    },
    strings: [],
    loading: false,
    html: { file: null, text: null },
    htmlLoading: false,
}

export default (state = initialState, action: {type: string, data}): ICMState => {
    switch (action.type) {
        case SHOW_STRING_FORM:
            return {
                ...state,
                stringForm: action.data,
            }
        case GET_STRINGS: {
            const strings = action.data.sort((a, b) => a.key > b.key ? 1 : -1);
            return {
                ...state,
                strings: strings,
            }
        }
        case SAVE_STRING_FORM:
            console.log(state)
            return {
                ...state,
                loading: action.data,
            }
        case GET_HTML:
            state.html[action.data.file] = action.data.text;
            return {
                ...state,
            }
        case SUBMIT_HTML:
            return {
                ...state,
                htmlLoading: action.data,
            }
        default:
            return {
                ...state,
            }
    }
}