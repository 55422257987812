import { Firebase, FirebaseDB, FirebaseFunctions } from "../../lib/firebase";
import { ICashOut } from "../../components/transactions/CashOutTable";
import { FirestoreKey } from "../../constants/setting";
import { Payout, PayoutMethodType, PayoutStatus } from "../../models/payout";
import moment from 'moment'
import { USERS_ERROR } from "./users";
import { UserVariable } from "../../models/user";
import { Refund } from "../../models/refund";
import { OutstandingRefundRecord } from "../../components/transactions/OutstandingRefund";
import { dateStringFormat } from "../../constants/setting";
import { AppThunk } from "../IStoreState";

export const GET_PENDING_PAYOUTS = 'GET_PENDING_PAYOUTS';
export const GET_CASH_OUTS = 'GET_CASH_OUTS';
export const CASHOUT_ERROR = 'CASHOUT_ERROR';
export const UPDATE_CASHOUT_STATUS = 'UPDATE_CASHOUT_STATUS';
export const UPDATE_ERROR = 'UPDATE_ERROR';
export const SHOW_CANCEL_PAYOUT_FORM = 'SHOW_CANCEL_PAYOUT_FORM';
export const HIDE_CANCEL_PAYOUT_FORM = 'HIDE_CANCEL_PAYOUT_FORM';
export const SHOW_SETTLE_PAYOUT = 'SHOW_SETTLE_PAYOUT';
export const HIDE_SETTLE_PAYOUT = 'HIDE_SETTLE_PAYOUT';
export const GET_PAYOUT_DETAILS = 'GET_PAYOUT_DETAILS';
export const GET_REFUND_DETAILS = 'GET_REFUND_DETAILS';
export const UPLOAD_ERROR = 'UPLOAD_ERROR';

function getRefundStatus (isRefunded:boolean, isFailed:boolean) {
    if (isRefunded)
        return {
            title: "Success",
            type: 'success'
        }
    else if (isFailed)
        return {
            title: 'Failed',
            type: 'error'
        }
    else
        return {
            title: 'Processing',
            type: 'processing'
        }
}

export function getCashOut(): AppThunk {
    return async dispatch => {
        if (Firebase === null) {
            dispatch({
                type: CASHOUT_ERROR,
                data: null,
            })
        }
        try {
            const transRef = await FirebaseDB.collection(FirestoreKey.Payouts).orderBy('createdDateTime', 'desc');
            const userRef = await FirebaseDB.collection(FirestoreKey.UsersVariable);

            await transRef.onSnapshot(async snap => {
                const transactions : ICashOut[] = [];
                snap.forEach(async doc => {
                    const len = snap.docs.length ? snap.docs.length : 0;
                    const data = await doc.data() as Payout;
                    if (data)
                    {
                        const user = data.userId;
                        const transaction : ICashOut = {
                            id: doc.id,
                            userId: data.userId,
                            user: '',
                            cashOutMethod: PayoutMethodType[data.payoutMethodType],
                            cashOutAccount: data.payoutMethodIdentifier,
                            cashOut: '$' + data.amount,
                            serviceFee: '$' + data.serviceFee,
                            final: '$' + data.netAmount,
                            cashOutDate: (data.createdDateTime) ? moment(data.createdDateTime).format(dateStringFormat.dateTime) : '-',
                            lastUpdated: (data.lastUpdatedDateTime) ? moment(data.lastUpdatedDateTime).format(dateStringFormat.dateTime) : '-',
                            status: PayoutStatus[data.status],
                            statusNo: data.status,
                            canceledBy: data.canceledBy,
                            canceledDateTime: (data.canceledDateTime) ? moment(data.canceledDateTime).format(dateStringFormat.dateTime) : '-',
                            cancellationReason: data.cancellationReason
                        }
                        await userRef.doc(user).onSnapshot(snap => {
                            const data = snap.data() as UserVariable;
                            transaction.user = data.email;
                            transactions.push(transaction);
                            if (transactions.length === len && len !== 0)
                            {
                                dispatch({
                                    type: GET_CASH_OUTS,
                                    data: transactions,
                                })
                            }
                        })
                    }
                })
            })
        }
        catch (error) {
            dispatch({
                type: CASHOUT_ERROR,
                data: null,
            })
        }

    }
}

export function getPendingCashOut(): AppThunk {
    return async dispatch => {
        if (Firebase === null) {
            dispatch({
                type: CASHOUT_ERROR,
                data: null,
            })
        }
        try {

            let pendingTransactions : ICashOut[] = [];
            const transRef = await FirebaseDB.collection(FirestoreKey.Payouts).where('status', '==', 0).orderBy('createdDateTime', 'desc');
            const userRef = await FirebaseDB.collection(FirestoreKey.UsersVariable);
            transRef.onSnapshot(async snap => {
                await Promise.all(snap.docChanges().map( async change => {
                    if (change.type === "added")
                    {
                        const index = pendingTransactions.findIndex(e => {
                            return e.id === change.doc.id;
                        })
                        if (index === -1)
                        {
                            const data = change.doc.data() as Payout;
                            if (data) {
                                const user = data.userId;
                                const transaction: ICashOut = {
                                    id: change.doc.id,
                                    userId: data.userId,
                                    user: '',
                                    cashOutMethod: PayoutMethodType[data.payoutMethodType],
                                    cashOutAccount: data.payoutMethodIdentifier,
                                    cashOut: '$' + data.amount,
                                    serviceFee: '$' + data.serviceFee,
                                    final: '$' + data.netAmount,
                                    cashOutDate: (data.createdDateTime) ? moment(data.createdDateTime).format(dateStringFormat.dateTime) : '-',
                                    lastUpdated: (data.lastUpdatedDateTime) ? moment(data.lastUpdatedDateTime).format(dateStringFormat.dateTime) : '-',
                                    status: PayoutStatus[data.status],
                                    statusNo: data.status,
                                    canceledBy: data.canceledBy,
                                    canceledDateTime: (data.canceledDateTime) ? moment(data.canceledDateTime).format(dateStringFormat.dateTime) : '-',
                                    cancellationReason: data.cancellationReason
                                };
                                try {
                                    const snap = await userRef.doc(user).get();
                                    const data = snap.data();
                                    transaction.user = data.email;
                                    pendingTransactions.push(transaction);
                                } catch (error) {
                                    console.log(error);
                                }
                            }
                        }
                    }
                    else if (change.type === "modified")
                    {
                        const index = pendingTransactions.findIndex(e => {
                            return e.id === change.doc.id;
                        })
                        if (index !== -1)
                        {
                            const data = change.doc.data() as Payout;
                            if (data) {
                                const user = data.userId;
                                pendingTransactions[index] = {
                                    id: change.doc.id,
                                    userId: data.userId,
                                    user: '',
                                    cashOutMethod: PayoutMethodType[data.payoutMethodType],
                                    cashOutAccount: data.payoutMethodIdentifier,
                                    cashOut: '$' + data.amount,
                                    serviceFee: '$' + data.serviceFee,
                                    final: '$' + data.netAmount,
                                    cashOutDate: (data.createdDateTime) ? moment(data.createdDateTime).format(dateStringFormat.dateTime) : '-',
                                    lastUpdated: (data.lastUpdatedDateTime) ? moment(data.lastUpdatedDateTime).format(dateStringFormat.dateTime) : '-',
                                    status: PayoutStatus[data.status],
                                    statusNo: data.status,
                                    canceledBy: data.canceledBy,
                                    canceledDateTime: (data.canceledDateTime) ? moment(data.canceledDateTime).format(dateStringFormat.dateTime) : '-',
                                    cancellationReason: data.cancellationReason
                                };
                                try {
                                    const snap = await userRef.doc(user).get();
                                    const data = snap.data();
                                    pendingTransactions[index].user = data.email;

                                } catch (error) {
                                    console.log(error);
                                }
                            }
                        }
                    }
                    else if (change.type === "removed")
                    {
                        const newTrans = pendingTransactions.filter(transaction => {
                            return transaction.id !== change.doc.id;
                        })
                        pendingTransactions = [...newTrans];
                    }
                }))
                dispatch({
                    type: GET_PENDING_PAYOUTS,
                    data: pendingTransactions,
                })

            })
        }
        catch (error) {
            dispatch({
                type: CASHOUT_ERROR,
                data: null,
            })
        }

    }
}

export function getPayoutDetails(id:string): AppThunk {
    return async dispatch => {
        if (Firebase === null) {
            dispatch({
                type: CASHOUT_ERROR,
                data: null,
            })
        }

        try {
            const transRef = await FirebaseDB.collection(FirestoreKey.Payouts).doc(id);
            const userRef = await FirebaseDB.collection(FirestoreKey.UsersVariable);
            await transRef.onSnapshot(async doc => {
                const data = await doc.data() as Payout;
                if (data)
                {
                    const user = data.userId;
                    const transaction : ICashOut = {
                        id: doc.id,
                        userId: data.userId,
                        user: '',
                        cashOutMethod: PayoutMethodType[data.payoutMethodType],
                        cashOutAccount: data.payoutMethodIdentifier,
                        cashOut: '$' + data.amount,
                        serviceFee: '$' + data.serviceFee,
                        final: '$' + data.netAmount,
                        cashOutDate: (data.createdDateTime) ? moment(data.createdDateTime).format(dateStringFormat.date) : '-',
                        lastUpdated: (data.lastUpdatedDateTime) ? moment(data.lastUpdatedDateTime).format(dateStringFormat.date) : '-',
                        status: PayoutStatus[data.status],
                        statusNo: data.status,
                        canceledBy: data.canceledBy,
                        canceledDateTime: (data.canceledDateTime) ? moment(data.canceledDateTime).format(dateStringFormat.date) : '-',
                        cancellationReason: data.cancellationReason
                    }
                    await userRef.doc(user).onSnapshot(snap => {
                        const data = snap.data();
                        transaction.user = data.email
                        dispatch({
                            type: GET_PAYOUT_DETAILS,
                            data: transaction,
                        })

                    })
                }

            })

        }
        catch (error) {
            dispatch({
                type: CASHOUT_ERROR,
                data: null,
            })
        }


    }
}

export function getRefundDetails(): AppThunk {
    return async dispatch => {
        if (Firebase === null)
        {
            dispatch({
                type: UPDATE_ERROR,
                data: null,
            })
        }
        try {
            let refunds = [] as OutstandingRefundRecord[];
            const refundRef = await FirebaseDB.collection(FirestoreKey.Refunds);
            refundRef.where('isRefunded', '==', false)
                .orderBy('createdDateTime', 'desc').onSnapshot(async snap => {
                    await Promise.all(snap.docChanges().map(async change => {
                        if (change.type === 'added') {
                            const index = refunds.findIndex(refund => {
                                return refund.id === change.doc.id
                            });
                            if (index === -1)
                            {
                                const data = change.doc.data() as Refund;
                                const refund : OutstandingRefundRecord = {
                                    id: change.doc.id,
                                    paymentId: data.paymentId,
                                    stripeId: data.stripeChargeId,
                                    stripeRefundId: data.stripeRefundId ? data.stripeRefundId : '-',
                                    refundDate: moment(data.refundedDateTime).format(dateStringFormat.dateTime),
                                    refundPercent: (data.refundedPercentage * 100) + "%",
                                    refunded: '$' + data.refundedAmount,
                                    status: getRefundStatus(data.isRefunded, data.isFailed),
                                }
                                refunds.push(refund);
                            }
                        }
                        else if (change.type === 'modified') {
                            const index = refunds.findIndex(refund => {
                                return refund.id === change.doc.id
                            });
                            if (index !== -1) {

                                const data = change.doc.data() as Refund;
                                const refund : OutstandingRefundRecord = {
                                    id: change.doc.id,
                                    paymentId: data.paymentId,
                                    stripeId: data.stripeChargeId,
                                    stripeRefundId: data.stripeRefundId ? data.stripeRefundId : '-',
                                    refundDate: moment(data.refundedDateTime).format(dateStringFormat.dateTime),
                                    refundPercent: (data.refundedPercentage * 100) + "%",
                                    refunded: '$' + data.refundedAmount,
                                    status: await getRefundStatus(data.isRefunded, data.isFailed),
                                };
                                console.log(refunds);
                                refunds[index] = refund;
                                console.log(refunds);
                            }
                        }
                        else if (change.type === 'removed') {
                            const newRefunds = refunds.filter(refund => {
                                return refund.id !== change.doc.id;
                            })
                            refunds = [...newRefunds];
                        }
                    }))
                    dispatch({
                        type: GET_REFUND_DETAILS,
                        data: refunds
                    })
                })
        }
        catch (error)
        {
            dispatch({
                type: UPDATE_ERROR,
                data: error,
            })
        }
    }
}

export function showCancelPayoutForm(record: ICashOut): AppThunk {
    return dispatch => {
        dispatch({
            type: SHOW_CANCEL_PAYOUT_FORM,
            data: record,
        })
    }
}

export function hideCancelPayoutForm(): AppThunk {
    return dispatch => {
        dispatch({
            type: HIDE_CANCEL_PAYOUT_FORM,
            data: false,
        })
    }
}

export function showSettlePayout(record: ICashOut): AppThunk {
    return dispatch => {
        dispatch({
            type: SHOW_SETTLE_PAYOUT,
            data: record,
        })
    }
}

export function hideSettlePayout(): AppThunk {
    return dispatch => {
        dispatch({
            type: HIDE_SETTLE_PAYOUT,
            data: false,
        })
    }
}

export function updateCashOutStatus(id:string, status:number, reason?:string): AppThunk {
    return async dispatch => {
        if (Firebase === null)
        {
            dispatch({
                type: USERS_ERROR,
                data: null,
            });
        }

        try {
            const updateStatus = await FirebaseFunctions.httpsCallable('updatePayoutStatus');
            updateStatus({ payoutId: id, payoutStatus: status, cancellationReason: reason }).then(
                (result) => {
                    console.log(result);
                    dispatch({
                        type: UPDATE_CASHOUT_STATUS,
                        data: true,
                    })
                }
            ).catch(err => {
                console.log(err);
                dispatch({
                    type: UPDATE_ERROR,
                    data: err,
                })
            })
        }
        catch (error)
        {
            console.log(error);
        }
    }

}

export function retryRefund(id:string): AppThunk {
    return async dispatch => {
        if (Firebase === null)
        {
            dispatch({
                type: USERS_ERROR,
                data: null,
            });
        }
        try {
            const retry = await FirebaseFunctions.httpsCallable('retryRefund');
            retry({ refundId: id }).then(
                (result) => {
                    console.log(result);
                }
            ).catch(err => {
                console.log(err);
                dispatch({
                    type: UPDATE_ERROR,
                    data: err,
                })
            })
        } catch (e) {
            dispatch({
                type: UPDATE_ERROR,
                data: e,
            })
        }
    }

}

