import React from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Input, Button, Col, Row, Card, Form } from 'antd';
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { showResetForm, signIn } from "../store/actions/user"
import PasswordReset from "./PasswordReset";


interface IProps {
    user: {
        user: firebase.User,
        error: Error,
        admin: boolean,
        showResetForm: boolean
    }
    form: { getFieldDecorator, validateFields, resetFields },
    signIn: (username: string, password: string) => void,
    showResetForm :() => void,
}

interface IState {
    visible: boolean,
}

class Login extends React.Component <IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            visible: false
        }
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
        console.log(this.state.visible);
    };

    handleSubmit = (values) => {
        this.props.signIn(values.username, values.password);
    };


    render(): React.ReactNode {
        const { user, error, admin, showResetForm } = this.props.user;
        const formItemLayout = {
            labelCol: {
                xs: { span: 12 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 },
            },
        };
        if (user && admin === true ) {
            return <Redirect push to="/" />
        }
        return (
            <div>
                <Row align='middle' justify='center' style={{ paddingTop: '10px', paddingLeft: '35%', width: '475px', height: '100vh' }}>
                    <Col span={12} style={{ backgroundColor: 'white', alignItems: 'center' }}>
                        <Card style={{ width: '475px', paddingRight: '15px' }}>
                            <div className="logo-text">ALTRGO</div>
                            <div className="admin-panel">Admin Panel</div>
                            <br/>
                            <br/>
                            <Form onFinish={this.handleSubmit} {...formItemLayout} hideRequiredMark={true}>
                                <Form.Item
                                    label="Account"
                                    name="username"
                                    rules={[{ required: true, message: 'Please input your email!' }]}
                                >
                                    <Input
                                        prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        placeholder="Email"
                                    />
                                </Form.Item>

                                <Form.Item
                                    label="Password"
                                    name="password"
                                    rules={[{ required: true, message: 'Please input your password!' }]}
                                >
                                    <Input
                                        prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        type="password"
                                        placeholder="Password"
                                    />
                                </Form.Item>
                                <p style={{ textAlign: 'center', color: '#F68A5B' }}>{error}</p>
                                <div style={{ textAlign: "right", cursor: 'pointer', textDecoration: 'underline' }}  onClick={this.props.showResetForm}>
                                    Forgot password?
                                </div>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" className="login-form-button" style={{ marginLeft: '145px' }}>
                                        Log In
                                    </Button>
                                </Form.Item>

                            </Form>
                            <PasswordReset visible={showResetForm} />
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user
});
const mapDispatchToProps = (dispatch) =>  {
    return {
        signIn: (username: string, password:string) => {
            dispatch(signIn(username, password));
        },
        showResetForm: () => {
            dispatch(showResetForm());
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
