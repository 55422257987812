import { UserReport } from "../../components/reports/ReportTable";
import { GET_COMPLETED_REPORTS, GET_PENDING_REPORTS, SHOW_USER_REPORT } from "../actions/report";

export interface IReportState {
    reports: UserReport[],
    pendingReports: UserReport[],
    report: UserReport,
    showReport: boolean,
}

export const initialState:IReportState = {
    reports: [],
    pendingReports: [],
    report: {
        id: "",
        userId: "",
        user: "",
        title: "",
        location: "",
        date: "",
        status: null,
        action: "",
        description: "",
    },
    showReport: false,
}

export default (state = initialState, action: {type: string, data}): IReportState => {
    switch (action.type) {
        case GET_COMPLETED_REPORTS: {
            console.log('here');
            console.log(action.data);
            let reports = action.data.sort((a, b) => b.date - a.date);
            reports = reports.reverse();
            return {
                ...state,
                reports: reports,
            };
        }
        case GET_PENDING_REPORTS: {
            let reports = action.data.sort((a, b) => b.date - a.date);
            reports = reports.reverse();
            return {
                ...state,
                pendingReports: reports,
            };
        }
        case SHOW_USER_REPORT:
            return {
                ...state,
                report: action.data,
                showReport: !!action.data,
            }
        default:
            return {
                ...state,
            }
    }
}

