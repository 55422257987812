import React from 'react'
import { Card, Table, message, Tooltip, Tag } from 'antd';
import { RouteComponentProps, withRouter } from "react-router";
import { IExperienceState } from "../../store/reducers/experience";
import { getSessions } from "../../store/actions/experience";
import { connect } from "react-redux";
import { Status } from "./ExperienceDetails";
import { CopyToClipboard } from "react-copy-to-clipboard/";
import { IStoreState } from '../../store/IStoreState';

interface IProps extends RouteComponentProps {
    id: string,
    getSessions : (id:string) => void,
    experience : IExperienceState,
}

export interface Session {
    id: string,
    session: number,
    starts: string,
    ends: string,
    duration: string,
    linkedPackage: string,
    pax: string,
    status:Status,
}

const columns = [
    {
        title: 'ID',
        render: function render(record) {
            const success = () => {

                message.success('Session ID Copied!');
            };
            return (
                <CopyToClipboard text={record.id}><Tooltip placement="top" title={record.id}><Tag onClick={(e) => {e.stopPropagation(); success()}}>ID</Tag></Tooltip></CopyToClipboard>
            )
        }
    },
    {
        title: 'Duration',
        dataIndex: 'duration'
    },
    {
        title: 'Linked Package',
        dataIndex: 'linkedPackage'
    },
    {
        title: 'PAX',
        dataIndex: 'pax'
    },
]

class SessionsTable extends React.Component <IProps> {
    componentDidMount(): void {
        this.props.getSessions(this.props.id);
    }

    render(): React.ReactNode {
        const { sessions } = this.props.experience;

        return (
            <Card style={{ margin: '16px' }} headStyle={{ backgroundColor: '#F8F8F8' }} title={ sessions.length.toString() + " Session(s)"}>
                <Table columns={columns}
                    dataSource={sessions}
                    onRow={(record, index) => {
                        return {
                            onClick: () => {
                                this.props.history.push('/sessions/' + sessions[index].id);
                            }
                        }
                    }}
                />
            </Card>
        )
    }
}

const mapStateToProps = (state: IStoreState) => {
    return {
        ...state
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getSessions: (id:string) => dispatch(getSessions(id)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SessionsTable));