import React from 'react'
import { Card, Descriptions, Divider, Tag, Row, Col } from "antd";
import { RouteComponentProps, withRouter } from "react-router";
import { ITransactionState } from "../../store/reducers/transactions";
import { connect } from "react-redux";
import { getPayoutDetails } from "../../store/actions/transactions";

interface MatchParams {
    id: string,
}

interface MatchProps extends RouteComponentProps<MatchParams>{
    params: { id },
}

interface IProps {
    match: MatchProps,
    getPayoutDetails : (id:string) => void;
    transactions: ITransactionState,
}

const color = {
    Pending: '#F68A5B',
    Processing: '#F7C16A',
    Settled: '#2BA885',
    Cancelled: '#C4C4C4',
}

class CashOutDetail extends React.Component <IProps> {
    componentDidMount(): void {
        const { id } = this.props.match.params;
        this.props.getPayoutDetails(id);
    }

    render() {
        const { id } = this.props.match.params;
        const { record } = this.props.transactions;
        const extra = () => {
            return (
                <span><b>Status</b> &nbsp;<Tag color={color[record.status]}>{record.status}</Tag></span>
            )
        }
        return (
            <Card title={"Cash Out Details (" + id + ")"} style={{ margin: '16px 16px' }} headStyle={{ backgroundColor: '#F8F8F8' }}
                extra={extra()} >
                <div style={{ lineHeight: '40px' }}>
                    <Row gutter={16}>
                        <Col span={3} className='desc-label'> Cash Out Id:</Col>
                        <Col span={2} className='desc-item'>{record.id}</Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={3} className='desc-label'> User:</Col>
                        <Col span={2} className='desc-item'>{record.user}</Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={3} className='desc-label'> Method:</Col>
                        <Col span={2} className='desc-item'>{record.cashOutMethod}</Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={3} className='desc-label'> Account:</Col>
                        <Col span={2} className='desc-item'>{record.cashOutAccount}</Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={3} className='desc-label'> Cash Out Date:</Col>
                        <Col span={2} className='desc-item'>{record.cashOutDate}</Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={3} className='desc-label'> Completed Date:</Col>
                        <Col span={2} className='desc-item'>-</Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={3} className='desc-label'>Last Updated Date:</Col>
                        <Col span={2} className='desc-item'>{record.lastUpdated}</Col>
                    </Row>
                    <Divider/>
                    <Row gutter={16}>
                        <Col span={3} className='desc-label'> Cash Out Amount:</Col>
                        <Col span={2} className='desc-item'>{record.cashOut}</Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={3} className='desc-label'>Service Charge:</Col>
                        <Col span={2} className='desc-item'>{record.serviceFee}</Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={3} className='desc-label'>Net Amount:</Col>
                        <Col span={2} className='desc-item'>{record.final}</Col>
                    </Row>
                </div>
                <Divider />
                { (record.statusNo === 3) &&
                    <div>
                        <Descriptions column={1} layout="vertical">
                            <Descriptions.Item label="Cancellation Reason" >{record.cancellationReason}</Descriptions.Item>
                        </Descriptions>
                        <div style={{ lineHeight: '40px' }}>
                            <Row gutter={16}>
                                <Col span={2} className='desc-label'>
                                    Cancelled By:
                                </Col>
                                <Col span={2} className='desc-item'>
                                    {record.canceledBy}
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={2} className='desc-label'>
                                    Cancelled On:
                                </Col>
                                <Col span={2} className='desc-item'>
                                    {record.canceledDateTime}
                                </Col>
                            </Row>
                        </div>
                    </div>
                }
            </Card>
        )
    }
}

const mapStateToProps = (state : ITransactionState) => {
    return {
        ...state,

    }
}

const mapDispatchToProps = dispatch => {
    return ({
        getPayoutDetails: (id: string) => dispatch(getPayoutDetails(id)),
    })
}
/* eslint-disable  @typescript-eslint/no-explicit-any */
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CashOutDetail) as any);