import { Firebase, FirebaseDB, FirebaseStorage } from "../../lib/firebase";
import moment from 'moment'
import { IString } from "../../components/contentManagement/Strings";
import { FirestoreKey } from "../../constants/setting";
import { getAdminDisplayName } from "./admin";
import { AppThunk } from "../IStoreState";

export const SHOW_STRING_FORM = 'SHOW_STRING_FORM';
export const GET_STRINGS = 'GET_STRINGS';
export const FETCH_ERROR = 'FETCH_ERROR';
export const SAVE_STRING_FORM = 'SAVE_STRING_FORM';
export const SAVE_HTML = 'SAVE_HTML';
export const SUBMIT_HTML = 'SUBMIT_HTML';
export const GET_HTML = 'GET_HTML';
export const GET_URL = 'GET_URL';

async function stringData (id: string, data: firebase.firestore.DocumentData): Promise<IString> {
    const updatedBy : string =  data.lastUpdatedBy ? await getAdminDisplayName(data.lastUpdatedBy) : '';
    try {
        const string : IString = {
            key: id,
            languages: {
                en: data.en ? data.en : null,
                zh: data.zh ? data.zh : null,
            },
            lastUpdated: data.lastUpdatedDateTime ? moment(data.lastUpdatedDateTime).format() : '',
            updatedBy: updatedBy,
        }
        return string;
    } catch (e) {
        return null;
    }
}

export function getStrings(): AppThunk {
    return async dispatch => {
        if (Firebase === null) {
            dispatch({
                type: 'GET_STRINGS',
                data: null
            })
        }
        try {
            let strings: IString[] = [];
            const stringRef = await FirebaseDB.collection(FirestoreKey.Strings);
            await stringRef.onSnapshot(async snap => {
                await Promise.all(snap.docChanges().map(async change => {
                    if (change.type === "added") {
                        const index = strings.findIndex(e => {
                            return e.key === change.doc.id})
                        if (index === -1)
                        {
                            const data = change.doc.data();
                            const string = await stringData(change.doc.id, data);
                            strings.push(string);
                        }
                    } else if (change.type === "modified") {
                        const index = strings.findIndex(e => {
                            return e.key === change.doc.id})
                        if (index !== -1)
                        {
                            const data = change.doc.data();
                            const string = await stringData(change.doc.id, data);
                            strings[index] = (string);
                        }
                    } else if (change.type === "removed") {
                        const newStrings = strings.filter(e => {return e.key !== change.doc.id });
                        strings = [...newStrings];
                    }

                }));
                dispatch({
                    type: GET_STRINGS,
                    data: strings,
                })
            })
        } catch (e) {
            dispatch({
                type: 'FETCH_ERROR',
                data: e.message,
            })
        }
    }
}

export function saveStringForm (string:IString, id?:string): AppThunk {
    return async dispatch => {
        if (Firebase === null)
        {
            dispatch({
                type: FETCH_ERROR,
                data: null,
            })
        }
        try {
            dispatch({
                type: SAVE_STRING_FORM,
                data: true
            })
            const stringRef = await FirebaseDB.collection(FirestoreKey.Strings);
            if (!id) {
                console.log('No id');
                stringRef.doc(string.key).set({
                    en: string.languages.en ? string.languages.en : '',
                    zh: string.languages.zh ? string.languages.zh : '',
                    lastUpdatedBy: string.updatedBy ? string.updatedBy : '',
                }).then(() => {
                    dispatch({
                        type: SHOW_STRING_FORM,
                        data: {
                            id: null,
                            show: false,
                        },
                    })
                    dispatch({
                        type: SAVE_STRING_FORM,
                        data: false,
                    })
                }).catch(e => console.log(e.message))
            } else {
                stringRef.doc(id).update({
                    en: string.languages.en ? string.languages.en : '',
                    zh: string.languages.zh ? string.languages.zh : '',
                    lastUpdatedBy: string.updatedBy ? string.updatedBy : '',
                }).then(() => {
                    dispatch({
                        type: SHOW_STRING_FORM,
                        data: {
                            id: null,
                            show: false,
                        },
                    })
                    dispatch({
                        type: SAVE_STRING_FORM,
                        data: false,
                    })
                }).catch(e => console.log(e.message))
            }
        } catch (e) {
            console.log(e.message);
            dispatch({
                type: FETCH_ERROR,
                data: e.message,
            })
        }
    }
}

export function showStringForm(id?:string): AppThunk {
    return async dispatch => {
        dispatch({
            type: SHOW_STRING_FORM,
            data: {
                id: id ? id : null,
                show: true,
            }
        })
    }
}

export function hideStringForm(): AppThunk {
    return async dispatch => {
        dispatch({
            type: SHOW_STRING_FORM,
            data: {
                id: null,
                show: false,
            }
        })
        dispatch({
            type: SAVE_STRING_FORM,
            data: false,
        })
    }
}

export function saveHtml(type: string, data: string): AppThunk {
    return async dispatch => {
        if (Firebase === null)
        {
            dispatch({
                type: SAVE_HTML,
                data: false,
            })
        }
        try {
            dispatch({
                type: SUBMIT_HTML,
                data: true,
            })
            const storageRef = FirebaseStorage.ref('about');
            const metadata = {
                contentType: 'text/html',
            }
            const blob = new Blob([data], { type: 'text/html' });
            let newType = type.replace(/ /g, "_");
            newType = newType.toLowerCase();
            type = newType;
            storageRef.child(type + ".html").put(blob, metadata).then(() => {
                dispatch({
                    type: SUBMIT_HTML,
                    data: false,
                })
            }
            );

        } catch (e) {
            console.log(e.message);
            dispatch({
                type: SAVE_HTML,
                data: false,
            })

        }
    }
}

export function getHtml(type:string): AppThunk {
    return async dispatch => {
        if (Firebase === null)
        {
            dispatch({
                type: FETCH_ERROR,
                data: null
            })
        }
        try {
            const storageRef = await FirebaseStorage.ref("about");
            type = type.replace(/ /g, "_");
            type = type.toLowerCase();
            storageRef.child(type + ".html").getDownloadURL().then(url => {
                console.log(url);
                const xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                xhr.onload = function() {
                    const blob = xhr.response;
                    blob.text()
                        .then(res => {
                            type = type.replace(/_/g, " ");
                            if (type !== 'tnc')
                            {
                                type = type.toLowerCase()
                                    .split(' ')
                                    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                                    .join(' ');
                            } else type = type.toUpperCase();
                            dispatch({
                                type: GET_HTML,
                                data: {
                                    file: type,
                                    text: res,
                                }
                            });
                        }
                        );
                    console.log('loaded');
                };
                xhr.open('GET', url);
                xhr.send();
            }).catch(e => console.log(e.message));
        } catch (e) {
            console.log(e.message);
        }
    }
}

