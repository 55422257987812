import { Firebase, FirebaseDB, FirebaseFunctions } from "../../lib/firebase";
import { FirestoreKey } from "../../constants/setting";
import { IAdminUser } from "../../components/administration/AdminUsers";
import { AdminUser, AdminUserRole } from "../../models/admin";
import { AppThunk } from "../IStoreState";

export const GET_ADMIN_USERS = 'GET_ADMIN_USERS';
export const FETCH_ERROR = 'FETCH_ERROR';
export const USER_UPDATING = 'USER_UPDATING';

function adminUserObject (data:AdminUser, id:string) {
    const adminUser : IAdminUser = {
        id: id,
        user: data.email,
        role: AdminUserRole[data.role],
        roleNumber: data.role,
        name: data.displayName,
        lastLogin: '',
    }
    return adminUser;
}

export async function getAdminDisplayName(id:string) : Promise<string> {
    if (Firebase === null)
    {
        return null;
    }
    let name: string;
    const adminRef = await FirebaseDB.collection(FirestoreKey.AdminUsers).doc(id).get();
    try {
        const data = adminRef.data() as AdminUser;
        name = data.displayName ? data.displayName : null;
        return name;
    } catch (e) {
        return null;
    }

}

export function getAdminUsers(): AppThunk {
    return async dispatch => {
        if (Firebase === null) {
            dispatch({
                type: GET_ADMIN_USERS,
                data: null,
            })
        }
        try {
            let adminUsers : IAdminUser[] = [];
            const adminRef = await FirebaseDB.collection(FirestoreKey.AdminUsers);
            await adminRef.onSnapshot(snap => {
                snap.docChanges().forEach(change => {
                    const data = change.doc.data() as AdminUser;
                    if (change.type === "added") {
                        const index = adminUsers.findIndex(e => {
                            return e.id === change.doc.id });
                        if (index === -1)
                        {
                            const adminUser = adminUserObject(data, change.doc.id);
                            adminUsers.push(adminUser);
                        }
                    } else if (change.type === "modified") {
                        const index = adminUsers.findIndex(e => {
                            return e.id === change.doc.id });
                        if (index !== -1) {
                            const adminUser = adminUserObject(data, change.doc.id);
                            adminUsers[index] = adminUser;
                        }
                    } else if (change.type === "removed") {
                        const newUsers = adminUsers.filter(e => {
                            return e.id !== change.doc.id
                        });
                        adminUsers = [...newUsers];
                    }
                })
                dispatch({
                    type: GET_ADMIN_USERS,
                    data: adminUsers,
                })
            })
        } catch (e) {
            dispatch({
                type: FETCH_ERROR,
                data: e.message,
            })
        }
    }
}

export function updateAdminUser (id:string, roleInput:string): AppThunk {
    return async dispatch => {
        if (Firebase === null) {
            dispatch({
                type: FETCH_ERROR,
                data: null,
            })
        }
        try {
            dispatch({
                type: USER_UPDATING,
                data: {
                    id: id,
                    updating: true,
                }
            })
            const roleString = roleInput.replace(/ /g, '_');
            const roleNumber = AdminUserRole[roleString];
            console.log(roleNumber);
            const userUpdate = await FirebaseFunctions.httpsCallable('updateAdminUser');
            console.log(id);
            userUpdate({ userId: id, role: roleNumber }).then((result) => {
                console.log(result);
                dispatch({
                    type: USER_UPDATING,
                    data: {
                        id: id,
                        updating: false,
                    }
                })
            }).catch(e =>
            {
                console.log(e);
                dispatch({
                    type: USER_UPDATING,
                    data: {
                        id: id,
                        updating: false,
                    }
                })
            }
            );
        } catch (e) {
            dispatch({
                type: FETCH_ERROR,
                data: e.message,
            })
        }

    }
}