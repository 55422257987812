import React from 'react';
import { IExperienceState } from "../../store/reducers/experience";
import { getParticipants } from "../../store/actions/experience";
import { RouteComponentProps, withRouter } from "react-router";
import { connect } from "react-redux";
import { Card, Table } from "antd";
import { Link } from "react-router-dom";
import { IStoreState } from '../../store/IStoreState';

interface IProps extends RouteComponentProps {
    id: string,
    getParticipants : (id:string) => void,
    experience : IExperienceState,
}
export interface Participant {
    userId: string,
    user: string,
    join: string,
    payment: string,
    bundle: string,
    customization: string,
}
const columns = [
    {
        title: 'User',
        key: 'user',
        render: function render(record) {
            return (
                <Link to={"/users/" + record.userId} style={{ color: '#374E6B', textDecoration: 'underline', fontWeight: 'bolder' }}
                    onClick={(e) => { e.stopPropagation();}}> {record.user}</Link>
            )
        }
    },
    {
        title: 'Join Date',
        dataIndex: 'join'
    },
    {
        title: 'Payment',
        dataIndex: 'payment'
    },
    {
        title: 'Bundle',
        dataIndex: 'bundle'
    },
    {
        title: 'Customization',
        dataIndex: 'customization'
    },

]

class Participants extends React.Component<IProps> {
    componentDidMount(): void {
        this.props.getParticipants(this.props.id);
    }

    render(): React.ReactNode {
        const { participants } = this.props.experience;
        return (
            <Card style={{ margin: '16px' }} headStyle={{ backgroundColor: '#F8F8F8' }} title="Participant Record">
                <Table columns={columns}
                    dataSource={participants}
                />
            </Card>
        )
    }
}

const mapStateToProps = (state: IStoreState) => {
    return {
        ...state
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getParticipants: (id:string) => dispatch(getParticipants(id)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Participants));