import React from 'react'
import { IExperienceState } from "../../store/reducers/experience";
import { getSessionDetails } from "../../store/actions/experience";
import { connect } from "react-redux";
import { Badge, Card, Descriptions } from "antd";
import { Status } from './ExperienceDetails';
import { IStoreState } from '../../store/IStoreState';

interface IProps {
    id: string,
    getSessionDetails : (id:string) => void,
    experience: IExperienceState,
}

export interface SessionDetails {
    starts: string,
    ends: string,
    location: string,
    joined: number,
    max: number,
    min: number,
    status: Status,
}

type StatusType = "default" | "success" | "processing" | "error" | "warning";

class SessionDetail extends React.Component<IProps> {
    componentDidMount(): void {
        this.props.getSessionDetails(this.props.id);
    }

    render(): React.ReactNode {
        const { id } = this.props;
        const { sessionDetails } = this.props.experience;

        return (
            <Card style={{ margin: '16px' }} headStyle={{ backgroundColor: '#F8F8F8' }}
                extra={sessionDetails.status && <Badge status={sessionDetails.status.type as StatusType} text={sessionDetails.status.title} />}
                title={'Session Details(' + id + ')'}>
                <Descriptions column={1} layout="vertical">
                    <Descriptions.Item label="Starts">{sessionDetails.starts}</Descriptions.Item>
                    <Descriptions.Item label="Ends">{sessionDetails.ends}</Descriptions.Item>
                    <Descriptions.Item label="Location">{sessionDetails.location}</Descriptions.Item>
                    <Descriptions.Item label="Joined">{sessionDetails.joined}</Descriptions.Item>
                    <Descriptions.Item label="Max PAX ">{sessionDetails.max}</Descriptions.Item>
                    <Descriptions.Item label="Min PAX">{sessionDetails.min}</Descriptions.Item>
                </Descriptions>
            </Card>
        )
    }
}

const mapStateToProps = (state: IStoreState) => {
    return {
        ...state
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getSessionDetails: (id:string) => dispatch(getSessionDetails(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SessionDetail);