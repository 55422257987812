import React from 'react';
import { RouteComponentProps } from "react-router";
import ExperienceDetails from "./ExperienceDetails";
import SessionsTable from "./Sessions";
import BundlesTable from "./Bundles";
import PackagesTable from './Packages';

interface MatchParams {
    id: string,
}

interface MatchProps extends RouteComponentProps<MatchParams>{
    params: { id },
}

interface IProps {
    match: MatchProps,
}


class ExperiencePage extends React.Component <IProps> {
    render(): React.ReactNode {
        const { match } = this.props;
        const { id } = match.params;
        return (
            <div key={id}>
                <ExperienceDetails expId={id} />
                <PackagesTable id={id} />
                <SessionsTable id={id} />
                <BundlesTable id={id} />
            </div>
        )
    }

}
export default ExperiencePage;