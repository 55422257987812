import React from 'react';
import './App.css';
import { Provider } from "react-redux";
import Routes from "./routes";
import createStore from "./store/index"

class App extends React.Component {
    render(): React.ReactNode {
        return (
            <Provider store={createStore}>
                <Routes />
            </Provider >
        )
    }
}

export default App;