import React from 'react'
import { Card, Table, Dropdown, Menu } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { getExperienceHistories } from '../../../store/actions/users';
import { connect } from 'react-redux';
import { IUsersState } from "../../../store/reducers/users";
import { IStoreState } from '../../../store/IStoreState';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ActivitySession } from '../../../models/activity';
import moment from 'moment';
import { dateStringFormat } from '../../../constants/setting';

export interface ExperienceHistory {
    experienceId: string,
    experienceName: string,
    packageName: string,
    packageType: string,
    sessions: Array<ActivitySession>,
    bundleName: string,
    enrolledTime: string,
}

interface IProps extends RouteComponentProps {
    id: string,
    getExperienceHistories: (id: string) => void,
    users: IUsersState,
}

interface IState {
    loading: boolean,
    data: Array<ExperienceHistory>,
    received: boolean,
}

const columns: ColumnProps<ExperienceHistory>[] = [
    {
        title: 'Experience Name',
        dataIndex: 'experienceName',
    },
    {
        title: 'Package Name',
        dataIndex: 'packageName',
    },
    {
        title: 'Package Type',
        dataIndex: 'packageType',
    },
    {
        title: 'Enrolled session(s)',
        dataIndex: 'sessions',
        render: function render(sessions: Array<ActivitySession>) {
            return (
                <Dropdown placement="topLeft" overlay=
                {<Menu>
                    <Menu.ItemGroup title="Enrolled session(s)">
                        {sessions.map((element => {
                            if (element.startDateTime && element.endDateTime)
                                return <div>{moment(element.startDateTime).format(dateStringFormat.dateTime) + " - " + moment(element.endDateTime).format(dateStringFormat.dateTime)}</div>
                            else
                                return <div>No recorded start and end time</div>
                        }))}
                    </Menu.ItemGroup>
                </Menu>}>
                    <div>{sessions.length > 1 ? sessions.length.toString() + " Sessions" : sessions.length.toString() + " Session"}</div>
                </Dropdown>
            );
        }
    },
    {
        title: 'Bundle',
        dataIndex: 'bundleName',
    },
    {
        title: 'Enrolled On',
        dataIndex: 'enrolledTime',
    },

];

class ExperienceHistories extends React.Component <IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: [],
            received: false,
        }
    }

    componentDidMount(): void {
        this.props.getExperienceHistories(this.props.id);
    }

    componentDidUpdate(prevProps: Readonly<IProps>): void {
        if (prevProps.users.experienceHistories !== this.props.users.experienceHistories) {
            this.setState({ data: [...this.props.users.experienceHistories], loading: false });
        }
    }

    render() {
        return (
            <Card style={{ margin: '16px' }} title='Experience History' headStyle={{ backgroundColor: '#F8F8F8' }} bodyStyle={{ padding: '0' }}>
                <Table columns={columns}
                    style={{ border: "0px" }}
                    className="table"
                    dataSource={this.state.data}
                    rowKey={'experienceId'}
                    onRow={(record) => {
                        return {
                            onClick: () => {
                                this.props.history.push('/experiences/' + record.experienceId);
                            }
                        }
                    }}
                    pagination={{ pageSize: 4, hideOnSinglePage: true, style: { float: "left" } }}/>
            </Card>
        );
    }
}

const mapStateToProps = (state:IStoreState) => {
    return {
        ...state
    }
};

const mapDispatchToProps = (dispatch) =>  {
    return {
        getExperienceHistories: (id : string) => {
            return dispatch(getExperienceHistories(id));
        }
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ExperienceHistories));
