import React from 'react'
import { Card, message, Table, Tag, Tooltip } from 'antd';
import { RouteComponentProps, withRouter } from "react-router";
import { IStoreState } from "../../store/IStoreState";
import { IExperienceState } from "../../store/reducers/experience";
import { getBundles } from "../../store/actions/experience";
import { connect } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";

interface IProps extends RouteComponentProps {
    id: string,
    getBundles: (id:string) => void,
    experience?: IExperienceState,
}

export interface Bundle {
    id: string,
    bundle: string,
    bundleName: string,
    qty: number,
    price: number,
    remain: number,
    customisation: string,
}

const columns = [
    {
        title: 'ID',
        key: 'id',
        render: function render(record) {
            const success = () => {
                message.success('Bundle ID Copied!');
            };
            return (
                <CopyToClipboard text={record.id}><Tooltip placement="top" title={record.id}><Tag onClick={success}>ID</Tag></Tooltip></CopyToClipboard>
            );
        }
    },
    // {
    //     title: 'Bundle',
    //     dataIndex: 'bundle'
    // },
    {
        title: 'Bundle Name',
        dataIndex: 'bundleName'
    },
    {
        title: 'Qty',
        dataIndex: 'qty'
    },
    // {
    //     title: 'Price',
    //     dataIndex: 'price'
    // },
    {
        title: 'Remain',
        dataIndex: 'remain'
    },
    {
        title: 'Customisation',
        dataIndex: 'customisation'
    },
]


class BundlesTable extends React.Component <IProps> {
    componentDidMount(): void  {
        this.props.getBundles(this.props.id)
    }

    render(): React.ReactNode {
        const { bundles } = this.props.experience;
        return (
            <Card style={{ margin: '16px' }} headStyle={{ backgroundColor: '#F8F8F8' }} title="Bundles">
                <Table columns={columns}
                    dataSource={bundles}
                />
            </Card>
        )
    }
}

const mapStateToProps = (state: IStoreState) => {
    return {
        ...state,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getBundles: (id:string) => dispatch(getBundles(id)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BundlesTable));