import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Tooltip, Tag, message, Table, Menu, Dropdown, Button } from 'antd';
import { CopyToClipboard } from "react-copy-to-clipboard/";
import { Link } from "react-router-dom";
import { getAllReports, getPendingReports, showUserReport, updateUserReportStatus } from "../../store/actions/report";
import { connect } from 'react-redux';
import { IReportState } from "../../store/reducers/report";
import ReportDetail from "./ReportDetail";
import { IStoreState } from '../../store/IStoreState';

interface IProps{
    status:string,
    getAllReports?: () => void,
    getPendingReports?: () => void,
    updateUserReportStatus: (id:string, status:number) => void,
    showUserReport: (report: UserReport) => void,
    report?: IReportState,
}

export interface UserReport {
    id: string,
    userId: string,
    user: string,
    title: string,
    location: string,
    date: string,
    status:number,
    action: string,
    description:string,
}

class ReportTable extends React.Component <IProps> {
    componentDidMount(): void {
        if (this.props.status === 'all') {
            this.props.getAllReports();
        }
        else if (this.props.status === '0') {
            this.props.getPendingReports()
        }
    }

    render() {
        const { reports, pendingReports } = this.props.report;
        console.log(this.props);
        const columns = [
            {
                title: 'ID',
                key: 'id',
                render: record => {
                    const success = (event) => {
                        event.stopPropagation();
                        message.success('Report ID Copied!');
                    };
                    return (
                        <CopyToClipboard text={record.id}><Tooltip placement="top" title={record.id}><Tag onClick={success}>ID</Tag></Tooltip></CopyToClipboard>
                    )
                }
            },
            {
                title: 'User',
                key: 'user',
                render: record => {
                    return (
                        <Link to={"/users/" + record.userId} style={{ color: '#374E6B', textDecoration: 'underline', fontWeight: 'bolder' }}
                            onClick={(e) => { e.stopPropagation();}}>{record.user}</Link>
                    );
                }
            },
            {
                title: 'Title',
                key: 'title',
                dataIndex: 'title',

            },
            {
                title: 'Location',
                key: 'location',
                dataIndex: 'location',
            },
            {
                title: 'Date',
                key: 'date',
                dataIndex: 'date',
            },
            {
                title: 'Action',
                key: 'action',
                render: record => {
                    const colors = ['#F68A5B', '#F7C16A', '#2BA885', '#898989'];
                    const menu = (
                        <Menu style={{ textAlign: 'left' }}>
                            <Menu.Item key="0" style={{ color: '#F68A5B' }}>
                                <div onClick={(e) => { e.stopPropagation(); this.props.updateUserReportStatus(record.id, 0)}}> Pending</div>
                            </Menu.Item>
                            <Menu.Item key="1" style={{ color: '#F7C16A' }}>
                                <div onClick={(e) => { e.stopPropagation(); this.props.updateUserReportStatus(record.id, 1)}}> Processing</div>
                            </Menu.Item>
                            <Menu.Item key="2" style={{ color: '#2BA885' }}>
                                <div onClick={(e) => { e.stopPropagation(); this.props.updateUserReportStatus(record.id, 2)}}> Resolved</div>
                            </Menu.Item>
                            <Menu.Item key="3" style={{ color: '#898989' }}>
                                <div onClick={(e) => { e.stopPropagation(); this.props.updateUserReportStatus(record.id, 3)}}> Cancelled</div>
                            </Menu.Item>
                        </Menu>
                    );
                    return (
                        <Dropdown overlay={menu}>
                            <Button style={{ color: colors[record.status], width: '130px' }} onClick={(e) => { e.stopPropagation();}}>
                                {record.action} <DownOutlined />
                            </Button>
                        </Dropdown>
                    );

                }
            }
        ]

        return (
            <div>
                <ReportDetail />
                <Table columns={columns}
                    dataSource={(this.props.status === 'all') ? reports : pendingReports}
                    onRow={(record) => {
                        return {
                            onClick: (e) => {
                                e.stopPropagation();
                                this.props.showUserReport(record);
                            }
                        }
                    }
                    }
                />
            </div>
        );
    }
}

const mapStateToProps = (state:IStoreState) => {
    return {
        ...state
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAllReports: () => dispatch(getAllReports()),
        getPendingReports: () => dispatch(getPendingReports()),
        updateUserReportStatus: (id:string, status:number) => dispatch(updateUserReportStatus(id, status)),
        showUserReport: (record:UserReport) => dispatch(showUserReport(record)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportTable);