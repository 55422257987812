
export enum PayoutStatus {
    Pending,
    Processing,
    Settled,
    Cancelled,
}
export enum PayoutMethodType {
    Paypal,
    FPS,
    HSBC,
}

export interface Payout {
    id?: string;
    userId: string;
    payoutMethodId: string;
    payoutMethodType: PayoutMethodType;
    payoutMethodIdentifier: string;
    amount: number;
    netAmount: number;
    serviceFee: number;
    platformFee: number;
    currency: string;
    status: PayoutStatus;
    remark?: string;
    createdDateTime: number;
    settledDateTime?: number;
    settledBy?: string;
    cancellationReason?: string;
    canceledDateTime?: number;
    canceledBy?: string;
    lastUpdatedDateTime?: number;
    lastUpdatedBy?: string;
}
export interface PayoutMethod {
    id?: string;
    type: PayoutMethodType;
    isActive: boolean;
    createdDateTime: number;
    deactivatedDateTime: number;
}
export interface Paypal extends PayoutMethod {
    email: string;
}
export interface FPS extends PayoutMethod {
    phoneNumber: string;
}
export interface HSBC extends PayoutMethod {
    bankAccountNo: string;
}