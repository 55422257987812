import React from 'react';
import TextEditor from "./TextEditor";
import { RouteComponentProps, withRouter } from "react-router";

type IProps = RouteComponentProps

class Terms extends React.Component<IProps> {
    render(): React.ReactNode {
        return (
            <>
                <TextEditor type="Terms And Conditions" />
                <TextEditor type="Cancellation Policy" />
                <TextEditor type="Payment Policy" />
                <TextEditor type="Privacy Policy" />
            </>
        );
    }
}

export default withRouter(Terms);