import React from 'react'
import { Card, Descriptions, Tag, Badge, Modal, Tooltip, message } from 'antd';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { IExperienceState } from "../../store/reducers/experience";
import { IStoreState } from "../../store/IStoreState";
import { getExperienceDetails } from "../../store/actions/experience";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface IState {
    showCover: boolean,
}

interface IProps extends RouteComponentProps {
    expId: string,
    getExperienceDetails: (id: string) => void,
    experience: IExperienceState,
}

export interface Category {
    title: string,
    color: string
}

export interface Status {
    title: string,
    type: string,
}

type StatusType = "default" | "success" | "processing" | "error" | "warning";

export interface IExperienceDetails {
    type:string,
    typeColor: string,
    category: Category,
    cover: string,
    status: Status,
    task: string,
    title: string,
    rating: number,
    hero: string,
    heroId: string,
    createDate: string,
    lastUpdateDate: string,
    numberOfPackages: number,
    numberOfSessions:  number,
    numberOfPurchases: number,
    languages: string,
    launchDate: string,
    fee: number,
    create: string,
    expect: string,
    join: string,
    keywords: string[],
    isActive: boolean,
    include: string[],
}

class ExperienceDetails extends React.Component <IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            showCover: false,
        }
    }
    componentDidMount(): void {
        this.props.getExperienceDetails(this.props.expId)
    }

    showCover =  () => {
        this.setState({ showCover: true })
    }

    handleCancel = () => {
        this.setState({
            showCover: false,
        });
    };

    render() {
        const { expId } = this.props
        const { experience } = this.props.experience;

        const title = () => {
            return (
                <span>
                    Experience Details &nbsp;
                    <CopyToClipboard text={expId}>
                        <Tooltip placement="top" title={expId}>
                            <Tag onClick={(e) => {e.stopPropagation(); message.success('Experience ID Copied!');}}>ID</Tag>
                        </Tooltip>
                    </CopyToClipboard>&nbsp;
                    <Tag color={experience.typeColor}>{experience.type}</Tag>
                    {experience.category && <Tag color={experience.category.color}>{experience.category.title}</Tag>}
                    {experience.task && <Tag color='#898989'>{experience.task}</Tag>}
                </span>
            )
        }
        return (
            <Card title={title()} style={{ margin: '16px' }}
                extra={<span><span onClick={this.showCover} style={{ cursor: 'pointer', verticalAlign: 'middle', textDecoration: 'underline', fontWeight: 'bolder' }}>View Cover</span> &nbsp; &nbsp;
                    {experience.status && <Badge status={experience.status.type as StatusType} text={experience.status.title} />}</span>}
                headStyle={{ backgroundColor: '#F8F8F8' }}>
                <Modal visible={this.state.showCover} footer={null} centered closable={false} style={{ backgroundColor: 'transparent', width: '650px', padding: '5px' }} className="cover-img">
                    <p onClick={this.handleCancel} style={{ color: 'white', textDecoration: 'underline', float: 'right', cursor: 'pointer' }}>Close</p>
                    <img src={experience.cover} style={{ marginLeft: '-10px', width: '100%' }} alt=""/>
                </Modal>
                <Descriptions layout="vertical" column={3}>
                    <Descriptions.Item label='Title'>{experience.title}</Descriptions.Item>                    
                    <Descriptions.Item label='Create Date'>{experience.createDate}</Descriptions.Item>
                    <Descriptions.Item label='No. of Purchase(s)'>{experience.numberOfPurchases}</Descriptions.Item>
                    <Descriptions.Item label='Hero'><a onClick={()=>{this.props.history.push('/users/' + experience.heroId);}} href="#">{experience.hero}</a></Descriptions.Item>
                    <Descriptions.Item label='Last Update Date'>{experience.lastUpdateDate}</Descriptions.Item>
                    <Descriptions.Item label='Rating'>{experience.rating ? experience.rating : '-'}</Descriptions.Item>
                    <Descriptions.Item label='Languages'>{experience.languages}</Descriptions.Item>
                    {/* <Descriptions.Item label='Launch Date'>{experience.launchDate}</Descriptions.Item> */}
                    {/* <Descriptions.Item label='Fee'>HK$ {experience.fee}</Descriptions.Item> */}
                    {/* <Descriptions.Item label='Create'>{experience.create}</Descriptions.Item> */}
                    {/* <Descriptions.Item label='Activated'>
                        <span style={(experience.isActive === true) ? { color: 'green' } : { color: 'red' }}>{(experience.isActive === true) ? "Yes" : "No"}</span>
                    </Descriptions.Item> */}
                </Descriptions>
                <Descriptions layout="vertical" column={1}>
                    <Descriptions.Item label='No. of Package(s)'>{experience.numberOfPackages}</Descriptions.Item>
                    <Descriptions.Item label='No. of Session(s)'>{experience.numberOfSessions}</Descriptions.Item>
                    <Descriptions.Item label='What to Expect'>{experience.expect || '-'}</Descriptions.Item>
                    <Descriptions.Item label='Who should Join'>{experience.join || '-'}</Descriptions.Item>
                    <Descriptions.Item label='Include'>{experience.include || '-'}</Descriptions.Item>
                    <Descriptions.Item label='Keywords'>{experience.keywords || '-'}</Descriptions.Item>
                </Descriptions>
            </Card>
        )
    }
}

const mapStateToProps = (state: IStoreState) => {
    return {
        ...state
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getExperienceDetails: (id:string) => dispatch(getExperienceDetails(id)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ExperienceDetails));