import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Table, Tag, Dropdown, Menu, Button, message, Tooltip } from 'antd';
import { connect } from "react-redux";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { ITransactionState } from '../../store/reducers/transactions';
import { IStoreState } from '../../store/IStoreState';
import {
    getCashOut,
    getPendingCashOut,
    showCancelPayoutForm, showSettlePayout,
    updateCashOutStatus
} from "../../store/actions/transactions";
import CancelPayout from "./CancelPayout";
import SettlePayout from "./SettlePayout";
import { CopyToClipboard } from "react-copy-to-clipboard/";

interface IProps extends RouteComponentProps{
    status: string,
    getCashOut: () => void,
    updateCashOutStatus: (id, status, reason) => void,
    showCancelPayoutForm: (record:ICashOut) => void,
    showSettlePayout :(record: ICashOut) => void,
    getPendingCashOut: () => void,
    transactions?:ITransactionState,
}

interface IState {
    loading:boolean,
    allData: ICashOut[],
    pendingData : ICashOut[],
    status: string,
}

export interface ICashOut {
    id: string,
    userId: string,
    user : string,
    cashOutMethod: string,
    cashOutAccount: string,
    cashOut:string,
    final:string,
    cashOutDate:string,
    lastUpdated:string,
    status:string,
    serviceFee: string,
    statusNo: number,
    cancellationReason: string,
    canceledDateTime : string,
    canceledBy: string,
}

class CashOutTable extends React.Component <IProps, IState> {
    constructor(props) {
        super(props)
        this.state = this.initialState
    }

    get initialState() {
        return {
            loading: false,
            allData: [],
            pendingData: [],
            status: '',
        }
    }

    removeItem (record) {
        const records = this.state.pendingData.filter(oldRecord => {
            return oldRecord !== record;
        });
        this.setState({ pendingData: [...records]});
    }

    componentDidMount(): void {
        if (this.props.status !== "new") {
            this.props.getCashOut();
            this.setState({ allData: this.props.transactions.allCashOut })
        }
        else {
            this.props.getPendingCashOut();
            this.setState({ pendingData: this.props.transactions.pendingCashOut });
        }
        this.setState({ loading: true })
    }

    componentDidUpdate(prevProps: Readonly<IProps>): void {
        if (((prevProps.transactions !== this.props.transactions) ) )
        {
            if (this.props.status !== "new")
            {
                this.setState({ allData: this.props.transactions.allCashOut })
            }
            else {
                this.setState({ pendingData: this.props.transactions.pendingCashOut });
            }
        }
    }

    cancelCashOut(e, record) : void{
        e.stopPropagation();
        this.props.showCancelPayoutForm(record);
    }

    settleCashOut(e, record) : void {
        e.stopPropagation();
        this.props.showSettlePayout(record);
    }

    render() {
        const { cancelPayoutForm, record, settleStatus } = this.props.transactions;
        const { status } = this.props;
        const { allData } = this.state;
        const columns = [
            {
                title: 'ID',
                render: record => {
                    const success = () => {

                        message.success('Cash Out ID Copied!');
                    };
                    return (
                        <CopyToClipboard text={record.id}><Tooltip placement="top" title={record.id}><Tag onClick={(e) => {e.stopPropagation(); success()}}>ID</Tag></Tooltip></CopyToClipboard>
                    )
                }
            },
            {
                title: "User",
                key: 'user',
                render: (record) => {
                    return (
                        <Link to={"/users/" + record.userId} style={{ color: '#374E6B', textDecoration: 'underline', fontWeight: 'bolder' }}
                            onClick={(e) => { e.stopPropagation();}}> {record.user} </Link>
                    )
                }
            },
            {
                title: 'Cash Out Method',
                dataIndex: 'cashOutMethod',
            },
            {
                title: 'Cash Out Account',
                dataIndex: 'cashOutAccount',
            },
            {
                title: 'Cash Out',
                dataIndex: 'cashOut',
            },
            {
                title: 'Final',
                dataIndex: 'final',
            },
            {
                title: 'Cash Out Date',
                dataIndex: 'cashOutDate',
            },
            {
                title: 'Last Updated',
                dataIndex: 'lastUpdated',
            },
            {
                title: 'Status',
                key: 'status',
                render: record => {
                    const menu = (
                        <Menu style={{ textAlign: 'center' }}>
                            <Menu.Item key="1" style={{ color: '#F7C16A' }}>
                                <div onClick={(e) => { e.stopPropagation(); this.props.updateCashOutStatus(record.id, 1, ''); this.removeItem(record)}}> Processing</div>
                            </Menu.Item>
                            <Menu.Item key="2" style={{ color: '#2BA885' }}>
                                <div  onClick={(e) => {this.settleCashOut(e, record)}}>Settled</div>
                            </Menu.Item>
                            <Menu.Item key="3" style={{ color: '#C4C4C4' }}>
                                <div onClick={(e) => {this.cancelCashOut(e, record)}}>Cancelled</div>
                            </Menu.Item>
                        </Menu>
                    )
                    if (record.status === 'Processing' || record.status === 'Pending')
                    {
                        return (
                            <Dropdown overlay={menu}>
                                <Button style={{ color: (record.status === 'Processing') ? '#F7C16A' : '#F68A5B', width: '130px' }} onClick={(e) => { e.stopPropagation();}}>
                                    {record.status} <DownOutlined />
                                </Button>
                            </Dropdown>
                        );

                    }
                    let color = ''
                    switch (record.status)
                    {
                        case 'Processing':
                            color = '#F7C16A';
                            break;
                        case 'Settled':
                            color = '#2BA885';
                            break;
                        case 'Cancelled':
                            color = '#C4C4C4';
                    }
                    return <Button style={{ color: color, width: '130px' }}> {record.status} </Button>
                }
            },
        ];
        return (
            <div>
                { settleStatus && <SettlePayout visible={settleStatus} record={record}/> }
                { cancelPayoutForm && <CancelPayout visible={cancelPayoutForm} record={record}/> }

                <Table
                    columns={columns}
                    dataSource={(status === "new") ? this.props.transactions.pendingCashOut : allData}
                    pagination={{ pageSize: 10, style: { float: "left" } }}
                    rowKey='id'
                    onRow={(record) => {
                        return {
                            onClick: () => {
                                this.props.history.push({ pathname: '/cash_out/' + record['id'], state: { data: record } });
                            }
                        }
                    }}
                />

            </div>
        );
    }
}


const mapStateToProps = (state:IStoreState) => {
    return {
        ...state,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCashOut: () => dispatch(getCashOut()),
        getPendingCashOut: () => dispatch(getPendingCashOut()),
        updateCashOutStatus: (id, status, reason) => dispatch(updateCashOutStatus(id, status, reason)),
        showCancelPayoutForm: (record:ICashOut) => dispatch(showCancelPayoutForm(record)),
        showSettlePayout: (record: ICashOut) => dispatch(showSettlePayout(record)),
    }
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CashOutTable));