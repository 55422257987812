import { BrowserRouter, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import React from "react";

import { fetchUser } from "../store/actions/user";
import LoginForm from "../components/Login";
import AppBar from "../components/AppBar";
import PasswordResetForm from "../components/PasswordReset";
import Users from "../components/users/users";
import UserTraveller from "../components/users/UserTraveller";
import UserHero from "../components/users/UserHero";
import CashOut from "../components/transactions/CashOut";
import CashOutDetail from "../components/transactions/CashOutDetail";
import Experiences from "../components/experiences/Experience";
import ExperiencePage from "../components/experiences/ExperiencePage";
import SessionPage from "../components/experiences/SessionPage";
import OutstandingRefund from "../components/transactions/OutstandingRefund";
import { initFirebaseApp } from "../lib/firebase";
import AllRewards from "../components/rewards/AllRewards";
import Redemption from "../components/rewards/Redemption";
import Report from "../components/reports/Report";
import AdminUsers from "../components/administration/AdminUsers";
import Strings from "../components/contentManagement/Strings";
import Terms from "../components/contentManagement/Terms";
import Dashboard from "../components/Dashboard"


interface IState {
    isFirebaseInitialized: boolean,
}

interface IProps {
    fetchUser: () => void;
}

class Routes extends React.Component<IProps, IState> {

    constructor(props) {
        super(props);
        this.state = {
            isFirebaseInitialized: false,
        };
    }

    UNSAFE_componentWillMount() {
        initFirebaseApp()
            .then(this.props.fetchUser)
            .then(() => this.setState({ isFirebaseInitialized: true }));
    }

    render() {
        console.log(`${process.env.PUBLIC_URL}`);
        return ( this.state.isFirebaseInitialized ?
            <BrowserRouter basename={`${process.env.PUBLIC_URL}/`}>
                <Switch>
                    <Route exact path="/login" component={LoginForm} />
                    <Route exact path="/password-reset" component={PasswordResetForm} />
                    <AppBar>
                        <Route exact path="/" component={Dashboard} />
                        <Route exact path="/users" component={Users} />
                        <Route exact path="/users/:id" component={UserTraveller} />
                        <Route exact path="/hero/:id" component={UserHero} />
                        <Route exact path="/cash_out" component={CashOut} />
                        <Route exact path="/cash_out/:id" component={CashOutDetail} />
                        <Route exact path="/outstanding_refund" component={OutstandingRefund} />
                        <Route exact path="/experiences" component={Experiences} />
                        <Route exact path="/experiences/:id" component={ExperiencePage} />
                        <Route exact path="/sessions/:id" component={SessionPage} />
                        <Route exact path="/rewards" component={AllRewards} />
                        <Route exact path="/redemption" component={Redemption} />
                        <Route exact path="/report" component={Report} />
                        <Route exact path="/admin_users" component={AdminUsers} />
                        <Route exact path="/strings" component={Strings} />
                        <Route exact path="/terms" component={Terms} />
                    </AppBar>
                </Switch>
            </BrowserRouter> : null
        )
    }
}

export default connect(
    null,
    { fetchUser }
)(Routes);

