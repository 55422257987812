import React from 'react';
import { RouteComponentProps, withRouter } from "react-router";
import UserInfo from "./traveller/UserInfo";
import UserProfile from "./traveller/UserProfile";
import RefundRecord from "./traveller/RefundRecord";
import PaymentRecordComponent from "./traveller/PaymentRecord";
import ExperienceHistories from './traveller/ExperienceHistories';

interface RouteParams {
    id: string,
}

class UserTraveller extends React.Component <RouteComponentProps<RouteParams>> {
    render(): React.ReactNode {
        const { match } = this.props;
        const { id } = match.params;
        return (
            <div key={id}>
                <UserInfo id={id} />
                <UserProfile id={id} />
                {/* <Journeys id={id} /> */}
                <ExperienceHistories id={id} />
                {/* <PaymentMethod id={id} /> */}
                <PaymentRecordComponent id={id} />
                <RefundRecord id={id} />
            </div>
        )
    }

}

export default withRouter(UserTraveller);