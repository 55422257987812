import React from 'react'
import { Card, Table, Tag } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { IUsersState } from "../../../store/reducers/users";
import { getCashoutRecord } from "../../../store/actions/users";
import { connect } from "react-redux";
import { PayoutStatusColor } from "../../../models/color";
import { IStoreState } from '../../../store/IStoreState';

export interface CashOutRecord {
    id: string,
    type: string,
    account: string,
    cashOutDate: string,
    amount: string,
    status: string,
    statusNo: number,
}

const columns: ColumnProps<CashOutRecord>[] = [
    {
        title: 'ID',
        dataIndex: 'id',
    },
    {
        title: 'Type',
        dataIndex: 'type',
    },
    {
        title: 'Account',
        dataIndex: 'account',
    },
    {
        title: 'Cash Out date',
        dataIndex: 'cashOutDate',
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
    },
    {
        title: 'Status',
        key: 'status',
        render: function render(record) {
            return (<Tag color={PayoutStatusColor[record.statusNo]}> {record.status} </Tag>)
        }
    },

];

interface IProps {
    id: string,
    getCashoutRecord : (id : string) => void,
    users: IUsersState,
}

class CashOutRecordComponent extends React.Component <IProps> {
    componentDidMount(): void {
        this.props.getCashoutRecord(this.props.id);
    }

    render() {
        const { cashOutRecord } = this.props.users;
        return (
            <Card style={{ margin: '16px' }} title='Cash Out Record' headStyle={{ backgroundColor: '#F8F8F8' }} bodyStyle={{ padding: '0' }}>
                <Table columns={columns}
                    dataSource={cashOutRecord} size="middle"
                    className="table"
                    bordered={false}
                    pagination={{ pageSize: 4, hideOnSinglePage: true, style: { float: "left" } }}/>
            </Card>
        );
    }
}

const mapStateToProps = (state:IStoreState) => {
    return {
        ...state
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getCashoutRecord: (id: string) => {
            return dispatch(getCashoutRecord(id))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(CashOutRecordComponent)
