import React from 'react';
import { Card, Table, Tag, Tooltip, message } from 'antd';
import { connect } from "react-redux";
import { getUsers } from "../../store/actions/users";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { IStoreState } from '../../store/IStoreState';

interface IProps extends RouteComponentProps{
    users?: {
        users: Array<IUser>,
        error?: string,
    }
    display: boolean,
    getUsers? : (ids: string[]) => void,
    ids: string[],
}

interface IState {
    display: boolean,
}

export interface IUser {
    id: string,
    phoneNumber: string,
    email : string,
}

const columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        render: function render(id) {
            const success = () => {
                message.success('User ID Copied!');
            };
            return (
                <CopyToClipboard text={id}><Tooltip placement="top" title={id}><Tag onClick={(e) => {e.stopPropagation(); success()}}>ID</Tag></Tooltip></CopyToClipboard>
            );
        }
    },
    {
        title: "User's Email",
        dataIndex: 'email',
        key: 'email',
    },
    {
        title: 'Phone number',
        dataIndex: 'phoneNumber',
        key: 'phoneNumber'
    },
];

class UsersList extends React.Component <IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            display: false,
        }
    }

    UNSAFE_componentWillUpdate(nextProps: Readonly<IProps>): void {
        if (nextProps.ids !== this.props.ids)
        {
            this.props.getUsers(nextProps.ids);
        }
    }

    componentDidMount(): void {
        this.props.getUsers(this.props.ids)
    }

    render() {
        const { users } = this.props.users;
        const usersCopy = users.map(user => user)
        console.log(this.props);
        return (
            <Card title='Search Result' headStyle={{ backgroundColor: '#F8F8F8', color: '#323C47', fontSize: '15px' }} bodyStyle={{ padding: "0" }}>
                <Table
                    columns={columns}
                    dataSource={usersCopy}
                    pagination={{ pageSize: 10, style: { float: "left" } }}
                    rowKey={record => record.id}
                    onRow={(record) => {
                        return {
                            onClick: () => {
                                this.props.history.push('/users/' + record.id);
                            }
                        }
                    }}
                />
            </Card>
        );
    }
}


const mapStateToProps = (state: IStoreState)  => {
    return {
        ...state
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUsers: (ids : string[]) => dispatch(getUsers(ids)),
    }
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps )(UsersList));