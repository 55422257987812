import React from 'react'
import { Layout, Menu, Breadcrumb, Divider } from 'antd';
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    DashboardOutlined,
    CreditCardOutlined,
    UserOutlined,
    InteractionOutlined,
    GiftOutlined,
    SnippetsOutlined,
    ContainerOutlined
} from '@ant-design/icons';
import { Link, Redirect, withRouter, RouteComponentProps, matchPath } from "react-router-dom";
import { signOut } from "../store/actions/user";
import { connect } from "react-redux";
import moment from 'moment';
import ModeButton from "./users/ModeButton";
import ExperienceActions from "./experiences/ExperienceActions";
import { dateStringFormat } from "../constants/setting";
import { IStoreState } from '../store/IStoreState';

const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;

const sideBarItems = [
    {
        text: 'Dashboard',
        route: '/',
        icon: <DashboardOutlined />
    },
    {
        text: 'Transactions',
        icon: <CreditCardOutlined />,
        children: [
            {
                text: 'Outstanding Refund',
                route: '/outstanding_refund',
                icon: '',
            },
            {
                text: 'Cash out',
                route: '/cash_out',
                icon: '',
            }
        ]
    },
    {
        text: 'Users',
        route: '/users',
        icon: <UserOutlined />
    },
    {
        text: 'Experience',
        route: '/experiences',
        icon: <InteractionOutlined />
    },
    {
        text: 'Reward',
        route: '/reward',
        icon: <GiftOutlined />,
        children: [
            {
                text: 'All Reward',
                route: '/rewards',
                icon: ''
            },
            {
                text: 'Redemption',
                route: '/redemption',
                icon: '',
            }
        ]
    },
    {
        text: 'Report',
        route: '/report',
        icon: <SnippetsOutlined />
    },
    {
        text: 'Administration',
        icon: <UserOutlined />,
        children: [
            {
                text: 'Admin Users',
                route: '/admin_users',
                icon: '',
            }
        ]
    },
    {
        text: 'Content Management',
        icon: <ContainerOutlined />,
        children: [
            {
                text: 'Strings',
                route: '/strings',
                icon: ''
            },
            {
                text: 'Terms',
                route: '/terms',
                icon: '',
            }
        ]
    },

];

interface IState {
    collapsed: boolean,
    heroButton: boolean,
    travellerButton: boolean,
    idPush: string,
    userBtn: boolean,
    experienceBtn: boolean,
}

interface IProps extends RouteComponentProps {
    user: {
        user: firebase.User,
        admin: boolean,
        error: string,
        userLoading: boolean,
    },
    children: React.Component,
    signOut: () => void,
    getHeroStatus: (id) => void,
}

class AppBar extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: false,
            heroButton: false,
            travellerButton: false,
            idPush: ' ',
            userBtn: false,
            experienceBtn: false,
        };
    }

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    componentDidMount(): void {
        const { pathname } = this.props.location;
        const user = matchPath(pathname, {
            path: "/users/",
            exact: false,
            strict: false,
        });
        const hero = matchPath(pathname, {
            path: "/hero/",
            exact: false,
            strict: false,
        });
        const experience = matchPath(pathname, {
            path: "/experiences/",
            exact: false,
            strict: false,
        })
        if (user || hero) {
            this.setState({ userBtn: true, experienceBtn: false })

        }
        else {
            this.setState({ userBtn: false })
        }

        if (experience) {
            this.setState({ experienceBtn: true, userBtn: false });
        }

    }

    componentDidUpdate(prevProps: Readonly<IProps>): void {
        const { pathname } = this.props.location;
        if (prevProps.location.pathname !== pathname) {
            console.log("updating");
            const user = matchPath(pathname, {
                path: "/users/",
                exact: false,
                strict: false,
            });
            const hero = matchPath(pathname, {
                path: "/hero/",
                exact: false,
                strict: false,
            });
            const experience = matchPath(pathname, {
                path: "/experiences/",
                exact: false,
                strict: false,
            })
            if (user || hero) {
                this.setState({ userBtn: true, experienceBtn: false })

            }
            else if (experience) {
                this.setState({ experienceBtn: true, userBtn: false });
            }
            else {
                this.setState({ userBtn: false })
            }
        }
    }


    render(): React.ReactNode {
        const { user, admin, userLoading } = this.props.user;
        const { children, location } = this.props;
        const { pathname } = this.props.location;
        const baseLength = process.env.PUBLIC_URL.length;
        const currentRoute = location.pathname.slice(baseLength);
        const { userBtn, experienceBtn } = this.state;
        const { lastSignInTime } = (admin && user !== null) ? user.metadata : { lastSignInTime: '' };
        const { email, displayName } = (admin && user !== null) ? user : { email: '', displayName: '' };
        const path = pathname.split('/');
        path.shift();
        const pathLen = path.length;
        const lastSignIn = lastSignInTime ? moment(lastSignInTime).format("HH:mm " + dateStringFormat.date) : "";
        return <>
            {(user !== null && admin) &&
                <Layout style={{ height: '100vh', }}>
                    <>
                        <Sider
                            width={250}
                            trigger={null}
                            collapsible
                            collapsed={this.state.collapsed}
                            style={{ background: 'white' }}
                        >
                            <div id="custom_sider_body">
                                <div id="custom_sider_header">
                                    <p className="logo" hidden={this.state.collapsed}>ALTRGO</p>
                                    {
                                        this.state.collapsed ? <MenuUnfoldOutlined type="menu-unfold" onClick={this.toggle} className="trigger-collapse" /> : <MenuFoldOutlined type="menu-fold" onClick={this.toggle} className="trigger" />
                                    }
                                </div>
                                <div id="custom_sider_menu">
                                    <Menu theme="light" mode="inline" defaultSelectedKeys={[currentRoute]}
                                        selectedKeys={[currentRoute]}
                                        inlineCollapsed={this.state.collapsed}
                                        style={{ display: "flex", flexDirection: "column" }}
                                    >
                                        {
                                            sideBarItems.map(item => {
                                                if (item.children) {
                                                    return (
                                                        <SubMenu key={item.text}
                                                            title={<span>{item.icon}{!this.state.collapsed ? item.text : ''}</span>}>
                                                            {
                                                                item.children.map(child => (
                                                                    <Menu.Item key={child.route}>
                                                                        <Link to={child.route}>
                                                                            <span>{child.text}</span>
                                                                        </Link>
                                                                    </Menu.Item>
                                                                ))
                                                            }
                                                        </SubMenu>
                                                    );
                                                }
                                                else {
                                                    return (
                                                        <Menu.Item key={item.route} title={item.text}>
                                                            <Link to={item.route}>
                                                                {item.icon}
                                                                <span hidden={this.state.collapsed}> {item.text} </span>
                                                            </Link>
                                                        </Menu.Item>
                                                    );
                                                }
                                            })
                                        }
                                    </Menu>
                                </div>
                                <div id="custom_sider_footer" hidden={this.state.collapsed}>
                                    <Divider style={{ margin: 0 }} />
                                    <div style={{ padding: 24 }}>
                                        <b style={{ fontSize: "14px" }}>{displayName}</b>
                                        <p style={{ color: '#90A0B7', fontSize: "11px" }}>{email}</p>
                                        <Link
                                            to='/login'
                                            onClick={() => {
                                                this.props.history.push('/login');
                                                this.props.signOut();
                                            }}
                                            style={{ textDecoration: 'underline', color: '#F68A5B', fontSize: '11px' }}
                                        >
                                            Log Out
                                        </Link>
                                        <div style={{ color: '#898989', fontSize: '10px' }}>
                                            last log in {lastSignIn}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Sider>
                        <Layout className="body">
                            <Header className="header">
                                <Breadcrumb className="breadcrumb">
                                    <Breadcrumb.Item key="dashboard"><Link to="/">Dashboard</Link></Breadcrumb.Item>
                                    {path.map(item => {
                                        const arr = item.split("");
                                        if (arr[0]) {
                                            arr[0] = arr[0].toUpperCase();
                                            let underScore = arr.findIndex(char => char === "_");
                                            if (underScore !== -1) {
                                                arr[underScore++] = " ";
                                                arr[underScore] = arr[underScore].toUpperCase();
                                            }
                                            if (item === path[pathLen - 1]) {
                                                item = arr.join("");
                                                return <Breadcrumb.Item key={item}><Link to={pathname}>{item}</Link></Breadcrumb.Item>
                                            }
                                            else {
                                                const index = path.findIndex(i => i === item);
                                                let i = 0;
                                                let linkPath = "/";
                                                while (i <= index) {
                                                    linkPath += path[i] + "/";
                                                    i++;
                                                }
                                                item = arr.join("");
                                                return <Breadcrumb.Item key={item}><Link to={linkPath}>{item}</Link></Breadcrumb.Item>
                                            }
                                        }
                                        return "";

                                    })}
                                </Breadcrumb>
                                {userBtn && <ModeButton />}
                                {experienceBtn && <ExperienceActions />}
                            </Header>
                            <Content className="content">
                                {children}
                            </Content>
                        </Layout>
                    </>
                </Layout>}
            {(admin !== true || user === null) && !userLoading && <Redirect exact to="/login" />}
        </>;
    }

}

const mapStateToProps = (state: IStoreState) => {
    return {
        ...state
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => {
            return dispatch(signOut());
        }
    }
};
/* eslint-disable  @typescript-eslint/no-explicit-any */
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppBar) as any);