import React from 'react';
import { Button, Drawer, Input, Form } from "antd";
import { ICMState } from "../../store/reducers/contentManagement";
import { IStoreState } from "../../store/IStoreState";
import { hideStringForm, saveStringForm } from "../../store/actions/contentManagement";
import { connect } from "react-redux";
import { IString } from "./Strings";
import { FormInstance } from 'antd/lib/form';
import { IUserState } from '../../store/reducers/user';

const { TextArea } = Input;

interface IProps {
    hideStringForm: () => void,
    saveStringForm : (string:IString, id?:string) => void,
    contentManagement: ICMState,
    user: IUserState
}

let string;

class StringForm extends React.Component<IProps> {
    formRef = React.createRef<FormInstance>();
    handleSubmit = (values) => {
        const { id } = this.props.contentManagement.stringForm;
        console.log('Submitting');
        const String : IString = {
            key: values.key,
            languages: {
                en: values.en,
                zh: values.zh
            },
            updatedBy: this.props.user.user.uid
        }
        console.log(String);
        if (!id) {
            console.log('saving');
            this.props.saveStringForm(String);
        } else {
            this.props.saveStringForm(String, id);
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>): void {
        // console.log(this.formRef);
        const { id, show } = this.props.contentManagement.stringForm;
        const { strings } = this.props.contentManagement;
        if (prevProps.contentManagement.stringForm.show !== show)
        {
            if (this.formRef.current) {
                if (this.props.contentManagement.stringForm.show && id != null) {
                    const index = strings.findIndex(e => {
                        return e.key === id
                    })
                    string = strings[index];
                    this.formRef.current.setFieldsValue({
                        key: id,
                        en: string.languages.en ? string.languages.en : '',
                        zh: string.languages.zh ? string.languages.zh : '',
                    })
                }
                else {
                    this.formRef.current.resetFields();
                }
            }
        }
    }

    render(): React.ReactNode {
        const { show, id } = this.props.contentManagement.stringForm;
        const { loading } = this.props.contentManagement;
        return (
            <div>
                <Drawer title="Items"
                    width={500}
                    onClose={() => {this.props.hideStringForm(); this.formRef.current.resetFields();}}
                    visible={show}>
                    <Form layout="vertical" hideRequiredMark id="rewardForm" ref={this.formRef} onFinish={this.handleSubmit}>
                        <Form.Item
                            label="Key"
                            name="key"
                            rules={[{ required: true, message: 'Please enter a key' }]}
                        >
                            <Input placeholder="key" disabled={!!id || loading} />
                        </Form.Item>
                        <Form.Item
                            label="en"
                            name="en"
                            rules={[{ required: false }]}
                        >
                            <TextArea placeholder="en" disabled={loading} />
                        </Form.Item>
                        <Form.Item
                            label="zh"
                            name="zh"
                            rules={[{ required: false }]}
                        >
                            <TextArea placeholder="zh" disabled={loading} />
                        </Form.Item>
                        <div
                            style={{
                                position: 'absolute',
                                left: 0,
                                bottom: 0,
                                width: '100%',
                                borderTop: '1px solid #e9e9e9',
                                padding: '10px 16px',
                                background: '#fff',
                                textAlign: 'right',
                            }}
                        >
                            <Button onClick={() => {this.props.hideStringForm(); this.formRef.current.resetFields();}} style={{ marginRight: 8 }}>
                                Cancel
                            </Button>
                            <Button form="rewardForm" type="primary" htmlType="submit" loading={loading}>
                                Save
                            </Button>
                        </div>
                    </Form>
                </Drawer>
            </div>
        )
    }
}

const mapStateToProps = (state: IStoreState) => {
    return {
        ...state
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        hideStringForm: () => dispatch(hideStringForm()),
        saveStringForm: (string:IString, id?:string) => dispatch(saveStringForm(string, id)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(StringForm);
