export enum AdminUserRole {
    Root = 0,

    IT_Head = 10,
    IT_Developer = 11,

    Operation_Head = 20,
    Operation_Staff = 21,

    Marketing_Head = 30,
    Marketing_Staff = 31,

    HR_Head = 40,
    HR_Staff = 41,
}
export interface AdminUser {
    role: AdminUserRole;
    email: string;
    displayName?: string;
}