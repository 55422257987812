import {
    GET_ALL_REWARDS,
    GET_REDEMPTIONS, REDEMPTION_DETAILS,
    REDEMPTION_FORM,
    REWARD_DETAILS,
    REWARD_FORM, SUBMIT_PROCESSING,
    SUBMIT_REWARD
} from "../actions/rewards";
import { IReward } from "../../components/rewards/AllRewards";
import { IRedemption } from "../../components/rewards/Redemption";
import { RewardRedemptionCode } from "../../models/rewardRedemptionCode";

export interface IRewardState {
    rewards:IReward[],
    redemptions: IRedemption[],
    rewardForm: {
        show:boolean,
        id?:string,
    },
    redemptionForm:{
        show:boolean,
        id?:string,
    },
    rewardId: string,
    reward: {
        id: string,
        type: number;
        userRole: number,
        isActive: boolean,
        isIssueOnReg: boolean,
        title: string,
        description: string,
        redeem: boolean,
        expireDateTime: number,
        quantity: number,
        remaining: number,
        recordValidityPeriod: number,
        expire: boolean,
        recordExpireDateTime: number,
        coverUrl: string,
        detailsUrl: string,
        discount: number,
        recommendation: string[],
        capacity: string,
        website: string,
        originalPrice: number,
        location: string,
        tnc: string,
        code: string,
        url: string
    },
    redemption: RewardRedemptionCode,
    rewardIds:Array<string>,
    loading: boolean,
}

export const initialState : IRewardState = {
    rewards: [],
    redemptions: [],
    rewardForm: {
        show: false,
        id: ''
    },
    redemptionForm: {
        show: false,
        id: ''
    },
    rewardId: '',
    reward: {
        id: null,
        type: null,
        userRole: null,
        isActive: null,
        isIssueOnReg: null,
        title: null,
        description: null,
        redeem: null,
        expireDateTime: null,
        quantity: null,
        remaining: null,
        recordValidityPeriod: null,
        expire: null,
        recordExpireDateTime: null,
        coverUrl: null,
        detailsUrl: null,
        discount: null,
        recommendation: [],
        capacity: null,
        website: null,
        originalPrice: null,
        location: null,
        tnc: null,
        code: null,
        url: null
    },
    redemption: {
        expireDate: null,
        id: null,
        type: null,
        rewardId: null,
        expireDateTime: null,
        isActive: null,
        isUsed: null,
        usedDateTime: null,
        amount: null,
        usedCount: null,
        isRepeatable: null,
    },
    rewardIds: [],
    loading: false,
}

export default (state = initialState, action: {type: string, data}): IRewardState => {
    switch (action.type) {
        case GET_ALL_REWARDS:
            return {
                ...state,
                rewards: action.data.rewards,
                rewardIds: action.data.rewardIds,
            };
        case GET_REDEMPTIONS:
            return {
                ...state,
                redemptions: action.data,
            }
        case REWARD_FORM:
            return {
                ...state,
                rewardForm: action.data,
                loading: !action.data,
            }
        case REDEMPTION_FORM:
            return {
                ...state,
                redemptionForm: action.data,
                loading: !action.data,
            }
        case SUBMIT_PROCESSING:
            return {
                ...state,
                loading: action.data,
            }
        case SUBMIT_REWARD:
            return {
                ...state,
                rewardId: action.data,
            }
        case REWARD_DETAILS:
            return {
                ...state,
                reward: action.data,
            }
        case REDEMPTION_DETAILS:
            return {
                ...state,
                redemption: action.data,
            }
        default: {
            return {
                ...state,
            }
        }
    }
}