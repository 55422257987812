import { FirebaseAuth, Firebase } from "../../lib/firebase";
import { AppThunk } from "../IStoreState";

export const FETCH_USER = "FETCH_USER";
export const LOGIN_USER_FAIL = "LOGIN_USER_FAIL";
export const ADMIN_USER_FAIL = "ADMIN_USER_FAIL";
export const ADMIN_USER_LOGIN = "ADMIN_USER_LOGIN";
export const RESET_ERROR = "RESET ERROR";
export const SIGN_OUT_FAIL = "SIGN_OUT_FAIL";
export const PASSWORD_RESET = "PASSWORD_RESET";
export const SHOW_RESET_FORM = "SHOW_RESET_FORM";
export const HIDE_RESET_FORM = "HIDE_RESET_FORM"
export const USER_LOADING = "USER_LOADING";


export function fetchUser(): AppThunk {
    return dispatch => {
        FirebaseAuth.onAuthStateChanged((user) => {
            if (user !== null)
            {
                user.getIdTokenResult().then(
                    (idTokenResult) => {
                        if (idTokenResult.claims.admin) {
                            dispatch({
                                type: FETCH_USER,
                                data: {
                                    user: user,
                                    admin: true
                                }
                            })
                            dispatch({
                                type: USER_LOADING,
                                data: false,
                            })
                        }
                    }
                )
            }
            else {
                dispatch({
                    type: USER_LOADING,
                    data: false,
                })
            }
        });
    };
}

export function signIn (email: string, password:string): AppThunk {
    return async dispatch => {
        dispatch({
            type: RESET_ERROR,
            data: null
        });

        try {
            FirebaseAuth.signInWithEmailAndPassword(email, password).then(
                () => {
                    if (FirebaseAuth.currentUser !== null)
                    {
                        FirebaseAuth.currentUser.getIdTokenResult().then((idTokenResult) => {
                            if (!idTokenResult.claims.admin) {
                                dispatch({
                                    type: ADMIN_USER_FAIL,
                                    data: false,
                                });
                                dispatch(({
                                    type: USER_LOADING,
                                    data: false,
                                }))
                            }
                            else {

                                dispatch({
                                    type: ADMIN_USER_LOGIN,
                                    data: true,
                                });
                                dispatch(({
                                    type: USER_LOADING,
                                    data: false,
                                }))
                            }
                        }
                        )
                    }
                }
            ).catch(e => {
                dispatch({
                    type: LOGIN_USER_FAIL,
                    data: e.message,
                })
            });
        }
        catch (error) {
            dispatch({
                type: LOGIN_USER_FAIL,
                data: error.message,
            })
        }
    };
}


export function signOut(): AppThunk {
    return async dispatch => {
        dispatch({
            type: RESET_ERROR,
            data: null
        });
        try {
            await FirebaseAuth.signOut().then(() => {
                dispatch({
                    type: USER_LOADING,
                    data: false,
                })
                dispatch({
                    type: FETCH_USER,
                    data: {
                        user: null,
                        admin: false,
                    }
                })
            });
        }
        catch (error) {
            console.log(error);
            dispatch({
                type: SIGN_OUT_FAIL,
                data: error.message,
            });
        }
    };
}

export function showResetForm(): AppThunk {
    return dispatch => {
        dispatch({
            type: SHOW_RESET_FORM,
            data: true,
        })
    }
}

export function hideResetForm(): AppThunk {
    return dispatch => {
        dispatch({
            type: HIDE_RESET_FORM,
            data: false,
        })
    };
}

export function resetPassword(email: string): AppThunk {
    return async dispatch => {
        if (Firebase === null)
        {
            dispatch({
                type: RESET_ERROR,
                data: null
            });
        }
        try {
            await FirebaseAuth.sendPasswordResetEmail(email).then(
                () => {
                    console.log("email sent");
                    dispatch({
                        type: PASSWORD_RESET,
                        data: true,
                    });
                }
            )
        }
        catch (error) {
            console.log(error);
            dispatch({
                type: PASSWORD_RESET,
                data: false,
            })
        }
    };
}
