import React from 'react'
import { Button, Input, Modal, Form } from "antd";
import { ITransactionState } from "../../store/reducers/transactions";
import { hideCancelPayoutForm, updateCashOutStatus } from "../../store/actions/transactions";
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from "react-router";
import { ICashOut } from "./CashOutTable";
import { IStoreState } from '../../store/IStoreState';
import { FormInstance } from 'antd/lib/form';

const { TextArea } = Input
interface IProps extends RouteComponentProps{
    hideCancelPayoutForm : () => void;
    updateCashOutStatus : (id:string, no:number, reason:string) => void;
    visible: boolean,
    transactions : ITransactionState,
    record: ICashOut,
}

class CancelPayout extends React.Component<IProps> {
    formRef = React.createRef<FormInstance>();

    constructor(props) {
        super(props);
        this.state = {
            ...props,
        }
    }

    componentDidMount(): void {
        this.setState({ visible: this.props.visible })
    }

    handleCancel = () => {
        this.props.hideCancelPayoutForm();
    };

    handleSubmit = values => {
        this.props.updateCashOutStatus(this.props.record.id, 3, values.cancelReason);
        this.props.hideCancelPayoutForm();
        this.props.history.push({ pathname: '/cash_out/' + this.props.record.id, state: { data: this.props.record } });
    }

    render() {
        return (
            <Modal visible={this.props.visible} footer={
                [
                    <Button key="" type="primary" htmlType="submit" className="login-form-button" onClick={this.handleSubmit} style={{ backgroundColor: "#2ED47A", border: '0px' }}>
                        Send
                    </Button>
                ]
            }  onCancel={this.handleCancel}>
                Failed Cash Out Remark: (required)
                <Form onFinish={this.handleSubmit} className="login-form" style={{ marginTop: '15px', alignItems: 'middle' }} ref={this.formRef}>
                    <Form.Item
                        name="cancelReason"
                        rules={[{ required: true, message: 'Please input a Cash Out remark' }]}
                    >
                        <TextArea rows={4} placeholder="Fill in the issue"/>
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

const mapStateToProps = (state:IStoreState) => {
    return {
        ...state
    }

}

const mapDispatchToProps = dispatch => {
    return ({
        hideCancelPayoutForm: () => dispatch(hideCancelPayoutForm()),
        updateCashOutStatus: (id:string, no:number, reason:string) => dispatch(updateCashOutStatus(id, no, reason)),
    })
}
/* eslint-disable  @typescript-eslint/no-explicit-any */
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CancelPayout));