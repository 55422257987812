import React from 'react'
import { Card, Table, Tag } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { IUsersState } from "../../../store/reducers/users";
import { getCashOutMethod } from "../../../store/actions/users";
import { connect } from "react-redux";
import { IStoreState } from '../../../store/IStoreState';

export interface CashOutMethod {
    id: string,
    type: string,
    account: string,
    status: string,
}
const columns: ColumnProps<CashOutMethod>[] = [
    {
        title: 'ID',
        dataIndex: 'id',
    },
    {
        title: 'Type',
        dataIndex: 'type',
    },
    {
        title: 'Account',
        dataIndex: 'account',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        render: function render(status) {
            const color = (status === 'Active') ? '#2ED47A' : '#F7685B';
            return (<Tag color={color}> {status} </Tag>)
        }
    },

];

interface IProps {
    id: string,
    getCashOutMethod : (id : string) => void,
    users: IUsersState,
}

class CashOutMethodComponent extends React.Component <IProps> {
    componentDidMount(): void {
        this.props.getCashOutMethod(this.props.id);
    }

    render() {
        const { cashOut } = this.props.users;
        return (
            <Card style={{ margin: '0px 16px' }} title='Cash Out Method' headStyle={{ backgroundColor: '#F8F8F8' }}  bodyStyle={{ padding: '0' }}>
                <Table columns={columns}
                    className="table"
                    dataSource={cashOut}
                    bordered={false}
                    pagination={{ pageSize: 4, hideOnSinglePage: true, style: { float: "left" } }}/>
            </Card>
        );
    }
}

const mapStateToProps = (state:IStoreState) => {
    return {
        ...state
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getCashOutMethod: (id: string) => {
            return dispatch(getCashOutMethod(id))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(CashOutMethodComponent)
