// Your web app's Firebase configuration
export default {
    apiKey: "AIzaSyCCeY_6H3j4KtczWQAAm6EanoUe_CZio2Q",
    authDomain: "altrgo-hk.firebaseapp.com",
    databaseURL: "https://altrgo-hk.firebaseio.com",
    projectId: "altrgo-hk",
    storageBucket: "altrgo-hk.appspot.com",
    messagingSenderId: "211441832980",
    appId: "1:211441832980:web:a05734cc0f2aa72c"
};

export const AlgoliaSettings = {
    prod: {
        appId: "GST4QCRB0R",
        apiKey: "7f10ed4b4ff032513e480875f1483e75",
        indexUsers: "prod_users",
        indexActivities: "prod_activities",
        indexActivitySessions: "prod_activity_sessions",
    },
    dev: {
        appId: "GST4QCRB0R",
        apiKey: "fb8d0c473ead58bab246aa85453d6cc8",
        indexUsers: "dev_users",
        indexActivities: "dev_activities",
        indexActivitySessions: "dev_activity_sessions",
    }
}.prod

export enum FirestoreKey {
    Users = "users",
    UserNotifications = "notifications",
    UserActivityBookmarks = "activityBookmarks",
    UserDailyUpdateBookmarks = "dailyUpdateBookmarks",
    UserJoined = "joined",
    UserFollowers = "followers",
    UserFollowing = "following",
    UserRewardRecords = "rewardRecords",
    UsersVariable = "usersVariable",
    UserVariablePayoutMethods = "payoutMethods",
    UserVariableStats = "stats",
    UserVariableStatsExperienceIncome = "experienceIncome", // doc
    UserVariableStatsJourneyIncome = "journeyIncome", // doc
    UserVariableStatsAdventureIncome = "adventureIncome", // doc
    UserVariableStatsExperienceVisit = "experienceVisit", // doc
    UserVariableStatsJourneyParticipation = "journeyParticipation", // doc
    UserVariableStatsCitizenProfileVisit = "citizenProfileVisit", // doc
    UserVariableStatsHeroProfileVisit = "heroProfileVisit", // doc
    UsersCitizenProfile = "usersCitizenProfile",
    UsersHeroProfile = "usersHeroProfile",
    UsersHeroStory = "usersHeroStory",
    UsersInfo = "usersInfo",
    ActivityMaster = "activityMaster",
    ActivityMasterActivities = "activities",
    ActivityMasterReviews = "reviews",
    ActivityMasterShocks = "shocks",
    Activities = "activities",
    ActivityBundles = "bundles",
    ActivityBundleCustomisations = "customisations",
    ActivityBookmarkedUser = "bookmarkedUser",
    ActivityStats = "stats",
    ActivityStatsIncome = "income", // doc
    ActivitiesStats = "activitiesStats",
    ActivitySessions = "activitySessions",
    ActivitySessionParticipants = "participants",
    ActivitySessionMessages = "messages",
    ActivitySessionStats = "stats",
    ActivitySessionStatsIncome = "income", // doc
    ActivitySessionsStats = "activitySessionsStats",
    DailyUpdates = "dailyUpdates",
    DailyUpdateComments = "comments",
    DUPhotos = "duPhotos",
    Missions = "missions",
    MissionProgresses = "missionProgresses",
    Rewards = "rewards",
    RewardMasters = "rewardMasters",
    RewardRecords = "rewardRecords",
    RewardRedemptionCodes = "rewardRedemptionCodes",
    RewardRedemptionCodeRecords = "records",
    Votings = "votings",
    VotingJourneys = "journeys",
    VotingVotes = "votes",
    VotingWinners = "winners",
    Transaction = "transactions",
    Payments = "payments",
    Payouts = "payouts",
    Refunds = "refunds",
    Categories = "categories",
    EventLogs = "eventLogs",
    BetaCodes = "betaCodes",
    UserReports = "userReports",
    Infos = "infos",
    InfoBeta = "beta", // doc
    InfoReg = "reg", // doc
    InfoUrls = "urls", // doc
    InfoVersion = "version", // doc
    AdminUsers = "adminUsers",
    Strings = "strings",
}

export const dateStringFormat = {
    date: "DD MMM YYYY",
    dateTime: "DD MMM YYYY HH:mm",
}