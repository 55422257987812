import React from 'react';
import {
    PlusOutlined,
    LoadingOutlined
} from '@ant-design/icons';
import {
    Drawer,
    Button,
    Col,
    Row,
    Input,
    Select,
    DatePicker,
    Switch,
    InputNumber,
    Checkbox,
    Upload,
    message,
    Form
} from 'antd';
import { IRewardState } from "../../store/reducers/rewards";
import { getRewardDetails, hideRewardForm, submitReward } from "../../store/actions/rewards";
import { connect } from "react-redux";
import { JourneyDiscountReward, Reward, VenueReward } from "../../models/reward";
import { dateStringFormat } from "../../constants/setting";
import { FormInstance } from 'antd/lib/form';
import { IStoreState } from '../../store/IStoreState';

const { Option } = Select;
interface IProps {
    hideRewardForm: () => void,
    getRewardDetails : (id:string) => void,
    submitReward: (value, master, coverUrl, detailsUrl) => void,
    rewards:IRewardState,
}
interface IState {
    visible: boolean,
    disable: boolean,
    reward:{
        type:number,
        redeem:boolean,
        userRole:number,
        expire:boolean,
    }
    type:number,
    code:string,
    url:string,
    discount:number,
    userRole:number,
    redeem: boolean,
    expire: boolean,
    valid: boolean,
    quantity:boolean,
    remaining: number,
    status: boolean,
    autoIssue: boolean,
    coverLoading: boolean,
    detailsLoading: boolean,
    coverImageUrl: string,
    detailsImageUrl: string,
    expireDate: boolean,
    expireDateTime:number,
    redeemDate: boolean,
    location: string,
    journeyDiscount: number,
    capacity: string,
    website: string,
    originalPrice: number,
    tnc: string,
    recommendation: Array<string>,

}

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
}

class RewardForm extends React.Component <IProps, IState> {
    formRef = React.createRef<FormInstance>();

    constructor(props) {
        super(props);
        this.state = {
            type: null,
            disable: false,
            code: '',
            url: '',
            discount: null,
            redeem: false,
            expire: false,
            visible: false,
            userRole: null,
            quantity: false,
            valid: false,
            remaining: null,
            status: false,
            autoIssue: false,
            coverLoading: false,
            detailsLoading: false,
            coverImageUrl: '',
            detailsImageUrl: '',
            expireDate: false,
            expireDateTime: null,
            redeemDate: false,
            location: '',
            reward: {
                type: null,
                redeem: false,
                userRole: null,
                expire: false,
            },
            journeyDiscount: null,
            capacity: '',
            website: '',
            originalPrice: null,
            tnc: '',
            recommendation: [],
        };
    }
    componentDidUpdate(prevProps: Readonly<IProps>): void {
        const { rewards } = this.props;
        if (prevProps.rewards.rewardForm.show !== this.props.rewards.rewardForm.show)
        {
            if (this.formRef.current) {
                this.formRef.current.resetFields();
            }
            this.setState({
                type: null,
                disable: false,
                status: true,
                redeem: false,
                valid: false,
                expire: false,
                code: null,
                url: null,
                location: null,
                discount: null,
                expireDate: false,
                userRole: null,
                autoIssue: false,
                quantity: false,
                remaining: null,
                coverLoading: false,
                detailsLoading: false,
                coverImageUrl: '',
                detailsImageUrl: '',
                capacity: '',
                website: '',
                originalPrice: null,
                tnc: '',
                recommendation: [],
            });
        }
        if (prevProps.rewards.rewardForm.id !== rewards.rewardForm.id)
        {
            this.props.getRewardDetails(this.props.rewards.rewardForm.id);
        }
        if (rewards.reward !== prevProps.rewards.reward)
        {
            const { reward } = rewards;
            console.log(reward);
            this.setState({
                type: reward.type,
                disable: true,
                redeem: !!reward.expireDateTime,
                status: reward.isActive,
                valid: !!reward.recordValidityPeriod,
                quantity: !!reward.quantity,
                remaining: reward.remaining,
                autoIssue: reward.isIssueOnReg,
                expireDate: reward.expire,
                userRole: reward.userRole,
                coverImageUrl: reward.coverUrl,
                detailsImageUrl: reward.detailsUrl,
            })
            if (reward.type === 0) {
                this.setState ({
                    location: reward.location,
                    expireDateTime: reward.recordExpireDateTime ? reward.recordExpireDateTime : null,
                    discount: reward.discount * 100, capacity: reward.capacity,
                    website: reward.website,
                    originalPrice: reward.originalPrice,
                    tnc: reward.tnc,
                    recommendation: reward.recommendation,
                }
                );
                this.formRef.current.setFieldsValue({
                    type: reward.type,
                    title: reward.title,
                    description: reward.description,
                    userRole: reward.userRole,
                    redeemDate: reward.expireDateTime ? reward.expireDateTime : null,
                    expireDateTime: reward.recordExpireDateTime ? reward.recordExpireDateTime : null,
                    validDays: reward.recordValidityPeriod,
                    quantity: !!reward.quantity,
                    quantityValue: reward.quantity ? reward.quantity : null,
                    remainingValue: reward.quantity ? reward.remaining : null,
                })
            }
            else if (reward.type === 1) {
                this.setState({ code: reward.code, expireDateTime: reward.recordExpireDateTime ? reward.recordExpireDateTime : null });
                this.formRef.current.setFieldsValue({
                    type: reward.type,
                    status: reward.isActive,
                    title: reward.title,
                    description: reward.description,
                    userRole: reward.userRole,
                    autoIssue: reward.isIssueOnReg,
                    redeemDate: reward.expireDateTime ? reward.expireDateTime : null,
                    expireDateTime: reward.recordExpireDateTime ? reward.recordExpireDateTime : null,
                    expire: reward.expire,
                    validDays: reward.recordValidityPeriod,
                    quantity: !!reward.quantity,
                    quantityValue: reward.quantity ? reward.quantity : null,
                    remainingValue: reward.remaining ? reward.remaining : null,
                })
            }
            else if (reward.type === 3) {
                this.setState({ url: reward.url, expireDateTime: reward.recordExpireDateTime ? reward.recordExpireDateTime : null })
                this.formRef.current.setFieldsValue({
                    type: reward.type,
                    status: reward.isActive,
                    title: reward.title,
                    description: reward.description,
                    userRole: reward.userRole,
                    autoIssue: reward.isIssueOnReg,
                    redeemDate: reward.expireDateTime ? reward.expireDateTime : null,
                    expire: reward.expire,
                    validDays: reward.recordValidityPeriod,
                    quantity: !!reward.quantity,
                    quantityValue: reward.quantity ? reward.quantity : null,
                    remainingValue: reward.remaining ? reward.remaining : null,
                })
            }
            else if (reward.type === 5) {
                this.setState({ expireDateTime: reward.recordExpireDateTime ? reward.recordExpireDateTime : null,
                    journeyDiscount: reward.discount * 100 });
                this.formRef.current.setFieldsValue({
                    type: reward.type,
                    status: reward.isActive,
                    title: reward.title,
                    description: reward.description,
                    userRole: reward.userRole,
                    autoIssue: reward.isIssueOnReg,
                    redeemDate: reward.expireDateTime ? reward.expireDateTime : null,
                    expire: reward.expire,
                    validDays: reward.recordValidityPeriod,
                    quantity: !!reward.quantity,
                    quantityValue: reward.quantity ? reward.quantity : null,
                    remainingValue: reward.remaining ? reward.remaining : null,
                })
            }
        }
    }

    changeType = (value) => {
        this.setState({
            type: value
        })
        if (!this.props.rewards.rewardForm.id) {
            this.formRef.current.resetFields();
        }
    }
    handleChange = info => {
        if (info.file.status === 'uploading') {
            this.setState({ coverLoading: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, coverImageUrl =>
                this.setState({
                    coverImageUrl,
                    coverLoading: false,
                }),
            );
        }
    };
    handleDetailsChange = info => {
        if (info.file.status === 'uploading') {
            this.setState({ detailsLoading: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, detailsImageUrl =>
                this.setState({
                    detailsImageUrl,
                    detailsLoading: false,
                }),
            );
        }
    };
    handleRemove = file => {
        console.log(file);
    }
    handleSubmit = values => {
        const { id } = this.props.rewards.rewardForm;
        console.log(values);
        if (this.state.type === 0)
        {
            const reward : VenueReward = {
                id: id ? id : null,
                type: this.state.type as number,
                isActive: values.status,
                title: values.title,
                description: values.description,
                userRole: values.role,
                discount: values.discount / 100,
                capacity: values.capacity,
                recommendation: values.recommendation ? values.recommendation.join(',') : null,
                location: values.location,
                website: values.website,
                originalPrice: values.originalPrice,
                tnc: values.tnc,
                isIssueOnReg: values.autoIssue,
                expireDateTime: values.redeem ? values.redeemDate.unix() * 1000 : null,
                createdDateTime: null,
            }
            const RewardMaster  = {
                recordExpireDateTime: (values.expire && values.expireDateTime)  ? values.expireDateTime.unix() * 1000 : null,
                recordValidityPeriod: values.valid ? values.validDays : null,
                quantity: values.quantity ? values.quantityValue : null,
                remaining: (values.quantity && values.remainingValue) ? values.remainingValue : null,
            }
            this.props.submitReward(reward, RewardMaster, this.state.coverImageUrl, this.state.detailsImageUrl);

        }
        else if (this.state.type === 1 || this.state.type === 3)
        {
            console.log(this.state.type);
            const reward : Reward = {
                id: id ? id : null,
                type: this.state.type as number,
                isActive: values.status,
                title: values.title,
                description: values.description,
                userRole: values.role,
                isIssueOnReg: values.autoIssue,
                expireDateTime: values.redeem ? values.redeemDate.unix() * 1000 : null,
                createdDateTime: null,
            }
            const RewardMaster = {
                recordExpireDateTime: (values.expire && values.expireDateTime) ? values.expireDateTime.unix() * 1000 : null,
                recordValidityPeriod: values.valid ? values.validDays : null,
                quantity: values.quantity ? values.quantityValue : null,
                remaining: (values.quantity && values.remainingValue) ? values.remainingValue : null,
                code: (this.state.type === 1) ? values.code : null,
                url: (this.state.type === 3) ? values.url : null,
            }
            this.props.submitReward(reward, RewardMaster, this.state.coverImageUrl, this.state.detailsImageUrl);
        }
        else if ( this.state.type === 5)
        {
            console.log(values);
            const reward : JourneyDiscountReward = {
                id: id ? id : null,
                type: this.state.type,
                isActive: values.status,
                title: values.title,
                description: values.description,
                userRole: values.role,
                isIssueOnReg: values.autoIssue,
                expireDateTime: values.redeem ? values.redeemDate.unix() * 1000 : null,
                createdDateTime: null,
                discount: values.journeyDiscount / 100,
            }
            const RewardMaster = {
                recordExpireDateTime: (values.expire && values.expireDateTime) ? values.expireDateTime.unix() * 1000 : null,
                recordValidityPeriod: values.valid ? values.validDays : null,
                quantity: values.quantity ? values.quantityValue : null,
                remaining: (values.quantity && values.remainingValue) ? values.remainingValue : null,
            }
            this.props.submitReward(reward, RewardMaster, this.state.coverImageUrl, this.state.detailsImageUrl);
        }
    }


    render() {
        const { rewardForm, loading } = this.props.rewards;
        const { recommendation } = this.state;
        const uploadButton = (
            <div>
                {this.state.coverLoading ? <LoadingOutlined /> : <PlusOutlined />}
                <div className="ant-upload-text">Upload</div>
            </div>
        );
        const uploadDetailsButton = (
            <div>
                {this.state.detailsLoading ? <LoadingOutlined /> : <PlusOutlined />}
                <div className="ant-upload-text">Upload</div>
            </div>
        );
        const { coverImageUrl, detailsImageUrl } = this.state;
        return (
            <div>
                <Drawer
                    title="Create Reward Item"
                    width={500}
                    onClose={() => {this.props.hideRewardForm();
                        this.formRef.current.resetFields();
                    }}
                    visible={rewardForm.show}
                >
                    <Form layout="vertical" hideRequiredMark id="rewardForm" ref={this.formRef} onFinish={this.handleSubmit}>
                        <Form.Item
                            label="Type"
                            name="type"
                            rules={[{ required: false }]}
                        >
                            <Select
                                placeholder="Select Type"
                                style={{ width: '200px' }}
                                disabled={this.state.disable || loading}
                                onChange={(value) => this.changeType(value)}
                                defaultValue={this.state.type}
                            >
                                <Option value={0}>Venue</Option>
                                <Option value={1}>Code</Option>
                                <Option value={3}>QR Code</Option>
                                <Option value={5}>Journey Discount</Option>
                            </Select>
                        </Form.Item>
                        {(this.state.type || this.state.type === 0 || rewardForm.id) &&
                            <div>
                                <div className="form-subheading">Reward Information</div>
                                <Form.Item
                                    label="Status"
                                    name="status"
                                    rules={[{ required: false }]}
                                    initialValue={this.state.status}
                                >
                                    <Switch
                                        checkedChildren='✔'
                                        unCheckedChildren='✖'
                                        checked={this.state.status}
                                        disabled={loading}
                                        onChange={(e) => this.setState({ status: e })}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Title"
                                    name="title"
                                    rules={[{ required: true, message: 'Please enter a title' }]}
                                >
                                    <Input placeholder="Reward Title" disabled={loading}/>
                                </Form.Item>
                                <Form.Item
                                    label="Description"
                                    name="description"
                                    rules={[{ required: false }]}
                                >
                                    <Input.TextArea rows={4} placeholder="Content" disabled={loading}/>
                                </Form.Item>
                                <Form.Item
                                    label="User Role"
                                    name="role"
                                    rules={[{ required: true, message: 'Please select user role!' }]}
                                    initialValue={this.state.userRole}
                                >
                                    <Select placeholder="Select User Role" style={{ width: '200px' }} disabled={loading || this.state.disable}>
                                        <Option value={1}>Traveller</Option>
                                        <Option value={2}>Hero</Option>
                                        <Option value={0}>All</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="Cover Photo"
                                    name="cover"
                                    rules={[{ required: false }]}
                                >
                                    <Upload
                                        name="avatar"
                                        listType="picture-card"
                                        className="avatar-uploader"
                                        showUploadList={false}
                                        action={'https://www.mocky.io/v2/5cc8019d300000980a055e76'}
                                        beforeUpload={beforeUpload}
                                        onChange={this.handleChange}
                                        style={{ width: '370px' }}
                                        onRemove={this.handleRemove}
                                        multiple={false}
                                        disabled={loading}
                                    >
                                        {coverImageUrl ? <img src={coverImageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                                    </Upload>
                                </Form.Item>
                                <Form.Item
                                    label="Details Photo"
                                    name="details"
                                    rules={[{ required: false }]}
                                >
                                    <Upload
                                        name="avatar"
                                        listType="picture-card"
                                        className="avatar-uploader"
                                        showUploadList={false}
                                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                        beforeUpload={beforeUpload}
                                        onChange={this.handleDetailsChange}
                                        disabled={loading}
                                    >
                                        {detailsImageUrl ? <img src={detailsImageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadDetailsButton}
                                    </Upload>
                                </Form.Item>
                                {(this.state.type === 0) &&
                                <div>
                                    <div className="form-subheading">Venue Information</div>
                                    <Row style={{ display: 'flex', flexDirection: 'row' }}>
                                        <Form.Item
                                            label="Discount"
                                            name="discount"
                                            rules={[{ required: true, message: 'Please enter discount' }]}
                                            initialValue={this.state.discount}
                                        >
                                            <InputNumber min={0} max={100} disabled={loading}/> %
                                        </Form.Item>
                                    </Row>

                                    <Form.Item
                                        label="Capacity"
                                        name="capacity"
                                        rules={[{ required: false }]}
                                        initialValue={this.state.capacity}
                                    >
                                        <Input placeholder="Content" disabled={loading} />
                                    </Form.Item>
                                    <Form.Item
                                        label="Recommendation"
                                        name="recommendation"
                                        rules={[{ required: false }]}
                                        initialValue={recommendation}
                                    >
                                        <Checkbox.Group style={{ width: '100%' }} disabled={loading}>
                                            <Row>
                                                <Col span={8}>
                                                    <Checkbox value="0">Performance</Checkbox>
                                                </Col>
                                                <Col span={8}>
                                                    <Checkbox value="1" style={{ width: '200px' }}>Sharing/Seminar</Checkbox>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={8}>
                                                    <Checkbox value="2">Class/Workshop</Checkbox>
                                                </Col>
                                                <Col span={8}>
                                                    <Checkbox value="3">Tour</Checkbox>
                                                </Col>
                                            </Row>
                                        </Checkbox.Group>
                                    </Form.Item>
                                    <Form.Item
                                        label="Location"
                                        name="location"
                                        rules={[{ required: false }]}
                                        initialValue={this.state.location}
                                    >
                                        <Input placeholder="Location" disabled={loading}/>
                                    </Form.Item>
                                    <Form.Item
                                        label="Website"
                                        name="website"
                                        rules={[{ required: false }]}
                                        initialValue={this.state.website}
                                    >
                                        <Input placeholder="Website" disabled={loading}/>
                                    </Form.Item>
                                    <Form.Item
                                        label="Original Price"
                                        name="originalPrice"
                                        rules={[{ required: false }]}
                                        initialValue={this.state.originalPrice}
                                    >
                                        <Input placeholder="Content" disabled={loading}/>
                                    </Form.Item>
                                    <Form.Item
                                        label="Terms and Conditions"
                                        name="tnc"
                                        rules={[{ required: false }]}
                                        initialValue={this.state.tnc}
                                    >
                                        <Input.TextArea rows={4} placeholder="Content" disabled={loading} />
                                    </Form.Item>
                                </div>}
                                {(this.state.type === 1) &&
                                    <div>
                                        <div className="form-subheading">Code Information</div>
                                        <Form.Item
                                            label="Code"
                                            name="code"
                                            rules={[{ required: true, message: 'Please enter a code' }]}
                                            initialValue={this.state.code}
                                        >
                                            <Input placeholder="Code" disabled={loading} />
                                        </Form.Item>
                                    </div>
                                }
                                {
                                    (this.state.type === 3) &&
                                        <div>
                                            <div className="form-subheading">QR Code Information</div>
                                            <Form.Item
                                                label="URL"
                                                name="url"
                                                rules={[{ required: true, message: 'Please enter a URL' }]}
                                                initialValue={this.state.url}
                                            >
                                                <Input placeholder="URL" disabled={loading}/>
                                            </Form.Item>
                                        </div>
                                }
                                {
                                    (this.state.type === 5) &&
                                        <div>
                                            <div className="form-subheading">Journey Discount Information</div>
                                            <Form.Item
                                                label="Discount (1~100)"
                                                name="journeyDiscount"
                                                rules={[{ required: true, message: 'Please enter a discount' }]}
                                                initialValue={this.state.journeyDiscount}
                                            >
                                                <div><InputNumber min={0} max={100} defaultValue={this.state.journeyDiscount} disabled={loading}/> %</div>
                                            </Form.Item>
                                        </div>
                                }
                                <div className="form-subheading">Reward Redemption</div>
                                <Row>
                                    <Form.Item
                                        style={{ display: 'inline-block' }}
                                        className="ant-form-checkbox"
                                        name="redeem"
                                        rules={[{ required: false }]}
                                        initialValue={this.state.redeem}
                                    >
                                        <Checkbox
                                            onChange={
                                                (e) => {
                                                    this.setState({ redeem: e.target.checked });
                                                    if (!e.target.checked) {
                                                        this.formRef.current.setFieldsValue({ redeemDate: null })
                                                    }
                                                }
                                            }
                                            disabled={loading}
                                            checked={!!this.state.redeem}>Reward can be redeemed until
                                        </Checkbox>
                                    </Form.Item>
                                    <Form.Item
                                        style={{ display: 'inline-block', width: 'calc(40% - 12px)' }}
                                        name="redeemDate"
                                        rules={[{ required: false }]}
                                    >
                                        <DatePicker format={dateStringFormat.date} style={{ width: '150px' }} disabled={!this.state.redeem || loading}/>
                                    </Form.Item>
                                </Row>
                                <Form.Item
                                    name="autoIssue"
                                    rules={[{ required: false }]}
                                    initialValue={this.state.autoIssue}
                                >
                                    <Row>
                                        <Checkbox disabled={loading}
                                            onChange={(e) => this.setState({ autoIssue: e.target.checked })} checked={this.state.autoIssue}>Auto issuing on user registration</Checkbox>
                                    </Row>
                                </Form.Item>
                                <div className="form-subheading">Reward Record</div>
                                <Row>
                                    <Form.Item
                                        className="ant-form-checkbox"
                                        name="expire"
                                        rules={[{ required: false }]}
                                        initialValue={this.state.expire}
                                    >
                                        <Checkbox  onChange={(e) => {
                                            this.setState({ expireDate: e.target.checked });
                                            if (!e.target.checked) {
                                                this.formRef.current.setFieldsValue({ expireDateTime: null })
                                            }}} checked={this.state.expireDate} disabled={loading}>Expires on</Checkbox>
                                    </Form.Item>
                                    <Form.Item
                                        style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}
                                        name="expireDateTime"
                                        rules={[{ required: false }]}
                                    >
                                        <DatePicker name="expireDate" style={{ width: '150px' }} format={dateStringFormat.date}
                                            disabled={!this.state.expireDate || loading} />
                                    </Form.Item>
                                </Row>
                                <Row>
                                    <Form.Item
                                        className="ant-form-checkbox"
                                        name="valid"
                                        rules={[{ required: false }]}
                                        initialValue={this.state.valid}
                                    >
                                        <Checkbox onChange={(e) => this.setState({ valid: e.target.checked })} checked={this.state.valid} disabled={loading}>The reward is valid for </Checkbox>
                                    </Form.Item>
                                    <Form.Item
                                        style={{ display: 'inline-block' }}
                                        name="validDays"
                                        rules={[{ required: false }]}
                                    >
                                        <InputNumber min={0} disabled={!this.state.valid || loading}/>
                                    </Form.Item>
                                    <div className="ant-row ant-form-item ant-form-checkbox form-text" >days</div>
                                </Row>
                                <Row style={{ marginBottom: "6px", paddingBottom: "16px" }}>
                                    <Form.Item
                                        className="ant-form-checkbox"
                                        name="quantity"
                                        rules={[{ required: false }]}
                                        initialValue={this.state.quantity}
                                    >
                                        <Checkbox onChange={(e) => this.setState({ quantity: e.target.checked })} checked={this.state.quantity} disabled={loading}>Quantity</Checkbox>
                                    </Form.Item>
                                    <Form.Item
                                        style={{ display: 'inline-block' }}
                                        name="quantityValue"
                                        rules={[{ required: false }]}
                                    >
                                        <InputNumber min={0} disabled={!this.state.quantity || loading} />
                                    </Form.Item>
                                    <div className="ant-row ant-form-item ant-form-checkbox form-text" >,Remaining</div>
                                    <Form.Item
                                        style={{ display: 'inline-block', width: 'calc(30% - 12px)' }}
                                        name="remainingValue"
                                        rules={[{ required: false }]}
                                    >
                                        <InputNumber style={{ marginLeft: '3px' }} disabled={!this.state.quantity || loading}/>
                                    </Form.Item>
                                </Row>
                            </div>
                        }
                    </Form>
                    <div className="reward-form-footer" >
                        <Button onClick={() => {this.props.hideRewardForm(); this.formRef.current.resetFields();}} style={{ marginRight: 8 }}>
                            Cancel
                        </Button>
                        <Button form="rewardForm" style={{ backgroundColor: '#2ED47A', border: 0 }}
                            type="primary" htmlType="submit" loading={loading}>
                            Submit
                        </Button>
                    </div>
                </Drawer>
            </div>
        )
    }
}

const mapStateToProps = (state: IStoreState) => {
    return {
        ...state
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        hideRewardForm: () => dispatch(hideRewardForm()),
        getRewardDetails: (id:string) => dispatch(getRewardDetails(id)),
        submitReward: (value, master, coverUrl, detailsUrl) => dispatch(submitReward(value, master, coverUrl, detailsUrl)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RewardForm);


