import FirebaseModule from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import firebaseConfig from '../constants/setting';

export let Firebase: typeof FirebaseModule;
export let FirebaseAuth: FirebaseModule.auth.Auth;
export let FirebaseDB: FirebaseModule.firestore.Firestore;
export let FirebaseFunctions: FirebaseModule.functions.Functions;
export let FirebaseStorage: FirebaseModule.storage.Storage;

export async function initFirebaseApp(): Promise<void> {
    if (process.env.NODE_ENV === 'production') {
        const response = await fetch('/__/firebase/init.json')
        init(await response.json());
    } else {
        init(firebaseConfig);
        return Promise.resolve();
    }
}

function init(options) {
    FirebaseModule.initializeApp(options);
    Firebase = FirebaseModule;
    FirebaseAuth = FirebaseModule.auth();
    FirebaseDB = FirebaseModule.firestore();
    FirebaseFunctions = FirebaseModule.app().functions('asia-east2');
    FirebaseStorage = FirebaseModule.storage();
}
