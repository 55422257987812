import React from 'react'
import { GiftOutlined, InboxOutlined, PlusOutlined, SyncOutlined } from '@ant-design/icons';
import { Card, Input, Table, Badge, Tag, Tooltip, Row, Col, message } from "antd";
import { Status } from '../experiences/ExperienceDetails';
import { RouteComponentProps, withRouter } from "react-router";
import { connect } from "react-redux";
import { IRewardState } from "../../store/reducers/rewards";
import { getAllRewards, getRedemptions, showRedemptionForm } from "../../store/actions/rewards";
import RedemptionFormWrapper from "./RedemptionForm";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { IStoreState } from '../../store/IStoreState';

const { Search } = Input;

export interface IRedemption {
    code: string,
    type:string,
    amount: number,
    usedCount: number,
    repeatable: string,
    rewardId:string,
    expiryDate: string,
    active: Status,
}

interface IProps extends RouteComponentProps{
    getRedemptions : () => void,
    getAllRewards : () => void,
    rewards: IRewardState,
    showRedemptionForm : (id?:string) => void,
}

interface IState {
    searchResults: IRedemption[],
}

const columns = [
    {
        title: 'Code',
        dataIndex: 'code',
    },
    {
        title: 'Type',
        dataIndex: 'type',
    },
    {
        title: 'Status',
        render: function render(record) {
            return (
                <Row gutter={16}>
                    <Col span={8}><Tooltip title='Amount'><span style={{ marginLeft: '5px' }}><InboxOutlined />{record.amount}</span></Tooltip></Col>
                    <Col span={8}><Tooltip title='Used Count'><span style={{ marginLeft: '5px' }}><GiftOutlined />{record.usedCount}</span></Tooltip></Col>
                    <Col span={8}><Tooltip title='Repeatable'><span style={{ marginLeft: '5px' }}><SyncOutlined />{record.repeatable}</span></Tooltip></Col>
                </Row>
            );
        }
    },

    {
        title: 'Reward ID',
        key: 'rewardId',
        render: function render(record) {
            const success = () => {
                message.success('Reward ID Copied!');
            };
            return (
                <CopyToClipboard text={record.rewardId}><Tooltip placement="top" title={record.rewardId}><Tag onClick={(e) => {e.stopPropagation(); success()}}>Reward</Tag></Tooltip></CopyToClipboard>
            )
        }
    },
    {
        title: 'Expiry Date',
        dataIndex: 'expiryDate',
    },
    {
        title: 'Active',
        key: 'active',
        render: function render(record) {
            return (
                record.active && <Badge status={record.active.type} text={record.active.title} />
            )
        }
    },
]

class Redemption extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            searchResults: [],
        }
    }
    search(value: string) {
        const { redemptions } = this.props.rewards;
        const results : IRedemption[] = [];
        redemptions.forEach(redemption => {
            if (redemption.code.includes(value)) {
                results.push(redemption);
            }
        })
        this.setState({ searchResults: results })
    }
    componentDidMount(): void {
        this.props.getRedemptions();
        this.props.getAllRewards();
    }
    componentDidUpdate(prevProps: Readonly<IProps>): void {
        if (prevProps.rewards.redemptions !== this.props.rewards.redemptions)
        {
            this.setState({ searchResults: this.props.rewards.redemptions })
        }
    }

    render() {
        const { searchResults } = this.state;
        return (
            <div style={{ margin: '16px' }}>
                <Search
                    placeholder="Search Code/ID/Title"
                    onSearch={value => this.search(value)}
                    className="search"
                    style={{
                        marginBottom: "16px"
                    }}
                    size="large"
                />
                <Card style={{ marginBottom: 16 }} headStyle={{ backgroundColor: '#F8F8F8' }}
                    extra={<PlusOutlined onClick={() => this.props.showRedemptionForm()} />}
                    title="Redemption Code">
                    <Table columns={columns}
                        dataSource={searchResults}
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    this.props.showRedemptionForm(record.code)
                                }
                            }
                        }
                        }
                    />
                </Card>
                <RedemptionFormWrapper />
            </div>
        );
    }
}


const mapStateToProps = (state: IStoreState) => {
    return {
        ...state
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getRedemptions: () => dispatch(getRedemptions()),
        getAllRewards: () => dispatch(getAllRewards()),
        showRedemptionForm: (id?:string) => dispatch(showRedemptionForm(id)),

    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Redemption));