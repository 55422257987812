import React from 'react';
import { Tabs } from 'antd';
import CashOutTable from "./CashOutTable";

const { TabPane } = Tabs;

interface IState {
    showTravellers: boolean
}

type IProps = unknown

class CashOut extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            showTravellers: false
        }
    }

    render(): React.ReactNode {
        return (
            <div style={{ margin: '16px' }} className="card-container">
                <Tabs type="card">
                    <TabPane tab="New Pendings" key="1" >
                        <CashOutTable status="new"/>
                    </TabPane>
                    <TabPane tab="All" key="2">
                        <CashOutTable status='all' />
                    </TabPane>
                </Tabs>
            </div>
        );
    }
}

export default CashOut;
