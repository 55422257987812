import { IAdminUser } from "../../components/administration/AdminUsers";
import { GET_ADMIN_USERS, USER_UPDATING } from "../actions/admin";

export interface IAdminState {
    adminUsers: IAdminUser[],
    updating: {id: string, updating: boolean},
}

export const initialState: IAdminState = {
    adminUsers: [],
    updating: { id: "", updating: null },
}

export default (state = initialState, action: {type: string, data}): IAdminState => {
    switch (action.type) {
        case GET_ADMIN_USERS:
            return {
                ...state,
                adminUsers: action.data,
            }
        case USER_UPDATING:
            return {
                ...state,
                updating: action.data,
            }
        default:
            return {
                ...state,
            }
    }
}