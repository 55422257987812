import React from 'react';
import { Descriptions, Modal } from 'antd';
import { IReportState } from "../../store/reducers/report";
import { hideUserReport } from "../../store/actions/report";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { IStoreState } from '../../store/IStoreState';

interface IProps {
    hideUserReport : () => void,
    report: IReportState,
}

class ReportDetail extends React.Component <IProps> {
    handleCancel(e) {
        e.preventDefault();
        this.props.hideUserReport();
    }
    render() {
        const { showReport, report } = this.props.report;
        const title =
            <div>Report Detail ( {showReport && report.id} )</div>;
        return (
            <Modal visible={showReport} footer={null} title={title} onCancel={e => this.handleCancel(e)}>
                {showReport &&  <Descriptions layout="horizontal" column={1}>
                    <Descriptions.Item label="Title">{report.title}</Descriptions.Item>
                    <Descriptions.Item label="User"> <Link to={"/users/" + report.userId} style={{ color: '#374E6B', textDecoration: 'underline', fontWeight: 'bolder' }}
                        onClick={(e) => { e.stopPropagation(); this.props.hideUserReport()}}>{report.user}</Link></Descriptions.Item>
                    <Descriptions.Item label="Location">{report.location}</Descriptions.Item>
                    <Descriptions.Item label="Description">{report.description}</Descriptions.Item>
                </Descriptions> }
            </Modal>
        );
    }
}

const mapStateToProps = (state:IStoreState) => {
    return {
        ...state
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        hideUserReport: () => dispatch(hideUserReport()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportDetail);