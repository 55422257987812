import React from 'react'
import { Card, Col, Divider, Row, Statistic, Modal } from "antd";
import { IUsersState } from "../../../store/reducers/users";
import { getUserProfile } from "../../../store/actions/users";
import { connect } from "react-redux";
import { IStoreState } from '../../../store/IStoreState';

interface IProps {
    id: string,
    getUserProfile: (id: string) => void,
    users: IUsersState,
}

interface IState {
    showCover: boolean
}

class UserProfile extends React.Component <IProps, IState> {
    constructor(props) {
        super(props)
        this.state = {
            showCover: false,
        }
    }

    componentDidMount(): void {
        this.props.getUserProfile(this.props.id);
    }

    handleCancel = e => {
        console.log(e);
        this.setState({
            showCover: false,
        });
    };

     showCover =  () => {
         this.setState({ showCover: true })
     }

     render() {
         const { profile } = this.props.users;

         return (
             <Card title={profile.Name} style={{ margin: '0px 16px' }} headStyle={{ backgroundColor: '#F8F8F8' }}
                 extra={<div onClick={this.showCover} style={{ cursor: 'pointer', verticalAlign: 'middle', textDecoration: 'underline', fontWeight: 'bolder' }}>View Cover</div>}
             >
                 <Modal visible={this.state.showCover} footer={null} centered closable={false} style={{ backgroundColor: 'transparent', width: '650px', padding: '5px' }} className="cover-img">
                     <p onClick={this.handleCancel} style={{ color: 'white', textDecoration: 'underline', float: 'right', cursor: 'pointer' }}>Close</p>
                     <img src={profile.Cover} style={{ marginLeft: '-10px', width: '100%' }} alt=""/>
                 </Modal>
                 <div style={{ display: 'flex', flexDirection: 'row' }}>
                     <img src={profile.Avatar} style={{ height: '85px', width: '85px' }} alt=""/>
                     <div style={{ display: 'flex', flexDirection: 'column', margin: '10px', width: '400px' }}>
                         <span style={{ color: '#37393A', fontSize: '13px', fontWeight: 'bold' }}> Description </span>
                         <p style={{ color: '#898989' }}> {profile.Description}</p>
                     </div>
                     <Divider type='vertical' style={{ height: 'auto', marginLeft: '10px' }}/>
                     <Row justify="space-between" gutter={32} style={{ marginTop: 'auto', marginLeft: 'auto', marginRight: 'auto', marginBottom: 'auto' }}>
                         <Col span={3}>
                             <div className='profile-col'>
                                 <Statistic value={profile.Joined}/>
                                 <p style={{ color: '#898989' }}>Joined</p>
                             </div>
                         </Col>
                         <Col span={3}>
                             <div className='profile-col'>
                                 <Statistic value={profile.Pledged}/>
                                 <p style={{ color: '#898989' }}>Pledged</p>
                             </div>
                         </Col>
                         <Col span={3}>
                             <div className='profile-col'>
                                 <Statistic value={profile.Bookmarked}/>
                                 <p style={{ color: '#898989' }}>Bookmarked</p>
                             </div>
                         </Col>
                         <Col span={3}>
                             <div className='profile-col'>
                                 <Statistic value={profile.Followings}/>
                                 <p style={{ color: '#898989' }}>Followings</p>
                             </div>
                         </Col>
                     </Row>
                 </div>

             </Card>

         );
     }
}

const mapStateToProps = (state: IStoreState) => {
    return {
        ...state
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUserProfile: (id: string) => {
            return dispatch(getUserProfile(id))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);