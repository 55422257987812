export enum RewardRedemptionCodeType {
    OneTime,
    Limited,
    Infinity,
}
export interface RewardRedemptionCode {
    expireDate: boolean;
    id?: string;
    type: RewardRedemptionCodeType;
    rewardId: string;
    expireDateTime?: number;
    isActive: boolean;
    isUsed: boolean;
    usedDateTime?: number;
    amount: number;
    usedCount: number;
    isRepeatable: boolean;
}

export interface RedemptionRecord {
    id?: string;
    userId: string
    usedDateTime: number;
}