import { Input, Tabs } from 'antd';
import React from 'react';
import ReportTable from './ReportTable';

const { TabPane } = Tabs;
const { Search } = Input;

class Report extends React.Component {
    render(): React.ReactNode {
        return (
            <div style={{ margin: '16px' }} >
                <Search
                    placeholder="Search User's email"
                    className="search"
                    style={{
                        marginBottom: "16px"
                    }}
                    size="large"
                />
                <div style={{ marginBottom: 16 }}>
                    <Tabs type="card">
                        <TabPane tab="In Progress" key="1" >
                            <ReportTable status='0' />
                        </TabPane>
                        <TabPane tab="Completed" key="2">
                            <ReportTable status='all' />
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        );
    }
}

export default Report;
