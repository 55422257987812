import React from 'react'
import { Card, Table, message, Tooltip, Tag, Dropdown, Menu } from 'antd';
import { RouteComponentProps, withRouter } from "react-router";
import { IExperienceState } from "../../store/reducers/experience";
import { getPackages } from "../../store/actions/experience";
import { connect } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard/";
import { IStoreState } from '../../store/IStoreState';
import { ActivitySession } from '../../models/activity';
import moment from 'moment';
import { dateStringFormat } from '../../constants/setting';

interface IProps extends RouteComponentProps {
    id: string,
    getPackages : (id:string) => void,
    experience : IExperienceState,
}

export interface Package {
    id: string,
    packageName: string,
    packageType: string,
    sessions: Array<ActivitySession>,
    // location: string,
    fee: number,
    purchasedUsers: Array<String>
}

const columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        render: function render(record) {
            const success = () => {
                message.success('Package ID Copied!');
            };
            return (
                <CopyToClipboard text={record}><Tooltip placement="top" title={record}><Tag onClick={(e) => {e.stopPropagation(); success()}}>ID</Tag></Tooltip></CopyToClipboard>
            )
        }
    },
    {
        title: 'Package Name',
        dataIndex: 'packageName'
    },
    {
        title: 'Package Type',
        dataIndex: 'packageType'
    },
    {
        title: 'No. of session(s)',
        dataIndex: 'sessions',
        render: function render(sessions: Array<ActivitySession>) {
            return (
                <Dropdown placement="topLeft" overlay=
                {<Menu>
                    <Menu.ItemGroup title="No. of session(s)">
                        {sessions.map((element => {
                            if (element.startDateTime && element.endDateTime)
                                return <div>{moment(element.startDateTime).format(dateStringFormat.dateTime) + " - " + moment(element.endDateTime).format(dateStringFormat.dateTime)}</div>
                            else
                                return <div>No recorded start and end time</div>
                        }))}
                    </Menu.ItemGroup>
                </Menu>}>
                    <div>{sessions.length > 1 ? sessions.length.toString() + " Sessions" : sessions.length.toString() + " Session"}</div>
                </Dropdown>
            );
        }
    },
    // {
    //     title: 'Location',
    //     dataIndex: 'location'
    // },
    {
        title: 'Fee',
        dataIndex: 'fee',
        render: function render(fee: number) {
            return <div>HKD {fee.toFixed(2)}</div>
        }
    },
    {
        title: 'No. of Purchase(s)',
        dataIndex: 'purchasedUsers',
        render: function render(purchasedUsers: Array<string>) {
            return (
                <Dropdown placement="topLeft" overlay=
                {<Menu>
                    <Menu.ItemGroup title="Purchased user(s)">
                        {purchasedUsers.map((element => {
                            return <div>{element}</div>
                        }))}
                    </Menu.ItemGroup>
                </Menu>}>
                    <div>{purchasedUsers.length > 1 ? purchasedUsers.length.toString() + " purchases" : purchasedUsers.length.toString() + " purchase"}</div>
                </Dropdown>
            );
        }
    },
]

class PackagesTable extends React.Component <IProps> {
    componentDidMount(): void {
        this.props.getPackages(this.props.id);
    }

    render(): React.ReactNode {
        const { packages } = this.props.experience;
        return (
            <Card style={{ margin: '16px' }} headStyle={{ backgroundColor: '#F8F8F8' }} title={ packages.length.toString() + " Package(s)"}>
                <Table columns={columns}
                    dataSource={packages}
                />
            </Card>
        )
    }
}

const mapStateToProps = (state: IStoreState) => {
    return {
        ...state
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getPackages: (id:string) => dispatch(getPackages(id)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PackagesTable));