import {
    GET_BUNDLES,
    GET_EXPERIENCE_DETAILS,
    GET_EXPERIENCES, GET_MASTER_EXPERIENCE, GET_PARTICIPANTS,
    GET_SESSION_DETAILS,
    GET_SESSIONS, SHOW_MASTER_EXPERIENCE, SHOW_RENAME_EXPERIENCE, GET_PACKAGES
} from "../actions/experience";
import { Bundle } from "../../components/experiences/Bundles";
import { Session } from "../../components/experiences/Sessions";
import { Participant } from "../../components/experiences/Participants";
import { MasterExperienceItem } from "../../components/experiences/MasterExperience";
import { IExperience } from "../../components/experiences/ExperienceList";
import { IExperienceDetails } from "../../components/experiences/ExperienceDetails";
import { SessionDetails } from "../../components/experiences/SessionDetail";
import { Package } from "../../components/experiences/Packages";

export interface IExperienceState {
    experiences: Array<IExperience>,
    bundles: Array<Bundle>,
    experience: IExperienceDetails,
    sessions: Array<Session>,
    sessionDetails: SessionDetails,
    packages: Array<Package>,
    participants: Participant[],
    masterExperiences: MasterExperienceItem[],
    showMasterList : boolean,
    showRenameExperience: boolean,
}

export const initialState: IExperienceState = {
    experiences: [],
    bundles: [],
    experience: {
        type: "",
        typeColor: "",
        category: {
            title: "",
            color: ""
        },
        cover: "",
        status: {
            title: "",
            type: "default",
        },
        task: "",
        title: "",
        rating: null,
        hero: "",
        heroId: "",
        lastUpdateDate: "",
        createDate: "",
        numberOfPackages: 0,
        numberOfSessions:  0,
        numberOfPurchases: 0,
        languages: "",
        launchDate: "",
        fee: null,
        create: "",
        expect: "",
        join: "",
        keywords: [],
        isActive: false,
        include: []
    },
    sessions: [],
    sessionDetails: {
        starts: "",
        ends: "",
        location: "",
        joined: null,
        max: null,
        min: null,
        status: {
            title: "",
            type: "default",
        }
    },
    packages: [],
    participants: [],
    masterExperiences: [],
    showMasterList: false,
    showRenameExperience: false,
}

export default function (state = initialState, action: {type: string, data}): IExperienceState {
    switch (action.type) {
        case GET_EXPERIENCES:
            return {
                ...state,
                experiences: action.data,
            }
        case GET_EXPERIENCE_DETAILS:
            return {
                ...state,
                experience: action.data,
            }
        case GET_BUNDLES:
            return {
                ...state,
                bundles: action.data,
            }
        case GET_SESSIONS:
            return {
                ...state,
                sessions: action.data,
            }
        case GET_SESSION_DETAILS:
            return {
                ...state,
                sessionDetails: action.data,
            }
        case GET_PACKAGES:
            return {
                ...state,
                packages: action.data,
            }
        case GET_PARTICIPANTS:
            return {
                ...state,
                participants: action.data,
            }
        case GET_MASTER_EXPERIENCE:
            return {
                ...state,
                masterExperiences: action.data,
            }
        case SHOW_MASTER_EXPERIENCE:
            return {
                ...state,
                showMasterList: action.data,

            }
        case SHOW_RENAME_EXPERIENCE:
            return {
                ...state,
                showRenameExperience: action.data,
            }
        default: {
            return {
                ...state,
            }
        }
    }
}