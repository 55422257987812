import { Firebase, FirebaseDB, FirebaseFunctions } from "../../lib/firebase";
import moment from 'moment'
import { USERS_ERROR } from "./users";
import { FirestoreKey } from "../../constants/setting";
import { Activity, ActivitySession, SessionParticipant, ActivityType } from "../../models/activity";
import { UserHeroProfile } from "../../models/user";
import { Bundle } from "../../components/experiences/Bundles";
import { Category, IExperienceDetails, Status } from "../../components/experiences/ExperienceDetails";
import { IExperience } from "../../components/experiences/ExperienceList";
import { Session } from "../../components/experiences/Sessions";
import { SessionDetails } from "../../components/experiences/SessionDetail";
import { Participant } from "../../components/experiences/Participants";
import { MasterExperienceItem } from "../../components/experiences/MasterExperience";
import { getMasterId, toOrdinal } from "../../utility/usefulFunctions";
import { dateStringFormat } from "../../constants/setting";
import { AppThunk } from "../IStoreState";
import { Package } from "../../components/experiences/Packages";

export const GET_EXPERIENCES = 'GET_EXPERIENCES';
export const GET_EXPERIENCE_DETAILS = 'GET_EXPERIENCE_DETAILS';
export const GET_BUNDLES = 'GET_BUNDLES';
export const GET_SESSIONS = 'GET_SESSIONS';
export const GET_PACKAGES = 'GET_PACKAGES';
export const GET_SESSION_DETAILS = 'GET_SESSION_DETAILS';
export const GET_PARTICIPANTS = 'GET_PARTICIPANTS';
export const EXPERIENCE_ERROR = 'EXPERIENCE_ERROR';
export const GET_MASTER_EXPERIENCE = 'GET_MASTER_EXPERIENCE';
export const SHOW_MASTER_EXPERIENCE = 'SHOW_MASTER_EXPERIENCE';
export const SHOW_RENAME_EXPERIENCE = 'SHOW_RENAME_EXPERIENCE';
export const RENAME_EXPERIENCE = 'RENAME_EXPEREINCE';
export const DISABLE_EXPERIENCE = 'DISABLE_EXPERIENCE';

export function getExperiences(hits: Array<{ title, objectID }>, activeOnly = false): AppThunk {
    return async dispatch => {
        if (Firebase === null) {
            dispatch({
                type: USERS_ERROR,
                data: null,
            })
        }
        try {

            const ActivityRef = await FirebaseDB.collection(FirestoreKey.Activities)
            const UserRef = await FirebaseDB.collection(FirestoreKey.UsersHeroProfile)
            const experiences = [] as IExperience[];
            if (hits.length === 0) {
                const experiences: IExperience[] = [];
                dispatch({
                    type: GET_EXPERIENCES,
                    data: experiences
                })
            }
            let count = hits.length;
            hits.map(async hit => {
                await ActivityRef.doc(hit.objectID).onSnapshot((async snap => {
                    const data = snap.data() as Activity;
                    if (data) {
                        if (!activeOnly || data.isActive) {
                            const experience: IExperience = {
                                id: hit.objectID,
                                userId: data.userId,
                                heroName: '',
                                experience: hit.title,
                            };
                            UserRef.doc(experience.userId).get().then(snap => {
                                const data = snap.data() as UserHeroProfile;
                                experience.heroName = data.heroName;
                                experiences.push(experience);
                                if (experiences.length === count && hits.length !== 0) {
                                    dispatch({
                                        type: GET_EXPERIENCES,
                                        data: experiences,
                                    })
                                }
                            })
                        }
                        else {
                            count--;
                        }
                    }
                }), error => {
                    console.log(error);
                })
            });
        }
        catch (error) {
            dispatch({
                type: USERS_ERROR,
                data: error
            })
        }
    }
}

export function getBundles(id: string): AppThunk {
    return async dispatch => {
        if (Firebase === null) {
            dispatch({
                type: EXPERIENCE_ERROR,
                data: null,
            })
        }
        try {
            const ActivityRef = await FirebaseDB.collection(FirestoreKey.Activities).doc(id);
            let bundles = [] as Bundle[];
            await ActivityRef.collection('bundles').onSnapshot(snap => {
                snap.docChanges().forEach(change => {
                    if (change.type === "added") {
                        const index = bundles.findIndex(e => {
                            return e.id === change.doc.id;
                        })
                        if (index === -1) {
                            const data = change.doc.data();
                            const bundle: Bundle = {
                                id: change.doc.id,
                                bundle: data.index,
                                bundleName: data.title,
                                qty: data.stock,
                                price: data.price,
                                remain: data.remaining,
                                customisation: data.description,
                            }
                            bundles.push(bundle);
                        }
                    }
                    else if (change.type === "modified") {
                        const index = bundles.findIndex(e => {
                            return e.id === change.doc.id;
                        })

                        if (index !== -1) {
                            const data = change.doc.data();
                            const bundle: Bundle = {
                                id: change.doc.id,
                                bundle: data.index,
                                bundleName: data.title,
                                qty: data.stock,
                                price: data.price,
                                remain: data.remaining,
                                customisation: data.description,
                            }
                            bundles[index] = bundle;
                        }
                    }
                    else {
                        const newBundles = bundles.filter(bundle => {
                            return bundle.id !== change.doc.id;
                        })
                        bundles = [...newBundles];
                    }
                });
                dispatch({
                    type: GET_BUNDLES,
                    data: bundles,
                })
            })
        }
        catch (error) {
            dispatch({
                type: EXPERIENCE_ERROR,
                data: error,
            })
        }
    }
}

export function getSessions(id: string): AppThunk {
    return async dispatch => {
        if (Firebase === null) {
            dispatch({
                type: EXPERIENCE_ERROR,
                data: null,
            })
        }
        try {
            const SessionRef = FirebaseDB.collection(FirestoreKey.ActivitySessions).where('activityId', '==', id)
            SessionRef.onSnapshot(snap => {
                let sessions: Session[] = [];
                let length = 0;
                if (snap.docs.length) {
                    length = snap.docs.length
                }
                snap.docChanges().forEach(async change => {
                    if (change.type === 'added' || change.type === 'modified') {
                        const index = sessions.findIndex(e => {
                            return e.id === change.doc.id;
                        })
                        if (index === -1) {
                            const data = change.doc.data() as ActivitySession;
                            const session: Session = {
                                id: change.doc.id,
                                session: data.index + 1,
                                starts: moment(data.startDateTime).format(dateStringFormat.dateTime),
                                ends: moment(data.endDateTime).format(dateStringFormat.dateTime),
                                duration: moment(data.startDateTime).format(dateStringFormat.dateTime) + " - " + moment(data.endDateTime).format(dateStringFormat.dateTime).substring(moment(data.endDateTime).format(dateStringFormat.dateTime).length - 5),
                                linkedPackage: "",
                                pax: (data.currentParticipantCount || 0) + '/' + data.pax,
                                status: getSessionStatus(data),
                            }

                            const expSnapshot = await FirebaseDB.collection(FirestoreKey.Activities).doc(data.activityId).collection("packages").get()
                            for (const expSnap of expSnapshot.docs) {
                                const sessionIds = expSnap.data().sessionIds;
                                if (sessionIds) {
                                    if (sessionIds.includes(change.doc.id)) {
                                        session.linkedPackage = expSnap.data().name;
                                        break;
                                    }
                                }
                            }

                            sessions.push(session)
                            if (sessions.length === length) {
                                sessions.sort((a, b) => {
                                    return (a.session > b.session ? 1 : a.session < b.session ? -1 : 0);
                                });
                                dispatch({
                                    type: GET_SESSIONS,
                                    data: sessions,
                                })
                            }
                        }
                    }
                    else {
                        const newSessions = sessions.filter(session => {
                            return session.id !== change.doc.id;
                        })
                        sessions = [...newSessions];
                        length -= 1;
                        if (sessions.length === length) {
                            sessions.sort((a, b) => {
                                return (a.session > b.session ? 1 : a.session < b.session ? -1 : 0);
                            });
                            dispatch({
                                type: GET_SESSIONS,
                                data: sessions,
                            })
                        }
                    }
                });
            })
        }
        catch (error) {
            dispatch({
                type: EXPERIENCE_ERROR,
                data: null,
            })
        }
    }
}
export function getSessionDetails(id: string): AppThunk {
    return async dispatch => {
        if (Firebase === null) {
            dispatch({
                type: EXPERIENCE_ERROR,
                data: null,
            })
        }
        try {
            const SessionRef = await FirebaseDB.collection(FirestoreKey.ActivitySessions).doc(id);
            SessionRef.onSnapshot(snap => {
                const data = snap.data() as ActivitySession;
                const Session: SessionDetails = {
                    starts: moment(data.startDateTime).format(dateStringFormat.dateTime),
                    ends: moment(data.endDateTime).format(dateStringFormat.dateTime),
                    location: data.address,
                    joined: data.currentParticipantCount,
                    max: data.pax,
                    min: data.minPax,
                    status: getSessionStatus(data),
                }
                dispatch({
                    type: GET_SESSION_DETAILS,
                    data: Session
                })
            })
        } catch (e) {
            dispatch({
                type: EXPERIENCE_ERROR,
                data: e,
            })
        }
    }
}

export function getPackages(id: string): AppThunk {
    return async dispatch => {
        if (Firebase === null) {
            dispatch({
                type: EXPERIENCE_ERROR,
                data: null,
            })
        }
        try {
            const packageRef = FirebaseDB.collection(FirestoreKey.Activities).doc(id).collection("packages")
            packageRef.onSnapshot(snap => {
                let packages: Package[] = [];
                let length = 0;
                if (snap.docs.length) {
                    length = snap.docs.length
                }
                else {
                    dispatch({ type: GET_PACKAGES, data: [], });
                }
                snap.docChanges().forEach(async change => {
                    if (change.type === 'added' || change.type === 'modified') {
                        const index = packages.findIndex(e => {
                            return e.id === change.doc.id;
                        })
                        if (index === -1) {
                            const data = change.doc.data();
                            const newPackage: Package = {
                                id: change.doc.id,
                                packageName: data.name,
                                packageType: data.type ? `Specific ${data.sessionIds.length}` : `Any ${data.amount}`,
                                sessions: [],
                                // location: "",
                                fee: data.price,
                                purchasedUsers: []
                            }

                            if (data.sessionIds) {
                                const sessionQueryPromises = [];
                                for (let i = 0; i < data.sessionIds.length; i++) {
                                    const sessionQuery = FirebaseDB.collection(FirestoreKey.ActivitySessions).doc(data.sessionIds[i]).get();
                                    sessionQueryPromises.push(sessionQuery)
                                }
                                const sessionSnapshot = await Promise.all(sessionQueryPromises);
                                for (const sessionSnap of sessionSnapshot) {
                                    const sessionData = sessionSnap.data() as ActivitySession;
                                    newPackage.sessions.push(sessionData);
                                }
                            }

                            const paymentSnapshot = await FirebaseDB.collection(FirestoreKey.Payments).where('type', '==', 1).where('packageId', '==', change.doc.id).get()
                            const userQueryPromises = [];
                            for (const paymentSnap of paymentSnapshot.docs) {
                                const paymentData = paymentSnap.data();
                                const userQuery = FirebaseDB.collection(FirestoreKey.UsersCitizenProfile).doc(paymentData.userId).get();
                                userQueryPromises.push(userQuery)
                            }

                            const userSnapshot = await Promise.all(userQueryPromises);
                            for (const userSnap of userSnapshot) {
                                const userData = userSnap.data();
                                newPackage.purchasedUsers.push(userData.citizenName);
                            }

                            packages.push(newPackage);
                            if (packages.length === length) {
                                packages.sort((a, b) => {
                                    return (a.packageName < b.packageName ? 1 : a.packageName === b.packageName ? 0 : -1);
                                });
                                dispatch({
                                    type: GET_PACKAGES,
                                    data: packages,
                                })
                            }
                        }
                    }
                    else {
                        const newPackages = packages.filter(session => {
                            return session.id !== change.doc.id;
                        })
                        packages = [...newPackages];
                        length -= 1;
                        if (packages.length === length) {
                            packages.sort((a, b) => {
                                return (a.packageName < b.packageName ? 1 : a.packageName === b.packageName ? 0 : -1);
                            });
                            dispatch({
                                type: GET_PACKAGES,
                                data: packages,
                            })
                        }
                    }
                });
            })
        }
        catch (error) {
            dispatch({
                type: EXPERIENCE_ERROR,
                data: null,
            })
        }
    }
}

async function getCustomization(sessionId: string, userId: string): Promise<string> {
    let customization: string;
    const SessionRef = FirebaseDB.collection(FirestoreKey.ActivitySessions).doc(sessionId)
        .collection(FirestoreKey.ActivitySessionParticipants).doc(userId).collection(FirestoreKey.ActivityBundleCustomisations);
    try {
        const doc = await SessionRef.get();
        const data = doc[0].data();
        customization = data.answer;
        return customization;
    } catch (e) {
        return null;
    }
}

export function getParticipants(id: string): AppThunk {
    return async dispatch => {
        if (Firebase === null) {
            dispatch({
                type: EXPERIENCE_ERROR,
                data: null
            })
        }
        try {
            const Participants = await FirebaseDB.collection(FirestoreKey.ActivitySessions).doc(id).collection(FirestoreKey.ActivitySessionParticipants);
            Participants.onSnapshot(async snap => {
                const participants: Participant[] = [];
                const len = snap.size;
                snap.forEach(async doc => {
                    const data = doc.data() as SessionParticipant;
                    const user: string = await getUser(doc.id);
                    const participant: Participant = {
                        userId: doc.id,
                        user: user,
                        join: moment(data.joinedDateTime).format(dateStringFormat.date),
                        payment: '',
                        bundle: data.bundleId,
                        customization: await getCustomization(id, doc.id),
                    }
                    participants.push(participant);
                    if (participants.length === len && len !== 0) {
                        dispatch({
                            type: GET_PARTICIPANTS,
                            data: participants,
                        })
                    }
                })

            })
        } catch (e) {
            dispatch({
                type: GET_PARTICIPANTS,
                data: e,
            })
        }
    }
}

async function getCategory(id: string): Promise<Category> {
    let category: Category;
    const CategoryRef = FirebaseDB.collection(FirestoreKey.Categories).doc(id);
    try {
        const snap = await CategoryRef.get();
        const data = snap.data();
        category = ({
            title: data.name,
            color: data.color,
        });
        return category;
    } catch (e) {
        return null;
    }
}

async function getCustomType(id: string): Promise<string> {
    let custom: string;
    const CustomRef = FirebaseDB.collection("customTypes").doc(id);
    try {
        const snap = await CustomRef.get();
        const data = snap.data();
        custom = data.name;
        return custom;
    } catch (e) {
        return null
    }
}

function getExperienceStatus(data: Activity): Status {
    if (data.isAllSessionSealed) {
        return {
            title: 'Closed',
            type: 'error',
        }
    }
    else if (data.isAllSessionEnded) {
        return {
            title: 'Reviewing',
            type: 'warning',
        }
    }
    else if (data.isAnySessionStarted) {
        return {
            title: 'On Going',
            type: 'success',
        }
    }
    else if (data.isLaunched) {
        return {
            title: 'Launched',
            type: 'success',
        }
    }
    else return {
        title: 'Concept',
        type: 'processing'
    }
}

function getSessionStatus(data: ActivitySession): Status {
    if (data.isDismissed) {
        return {
            title: 'Dismissed',
            type: 'default'
        }
    }
    else if (data.isSealed) {
        return {
            title: 'Closed',
            type: 'error'
        }
    }
    else if (moment().unix() > data.endDateTime) {
        return {
            title: 'Reviewing',
            type: 'warning'
        }
    }
    else if (data.startDateTime < moment().unix() && moment().unix() < data.endDateTime) {
        return {
            title: 'On Going',
            type: 'success'
        }
    }
    else if (data.isFulled) {
        return {
            title: 'Fulled',
            type: 'success'
        }
    }
    else if (data.isLaunched) {
        return {
            title: 'Launched',
            type: 'success'
        }
    }
    else return {
        title: 'Concept',
        type: 'processing',
    }
}

export async function getUser(id: string): Promise<string> {
    let user: string;
    const UserRef = FirebaseDB.collection(FirestoreKey.UsersVariable).doc(id);
    try {
        const snap = await UserRef.get();
        const data = snap.data();
        user = data.email;
        return user;
    } catch (e) {
        return null;
    }
}

export function getExperienceDetails(id: string): AppThunk {
    return async (dispatch) => {
        if (Firebase === null) {
            dispatch({
                type: EXPERIENCE_ERROR,
                data: null,
            })
        }
        try {
            const ActivityRef = await FirebaseDB.collection(FirestoreKey.Activities).doc(id);

            ActivityRef.onSnapshot(async doc => {
                const data = doc.data() as Activity;
                const category: Category = await getCategory(data.categoryId);
                const hero: string = await getUser(data.userId);
                const customType: string = data.customTypeId ? await getCustomType(data.customTypeId) : "";
                let activityType: string;
                switch (data.type) {
                    case ActivityType.Adventure:
                        activityType = "Adventure";
                        break;
                    case ActivityType.Journey:
                        activityType = "Journey";
                        break;
                    case ActivityType.Event:
                        activityType = "Event";
                        break;
                    default:
                        activityType = "";
                }
                const experience: IExperienceDetails = {
                    type: activityType,
                    typeColor: data.type ? '#2BA885 ' : '#F68A5B',
                    category: category,
                    cover: data.coverPhotoUrl,
                    status: getExperienceStatus(data),
                    task: customType,
                    title: data.title,
                    rating: data.rating,
                    hero: hero,
                    heroId: data.userId,
                    createDate: moment(data.createdDateTime).format(dateStringFormat.dateTime),
                    lastUpdateDate: moment(data.lastModifiedDateTime).format(dateStringFormat.dateTime),
                    numberOfPackages: 0,
                    numberOfSessions: 0,
                    numberOfPurchases: 0,
                    languages: data.languageCode,
                    launchDate: moment(data.launchedDateTime).format(dateStringFormat.dateTime),
                    fee: data.fee,
                    create: toOrdinal(data.order),
                    expect: data.whatToExpect,
                    join: data.whoShouldJoin,
                    keywords: data.keywords,
                    isActive: data.isActive,
                    include: []
                }
                const packageSnapshot = await ActivityRef.collection("packages").get();
                experience.numberOfPackages = packageSnapshot.docs.length;

                const sessionSnapshot = await FirebaseDB.collection(FirestoreKey.ActivitySessions).where('activityId', '==', id).get()
                experience.numberOfSessions = sessionSnapshot.docs.length;

                const paymentSnapshot = await FirebaseDB.collection(FirestoreKey.Payments).where('type', '==', 1).where('activityId', '==', id).get()
                experience.numberOfPurchases = paymentSnapshot.docs.length;

                const includeSnapshot = await ActivityRef.collection("includes").get();
                for (const includeSnap of includeSnapshot.docs) {
                    const includeData = includeSnap.data();
                    switch (includeData.type) {
                        case 0:
                            experience.include.push("Food Drinks: " + includeData.description);
                            break;
                        case 1:
                            experience.include.push("Equipment: " + includeData.description);
                            break;
                        case 2:
                            experience.include.push("Material: " + includeData.description);
                            break;
                        case 3:
                            experience.include.push("Transportation: " + includeData.description);
                            break;
                        default:
                            experience.include.push("Default: " + includeData.description);
                            break;
                    }
                }

                dispatch({
                    type: GET_EXPERIENCE_DETAILS,
                    data: experience,
                })
            })

        }
        catch (error) {
            dispatch({
                type: EXPERIENCE_ERROR,
                data: error,
            })
        }
    }
}

export function getMasterExperience(activityId: string): AppThunk {
    return async dispatch => {
        if (Firebase === null) {
            dispatch({
                type: GET_MASTER_EXPERIENCE,
                data: null,
            })
        }
        try {
            const id = await getMasterId(activityId);
            const MasterRef = await FirebaseDB.collection(FirestoreKey.Activities).where('masterId', '==', id);
            MasterRef.onSnapshot(async snap => {
                const len = snap.size;
                const masterExperiences: MasterExperienceItem[] = [];
                snap.forEach(doc => {
                    const data = doc.data() as Activity;
                    const masterExperience: MasterExperienceItem = {
                        id: doc.id,
                        create: toOrdinal(data.order),
                        name: data.title,
                        createDate: "Created on " + moment(data.createdDateTime).format(dateStringFormat.date)
                    }
                    masterExperiences.push(masterExperience);
                    if (len === masterExperiences.length && len !== 0) {
                        dispatch({
                            type: GET_MASTER_EXPERIENCE,
                            data: masterExperiences
                        })
                    }
                })
            })
        } catch (e) {
            dispatch({
                type: EXPERIENCE_ERROR,
                data: e,
            })
        }
    }
}

export function renameExperience(id: string, title: string, success: () => void, fail: () => void): AppThunk {
    return async dispatch => {
        if (Firebase === null) {
            dispatch({
                type: USERS_ERROR,
                data: null,
            });
            fail()
        }

        try {
            const renameProm = await FirebaseFunctions.httpsCallable('renameActivity');
            renameProm({ activityId: id, title: title }).then(
                () => {
                    dispatch({
                        type: DISABLE_EXPERIENCE,
                        data: false,
                    });
                    success();
                }
            )
        }
        catch (error) {
            console.log(error);
            fail();
        }
    }
}

export function disableExperience(id: string, success: () => void, fail: () => void): AppThunk {
    return async dispatch => {
        if (Firebase === null) {
            dispatch({
                type: USERS_ERROR,
                data: null,
            });
            fail();
        }

        try {
            const disableProm = await FirebaseFunctions.httpsCallable('disableActivity');
            disableProm({ activityId: id }).then(
                () => {
                    dispatch({
                        type: DISABLE_EXPERIENCE,
                        data: false,
                    });
                    success();
                }
            )
        }
        catch (error) {
            console.log(error);
            fail();
        }
    }
}

export function showMasterExperience(): AppThunk {
    return async dispatch => {
        if (Firebase === null) {
            dispatch({
                type: EXPERIENCE_ERROR,
                data: null,
            })
        }
        try {
            dispatch({
                type: SHOW_MASTER_EXPERIENCE,
                data: true,
            })
        } catch (e) {
            dispatch({
                type: EXPERIENCE_ERROR,
                data: null,
            })
        }
    }
}

export function hideMasterExperience(): AppThunk {
    return async dispatch => {
        if (Firebase === null) {
            dispatch({
                type: EXPERIENCE_ERROR,
                data: null,
            })
        }
        try {
            dispatch({
                type: SHOW_MASTER_EXPERIENCE,
                data: false,
            })
        } catch (e) {
            dispatch({
                type: EXPERIENCE_ERROR,
                data: null,
            })
        }
    }
}

export function showRenameExperience(): AppThunk {
    return async dispatch => {
        if (Firebase === null) {
            dispatch({
                type: EXPERIENCE_ERROR,
                data: null,
            })
        }
        try {
            dispatch({
                type: SHOW_RENAME_EXPERIENCE,
                data: true,
            })
        } catch (e) {
            dispatch({
                type: EXPERIENCE_ERROR,
                data: null,
            })
        }
    }
}

export function hideRenameExperience(): AppThunk {
    return async dispatch => {
        if (Firebase === null) {
            dispatch({
                type: EXPERIENCE_ERROR,
                data: null,
            })
        }
        try {
            dispatch({
                type: SHOW_RENAME_EXPERIENCE,
                data: false,
            })
        } catch (e) {
            dispatch({
                type: EXPERIENCE_ERROR,
                data: null,
            })
        }
    }
}