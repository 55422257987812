import {
    GET_CASH_OUTS, GET_PAYOUT_DETAILS,
    GET_PENDING_PAYOUTS, GET_REFUND_DETAILS,
    HIDE_CANCEL_PAYOUT_FORM, HIDE_SETTLE_PAYOUT,
    SHOW_CANCEL_PAYOUT_FORM, SHOW_SETTLE_PAYOUT, UPDATE_CASHOUT_STATUS, UPDATE_ERROR
} from "../actions/transactions";
import { ICashOut } from "../../components/transactions/CashOutTable";
import { OutstandingRefundRecord } from "../../components/transactions/OutstandingRefund";

export interface ITransactionState {
    pendingCashOut: Array<ICashOut>,
    allCashOut:Array<ICashOut>,
    cancelPayoutForm:boolean
    record : ICashOut,
    cancelStatus : boolean,
    settleStatus : boolean,
    refunds: OutstandingRefundRecord[],
}

export const initialState:ITransactionState = {
    pendingCashOut: [],
    allCashOut: [],
    cancelPayoutForm: false,
    record: {
        id: '',
        userId: '',
        user: '',
        cashOutMethod: '',
        cashOutAccount: '',
        cashOut: '',
        final: '',
        cashOutDate: '',
        lastUpdated: '',
        status: '',
        serviceFee: '',
        statusNo: 0,
        cancellationReason: '',
        canceledDateTime: '',
        canceledBy: '',

    },
    cancelStatus: false,
    settleStatus: false,
    refunds: [],
}

export default (state = initialState, action:{type:string, data}): ITransactionState => {
    switch (action.type) {
        case GET_PENDING_PAYOUTS:
            return {
                ...state,
                pendingCashOut: action.data
            }
        case GET_CASH_OUTS:
            return {
                ...state,
                allCashOut: action.data
            }
        case GET_PAYOUT_DETAILS:
            return {
                ...state,
                record: action.data,
            }
        case GET_REFUND_DETAILS:
            return {
                ...state,
                refunds: action.data,
            }
        case SHOW_CANCEL_PAYOUT_FORM:
            return {
                ...state,
                cancelPayoutForm: true,
                record: action.data,
            }
        case HIDE_CANCEL_PAYOUT_FORM:
            return {
                ...state,
                cancelPayoutForm: action.data,
            }
        case SHOW_SETTLE_PAYOUT:
            return {
                ...state,
                record: action.data,
                settleStatus: true,
            }
        case HIDE_SETTLE_PAYOUT:
            return {
                ...state,
                settleStatus: action.data
            }
        case UPDATE_CASHOUT_STATUS:
            return {
                ...state,
                cancelStatus: true,
            }
        case UPDATE_ERROR:
            return {
                ...state,
                cancelStatus: false,
            }
        default: {
            return {
                ...state,
            }
        }
    }

};