import {
    FETCH_USER,
    LOGIN_USER_FAIL,
    RESET_ERROR,
    ADMIN_USER_FAIL,
    ADMIN_USER_LOGIN,
    PASSWORD_RESET,
    SHOW_RESET_FORM, HIDE_RESET_FORM, USER_LOADING
} from "../actions/user";

export interface IUserState {
    error : string,
    user : firebase.User,
    admin: boolean,
    resetEmail: boolean,
    showResetForm: boolean,
    userLoading: boolean,
}

export const initialState : IUserState = {
    error: null,
    user: null,
    admin: false,
    resetEmail: false,
    showResetForm: false,
    userLoading: true,
};

export default (state = initialState, action: {type: string, data}): IUserState => {
    switch (action.type) {
        case FETCH_USER: {
            return {
                ...state,
                user: action.data.user,
                admin: action.data.admin,
                error: null
            };
        }

        case USER_LOADING: {
            return {
                ...state,
                userLoading: action.data,
            }
        }

        case RESET_ERROR: {
            return {
                ...state,
                error: null
            }
        }

        case LOGIN_USER_FAIL: {
            return {
                ...state,
                error: action.data,
            }
        }

        case ADMIN_USER_FAIL: {
            return {
                ...state,
                admin: action.data,
                error: "You do not have access rights!"
            }
        }

        case ADMIN_USER_LOGIN: {
            return {
                ...state,
                admin: action.data,
            }
        }

        case PASSWORD_RESET: {
            return {
                ...state,
                resetEmail: action.data,
            }
        }

        case SHOW_RESET_FORM : {
            return {
                ...state,
                showResetForm: action.data,
            }
        }

        case HIDE_RESET_FORM : {
            return {
                ...state,
                showResetForm: action.data,
                resetEmail: false,
            }
        }

        default: {
            return {
                ...state,
            }
        }
    }
};