import user from "./user";
import users from "./users"
import transactions from "./transactions";
import experience from "./experience";
import rewards from "./rewards";
import report from "./report";
import admin from "./admin";
import contentManagement from "./contentManagement";


export default {
    user,
    users,
    transactions,
    experience,
    rewards,
    report,
    admin,
    contentManagement,
};